import {Injectable} from '@angular/core';
import {QualifactApiService} from '../../core/api-services/qualifact/qualifact.api.service';

@Injectable({
    providedIn: 'root'
})
export class QualifactControlService {
    constructor(private _qualifactApiService: QualifactApiService) {}

    async loadAllQualifactControls(params?: any) {
        try {
            const requestParams = {
                ...params,
                perPage: 1000,
                sort: 'code'
            };
            return await this._qualifactApiService
                .getAllControls(requestParams)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }
}
