<mat-toolbar class="border-bottom">
    <button
        type="button"
        class="p-0 border-0 bg-transparent display-flex justify-content-start align-items-center cursor-pointer"
        (click)="openVersionDisplayDialog($event)"
    >
        <img
            id="ospi"
            class="logo"
            [src]="'./assets/images/ospi.svg'"
            alt="ospi"
        />

        <div id="logo-separator"></div>

        <img
            [id]="[isResearch ? 'i4h-search' : 'i4h']"
            class="logo"
            [src]="logoUrl"
            alt="i4h"
        />

        <h4 class="h4">
            {{ getTitle() || '' }}
        </h4>
    </button>

    <span class="spacer"></span>

    <!--Subscribe to alert button-->
    <button
        mat-icon-button
        *ngIf="canDisplaySubscribeToCurrentModuleButton()"
        [matTooltip]="'TOOLTIP.SUBSCRIBE' | translate"
        (click)="subscribeToAlert()"
    >
        <mat-icon>add_alert</mat-icon>
    </button>

    <!--Unsubscribe from alert button-->
    <button
        mat-icon-button
        *ngIf="canDisplayUnsubscribeFromCurrentModuleButton()"
        [matTooltip]="'TOOLTIP.UNSUBSCRIBE' | translate"
        (click)="subscribeToAlert(true)"
    >
        <mat-icon>unsubscribe</mat-icon>
    </button>

    <!-- Intercom button -->
    <button
        *ngIf="intercomEnabled"
        id="intercom-launcher"
        class="pr-4 pl-[2px] h-[30px] inline-flex items-center text-xs font-bold bg-secondary-default hover:bg-secondary-hover transition-colors text-white rounded-3xl relative mr-4"
        (click)="unreadIntercomNotifications = null"
    >
        <span class="h-[26px] w-[26px] flex items-center justify-center rounded-full bg-white mr-2">
            <i class="ri-message-2-line ri-lg text-secondary-default"></i>
        </span>

        <span class="text-xs font-bold">Contactez-nous</span>

        <span
            *ngIf="unreadIntercomNotifications"
            class="absolute flex h-[10px] w-[10px] bg-gradient-to-tr from-red-500 to-[#f00] text-white justify-center items-center font-bold -top-1 -right-1 text-tiny rounded-full ring ring-white"
        ></span>
    </button>

    <button
        *ngIf="!intercomEnabled"
        (click)="submitIssue()"
        class="btn-jira"
        mat-raised-button
        color="primary"
        [ngClass]="'ml-3'"
    >
        {{ 'TOOLTIP.JIRA_COLLECTOR' | translate }}
        <mat-icon class="white-icon">contact_support</mat-icon>
    </button>

    <!--Generic call for refresh csv button-->
    <button mat-icon-button
            *ngIf="canDisplayOrbisButton"
            (click)="updateFile()">
        <mat-icon>play_for_work</mat-icon>
    </button>
    <!--Generic call for ML button-->
    <button mat-icon-button
            *ngIf="canDisplayMLGenericCall"
            (click)="goToMLGenericCallPage()">
        <mat-icon>link</mat-icon>
    </button>

    <!--Documentation button-->
    <button mat-icon-button
            *ngIf="canDisplayDocumentation"
            (click)="openDocumentationDialog()">
        <mat-icon>help</mat-icon>
    </button>

    <!--Alert list button-->
    <button mat-icon-button
            class="alert-button"
            *ngIf="canDisplayAlertList"
            (click)="goToAlertList()">
        <mat-icon>warning</mat-icon>
    </button>

    <!-- More menu button -->
    <button mat-icon-button
            class="profile-button"
            [matMenuTriggerFor]="toolBarMenu">
        <img [src]="userPicture"
             class="user-picture"/>
    </button>

    <mat-menu #toolBarMenu="matMenu">
        <ng-template matMenuContent>
            <button mat-menu-item
                    (click)="goToEditPassword()">
                <mat-icon>vpn_key</mat-icon>
                <span>{{ 'TOOLBAR.UPDATE_PWD' | translate }}</span>
            </button>

            <button mat-menu-item
                    (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>
                <span>{{ 'TOOLBAR.LOGOUT' | translate }}</span>
            </button>
        </ng-template>
    </mat-menu>
</mat-toolbar>
