import {Injectable} from '@angular/core';
import {SearchEngineCondition} from '../search-engine.condition';
import {TranslateService} from '@ngx-translate/core';
import { SearchEngineConditions } from '../../enums/search-engine-conditions';

type TypeRevaluation = typeof SearchEngineConditionRevaluationService.SORT_REVALUATION_DESC
    | typeof SearchEngineConditionRevaluationService.SORT_REVALUATION_ASC
    | typeof SearchEngineConditionRevaluationService.SORT_REVALUATION_NONE;

@Injectable({
    providedIn: 'root'
})
export class SearchEngineConditionRevaluationService extends SearchEngineCondition {
    public static readonly SORT_REVALUATION_DESC = '-revaluation';
    public static readonly SORT_REVALUATION_ASC = 'revaluation';
    public static readonly SORT_REVALUATION_NONE = null;

    public revaluation: string;

    constructor(
        private _translateService: TranslateService,
    ) {
        super([SearchEngineConditions.Revaluation],
            _translateService.instant('STAY.REVALUATION'),
            SearchEngineConditions.Revaluation,
            null,
            false
        );

        this.init();
    }

    public init(): void {
        this.setRevaluation(null);
    }

    public setRevaluation(revaluation?: string) {
        this.params = {revaluation: revaluation};
        this.uriParams = this.params;
    }

    public getUriParams(): { revaluation: string } {
        return this.uriParams;
    }
}
