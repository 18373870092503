import * as _ from 'lodash-es';
import {DataHelperService} from '../../../core/services/data.helper.service';
import {DateHelperService} from '../../../core/services/date.helper.service';

export interface ApiCriteria {
    type: string;
    args: {
        unit?: string;
        source?: any[],
        values?: any[],
        value?: any,
        minValue?: any,
        maxValue?: any,
        operator?: string
        codificationLabel?: string[]
        slugs?: string[],
        type?: string,
        revaluation?: boolean,
        isValidated?: boolean,
        score?: ComparisonArgs,
        simplifiedScore?: ComparisonArgs
    };
}

export interface ComparisonArgs {
    minValue?: any;
    maxValue?: any;
    operator: string;
}

export class SearchEngineCondition {
    protected _params: any;
    protected _uriParams: any;
    protected _apiParams: ApiCriteria;
    protected _fields: string[];
    protected _displayName: string;
    protected _slug: string;
    protected _component: any;
    protected _isAdvanced: boolean;
    protected _isActivated: boolean;

    constructor(fields: string[],
                displayName: string,
                slug: string,
                component: any,
                isAdvanced: boolean,
    ) {
        this._fields = fields;
        this._displayName = displayName;
        this._slug = slug;
        this._component = component;
        this._isAdvanced = isAdvanced;
        this._isActivated = false;
    }

    get params(): any {
        return this._params;
    }

    set params(value: any) {
        this._params = value;
    }

    get uriParams(): any {
        return this._uriParams;
    }

    set uriParams(value: any) {
        this._uriParams = value;
    }

    get apiParams(): ApiCriteria {
        return this._apiParams;
    }

    set apiParams(value: ApiCriteria) {
        this._apiParams = value;
    }

    get fields(): string[] {
        return this._fields;
    }

    set fields(value: string[]) {
        this._fields = value;
    }

    get displayName(): string {
        return this._displayName;
    }

    set displayName(value: string) {
        this._displayName = value;
    }

    get slug(): string {
        return this._slug;
    }

    set slug(value: string) {
        this._slug = value;
    }

    get component(): any {
        return this._component;
    }

    set component(value: any) {
        this._component = value;
    }

    get isAdvanced(): boolean {
        return this._isAdvanced;
    }

    set isAdvanced(value: boolean) {
        this._isAdvanced = value;
    }

    get isActivated(): boolean {
        return this._isActivated;
    }

    set isActivated(value: boolean) {
        this._isActivated = value;
    }

    static getActSlugsAsArray(actString: string, useCase?: string): string[] {
        if (actString) {
            const actSplit = actString.split(';');
            const missingActsElement = actSplit.find(el =>
                (useCase ? el.includes(`t:${useCase}`) : true));
            if (missingActsElement) {
                const elements = missingActsElement.split('|');
                return elements[0].split(':')[1].split(',');
            }
        }
        return [];
    }

    static updateParamsActSlug(uriParams: any, params: any, useCase: string): void {
        if (uriParams &&
            params) {
            const actSplit = uriParams.act.split(';');
            const current = actSplit.find(el => el.includes(`t:${useCase}`));
            if (current) {
                const elements = current.split('|');
                params.actSlug = elements[0].split(':')[1].split(',');
                params.actSlugOperator = elements[2].split(':')[1];
            }
        }
    }

    getUriParams(): any {
        this.convertToUriParams();
        if (this.isValid(this._uriParams)) {
            return this._uriParams;
        }
        return null;
    }

    private _isEmptyValueOrValuesArgs(args: any): boolean {
        if (args) {
            const keys = Object.keys(args);
            const hasValueOrValuesKey = keys.includes('value') || keys.includes('values');
            // Returns false if no value/values key or (value/values key and not empty)
            // else true
            return hasValueOrValuesKey &&
                (this.isEmpty(args.value) || this.isEmpty(args.values));
        }
        return true;
    }

    getApiParams(): ApiCriteria {
        this.convertToApiParams();
        if (this.isValidApiParams(this._apiParams.args) &&
            !this._isEmptyValueOrValuesArgs(this._apiParams.args)) {
            return this._apiParams;
        }
        return null;
    }

    setUriParams(uriParams: any): void {
        if (uriParams) {
            this.initUriParams(uriParams);
            this.convertToParams();
        }
    }
    initUriParams(uriParams: any): void {
        if (uriParams) {
            this._fields.forEach(field => {
                if (!this.isUndefined(uriParams[field])) {
                    this._uriParams[field] = uriParams[field];
                }
            });
        }
    }

    convertToUriParams(): void {
        this._uriParams = _.cloneDeep(this._params);
    }

    async setApiParams(apiParams: ApiCriteria): Promise<void> {
        if (apiParams) {
            this.initApiParams(apiParams);
            // await because of convertToParamsFromApiParams() in SearchEngineConditionQueryStructuredService
            await this.convertToParamsFromApiParams();
        }
    }

    initApiParams(apiParams: ApiCriteria): void {
        if (apiParams) {
            this._apiParams = _.cloneDeep(apiParams);
        }
    }

    convertToApiParams(): void {
        this._apiParams = {
            type: this._slug,
            args: null
        };
    }

    convertToParams(): void {
        this._params = _.cloneDeep(this._uriParams);
    }

    // For service of this type
    // this.params = {someKeyMin: null, someKeyMax: null};
    // this.uriParams = {someKey: ''};
    updateUriParamsComparisonNumber(uriParams: any, uriParamKey: string, params: any, ...paramsKeys: string[]): void {
        if (params && uriParams) {
            uriParams[uriParamKey] = '';
            if (DataHelperService.isDefinedAndNotNull(params[paramsKeys[0]]) &&
                DataHelperService.isDefinedAndNotNull(params[paramsKeys[1]])) {
                uriParams[uriParamKey] = `${params[paramsKeys[0]]};>=<;${params[paramsKeys[1]]}`;
            } else if (DataHelperService.isDefinedAndNotNull(params[paramsKeys[0]])) {
                uriParams[uriParamKey] = `${params[paramsKeys[0]]};>=`;
            } else if (DataHelperService.isDefinedAndNotNull(params[paramsKeys[1]])) {
                uriParams[uriParamKey] = `${params[paramsKeys[1]]};<=`;
            }
        }
    }

    // For service of this type
    // this.params = {someKeyMin: null, someKeyMax: null};
    // this.uriParams = {someKey: ''};
    updateParamsComparisonNumber(uriParams: any, uriParamKey: string, params: any, ...paramsKeys: string[]): void {
        if (params && uriParams) {
            if (uriParams[uriParamKey]) {
                const split = uriParams[uriParamKey].split(';');
                // Comparison
                if (split.length === 3) {
                    params[paramsKeys[0]] = Number.parseFloat(split[0]);
                    params[paramsKeys[1]] = Number.parseFloat(split[2]);
                } else if (split.length === 2) {
                    const paramToUpdate = split[1] === '>=' ? paramsKeys[0] : split[1] === '<=' ? paramsKeys[1] : '';
                    if (paramToUpdate) {
                        params[paramToUpdate] = Number.parseFloat(split[0]);
                    }
                }
            }
        }
    }

    // For service of this type
    // this.params = {startDate: null, endDate: null};
    // this.uriParams = {someKey: ''};
    updateUriParamsComparisonDate(uriParams: any, key: string, params: any): void {
        if (uriParams && params) {
            const uriStartDate = DateHelperService.toMysql(params.startDate);
            const uriEndDate = DateHelperService.toMysql(params.endDate);
            if (uriStartDate &&
                uriEndDate) {
                uriParams[key] = `${uriStartDate};>=<;${uriEndDate}`;
            } else if (uriStartDate) {
                uriParams[key] = `${uriStartDate};>=`;
            } else if (uriEndDate) {
                uriParams[key] = `${uriEndDate};<=`;
            }
        }
    }

    // For service of this type
    // this.params = {startDate: null, endDate: null};
    // this.uriParams = {someKey: ''};
    updateParamsComparisonDate(uriParams: any, key: string, params: any): void {
        if (uriParams && params) {
            const split = uriParams[key].split(';');
            if (split.length === 3) {
                params.startDate =
                    new Date(DateHelperService.mysqlToInput(split[0]));
                params.endDate =
                    new Date(DateHelperService.mysqlToInput(split[2]));
            } else if (split.length === 2) {
                const paramToUpdate = split[1] === '>=' ? 'startDate' : split[1] === '<=' ? 'endDate' : '';
                if (paramToUpdate) {
                    params[paramToUpdate] =
                        new Date(DateHelperService.mysqlToInput(split[0]));
                }
            }
        }
    }

    updateParamsFromApiParamsComparisonDate(apiParams: any, params: any): void {
        if (apiParams && params) {
            params.startDate = new Date(DateHelperService.mysqlToInput(apiParams.args.minValue));
            params.endDate = apiParams.args.maxValue ? new Date(DateHelperService.mysqlToInput(apiParams.args.maxValue)) : null;
        }
    }

    convertToParamsFromApiParams(): void {
        this._params = _.cloneDeep(this._apiParams);
    }

    getComparisonArgsObject(minValue: number | string, maxValue: number | string): ComparisonArgs {
        const comparisonArgs: ComparisonArgs = {minValue, operator: ''};
        if (this.equalsFalse(minValue) && !this.equalsFalse(maxValue)) {
            return {minValue: maxValue, operator: '<='};
        } else if (!this.equalsFalse(minValue) && this.equalsFalse(maxValue)) {
            return {minValue, operator: '>='};
        } else if (!this.equalsFalse(minValue) && !this.equalsFalse(maxValue)) {
            return {...comparisonArgs, maxValue, operator: '>=<'};
        }
        return comparisonArgs;
    }

    resetField(fieldName: string): void {
        if (this._fields.includes(fieldName)) {
            this._uriParams[fieldName] = '';
            this._params[fieldName] = null;
        }
    }

    isUndefined(value: any): boolean {
        return typeof value === 'undefined';
    }

    isEmpty(value: any): boolean {
        if (!this.isUndefined(value)) {
            return value === '' ||
                this.isEmptyArray(value) ||
                this.isEmptyObject(value);
        }
        return null;
    }

    isEmptyArray(array: any[]): boolean {
        return Array.isArray(array) && !array.length;
    }

    isEmptyObject(object: any[]): boolean {
        return object !== null && typeof object === 'object' && !Object.keys(object).length;
    }

    equalsFalse(value: any): boolean {
        return typeof value === 'undefined' ||
            value === null ||
            value === false ||
            value === 'false' ||
            value === '';
    }

    isValid(fields?: any, key?: string): boolean {
        return true;
    }

    isValidComparisonNumbers(fields: any, key: string): boolean {
        if (fields &&
            fields[key]) {
            const split = fields[key].split(';');
            if (split.length === 3) {
                return split[0] >= 0 && split[2] >= 0;
            } else if (split.length === 2) {
                return split[0] >= 0;
            }
        }
        return false;
    }

    isValidApiParams(args: any): boolean {
        return true;
    }
}
