import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as _ from 'lodash-es';

@Component({
    selector: 'ct-stay-detail-edit-act-execution-date',
    templateUrl: './stay-detail-edit-act-execution-date-dialog.component.html',
    styleUrls: ['./stay-detail-edit-act-execution-date-dialog.component.scss']
})
export class StayDetailEditActExecutionDateDialogComponent implements OnInit {
    act: any;
    selectedDateTime: any;
    title: string;
    stayStartDate: any;
    stayEndDate: any;
    errorMessage: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<StayDetailEditActExecutionDateDialogComponent>) { }

    ngOnInit() {
        // get les data dans le this.data
        this.title = 'DATA_SET.CODIFICATION_ELEMENT.ACT.EDIT_DATE';
        this.act = _.cloneDeep(this.data.linkAct);
        this.stayStartDate = this.data.startDate;
        this.stayEndDate = this.data.endDate;
    }
    validateActDate(): boolean {
        const actDate = new Date(this.selectedDateTime);
        const startDate = new Date(this.stayStartDate);
        const endDate = new Date(this.stayEndDate);
        if (actDate < startDate) {
            this.errorMessage = 'DATA_SET.CODIFICATION_ELEMENT.ACT.ERROR_DATE_BEFORE_START_DATE';
        } else if (actDate > endDate) {
            this.errorMessage = 'DATA_SET.CODIFICATION_ELEMENT.ACT.ERROR_DATE_AFTER_END_DATE';
        }
        return !(actDate < startDate || actDate > endDate);
    }

    validate() {
        if (this.validateActDate()) {
            this.act.execution_date = this.selectedDateTime;
            this.dialogRef.close({new: this.act, old: this.data.linkAct});
        }
    }
}
