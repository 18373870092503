import {ErrorHandler, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HotkeyModule} from 'angular2-hotkeys';
import {GlobalErrorHandler} from './core/services/global-error-handler.service';
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {CustomDateAdapter} from './core/adapters/custom-date-adpater';
import {ApiHttpInterceptor} from './core/http/http.interceptor';
import {SnackBarService} from './core/services/snack-bar.service';
import {LayoutModule} from './modules/layout/layout.module';
import {MedicationModule} from './shared/components/medication/medication.module';
import {HighlightModule} from './shared/components/highlight/highlight.module';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {UIRouterModule} from '@uirouter/angular';
import {AppComponent} from './app.component';
import {AuthenticationModule} from './modules/authentication/authentication.module';
import {EntryComponentsModule} from './core/entry-components.module';
import {AppLogService} from './core/app-log/app-log.service';
import {createCustomElement} from '@angular/elements';
import {MedicationComponent} from './shared/components/medication/medication.component';
import {HighlightComponent} from './shared/components/highlight/highlight.component';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';

export function HttpLoaderFactory(http: HttpClient) {
    // Fix for cache busting
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + Date.now());
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient]
            }
        }),
        UIRouterModule.forRoot({
            otherwise: {state: 'authentication.login'},
            useHash: true
        }),
        HotkeyModule.forRoot(),
        MedicationModule,
        HighlightModule,
        AuthenticationModule,
        LayoutModule,
        EntryComponentsModule,
        MatNativeDateModule,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'fr-FR'
        },
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiHttpInterceptor,
            deps: [TranslateService, SnackBarService, AppLogService],
            multi: true
        },
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false }
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private _injector: Injector) {
        this._createCustomElements();
    }

    /**
     * To create Angular Elements
     * @private
     */
    private _createCustomElements() {
        const medicationElement = createCustomElement(MedicationComponent, {injector: this._injector});
        const highlightElement = createCustomElement(HighlightComponent, {injector: this._injector});
        customElements.define('ct-medication', medicationElement);
        customElements.define('ct-highlight', highlightElement);
    }
}
