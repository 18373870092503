import {Injectable} from '@angular/core';
import {HttpService} from '../../../http/http.service';
import {map} from 'rxjs/operators';
import {DataHelperService} from '../../../services/data.helper.service';

@Injectable({
    providedIn: 'root'
})
export class DataSetDataSetElementApiService {
    private _BASE_URL = 'data-set';

    constructor(private _http: HttpService) {}

    getAll(dataSetId: number, body: any) {
        DataHelperService.deleteEmptyUndefinedNullFromObject(body);
        return this._http
            .post(`${this._BASE_URL}/${dataSetId}/data-set-element`, body)
            .pipe(map(res => {
                return {
                    ...res,
                    total: res.meta.total,
                    lastPage: res.meta.last_page,
                    currentPage: res.meta.current_page
                };
            }));
    }

    count(dataSetId: number, body: any) {
        DataHelperService.deleteEmptyUndefinedNullFromObject(body);

        return this._http
            .post(`${this._BASE_URL}/${dataSetId}/data-set-element/count`, body);
    }

    setMultipleRecordUser(dataSetId: number, userId: number, body: any) {
        DataHelperService.deleteEmptyUndefinedNullFromObject(body);
        return this._http
            .post(`${this._BASE_URL}/${dataSetId}/data-set-element/${userId}/attach-record-user`, body);
    }

    unsetMultipleRecordUser(dataSetId: number, userId: number, body: any) {
        DataHelperService.deleteEmptyUndefinedNullFromObject(body);
        return this._http
            .post(`${this._BASE_URL}/${dataSetId}/data-set-element/${userId}/detach-record-user`, body);
    }
    nearAutomation(body?: any) {
        const shouldAddFloorDate = !body.query.args.criteria.some(criterion => criterion.type === 'floorDate');

        if (shouldAddFloorDate) {
            body.query.args.criteria.push({
                'type': 'floorDate',
                'args': {
                    'value': true
                }
            });
        }
        return this._http
            .post(`${this._BASE_URL}/1/data-set-element/supervised-automation`, body)
            .pipe(map(res => {
                return {
                    ...res,
                    total: res.meta.total,
                    lastPage: res.meta.last_page,
                    currentPage: res.meta.current_page
                };
            }));
    }

    exportSearch(dataSetId: number, body?: any) {
        DataHelperService.deleteEmptyUndefinedNullFromObject(body);
        return this._http
            .post(`${this._BASE_URL}/${dataSetId}/data-set-element/export`, body, {responseType: 'blob'});
    }
}
