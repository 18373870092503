<div class="edit-act-date-dialog-content">
    <h1 mat-dialog-title><span [innerHTML]="title | translate | safeHtml"></span></h1>
    <div class="w-50">
        <input matInput type="datetime-local" [(ngModel)]="selectedDateTime">
    </div>
    <div class="text-danger" *ngIf="errorMessage">{{ errorMessage | translate}}</div>
    <div class="add-act-dialog-content d-flex flex-column align-items-end">
        <button mat-raised-button
                type="submit"
                color="primary"
                (click)="validate()"
                class="mt-3">{{ 'BUTTON.VALIDATE' | translate | uppercase }}
        </button>
    </div>
</div>
