import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StayDetailCodificationDiagnosticService} from './stay-detail-codification-diagnostic.service';
import {DataSetElement} from '../../../shared/data-set-element.model';
import {DataSetCodification} from '../../../shared/data-set-codification.model';
import {Subscription} from 'rxjs';
import {BroadcastService} from '../../../../../core/services/broadcast.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslationHelperService} from '../../../../../core/services/translation.helper.service';
import {AppLogService} from '../../../../../core/app-log/app-log.service';
import {DiagnosticNoteService} from '../../../../diagnostic/note/diagnostic-note.service';
import {CdkDragDrop, transferArrayItem} from '@angular/cdk/drag-drop';
import {LayoutService} from '../../../../layout/layout.service';
import {StayDetailJustificationService} from '../../justification/stay-detail-justification.service';
import {DataSetElementService} from '../../../shared/data-set-element.service';
import {FormControl} from '@angular/forms';
import {DashboardService} from '../../../../dashboard/dashboard.service';
import {CodificationLabelEnum} from '../../../shared/codification-label-enum';
import {CodificationLabelsModel} from '../../../shared/codification-labels.model';

@Component({
    selector: 'ct-stay-detail-codification-diagnostic',
    templateUrl: './stay-detail-codification-diagnostic.component.html',
    styleUrls: ['./stay-detail-codification-diagnostic.component.scss']
})
export class StayDetailCodificationDiagnosticComponent implements OnInit, OnDestroy {
    private _subscriptions: Subscription[] = [];

    @Input() dataSetElement: DataSetElement;
    @Input() dataSetCodification: DataSetCodification = {id: 1};
    @Input() predictiveDiagnostics: any[];
    @Input() diagnostics: any[];
    @Input() disableDragAndDrop: boolean;
    @Input() canTriggerCodificationUpdate = true;
    @Input() limitCodificationUpdateToDA = false;
    @Input() codificationLabels: CodificationLabelsModel;

    canDisplayDR: boolean;
    canDisplaySeverityLevel: boolean;
    isTypeRehabilitation: boolean;
    CodificationLabelEnum: typeof CodificationLabelEnum = CodificationLabelEnum;

    diagnosticsToDisplay: any[] = [];
    formControl = new FormControl();

    constructor(private _stayDetailCodificationDiagnosticService: StayDetailCodificationDiagnosticService,
                private _dataSetElementService: DataSetElementService,
                private _stayDetailJustificationService: StayDetailJustificationService,
                private _diagnosticNoteService: DiagnosticNoteService,
                private _translateService: TranslateService,
                private _translationHelperService: TranslationHelperService,
                private _broadcastService: BroadcastService,
                private _layoutService: LayoutService,
                private _logService: AppLogService,
                private dataSetElementService: DataSetElementService,
                private _dashboardService: DashboardService) { }

    async ngOnInit() {
        this.isTypeRehabilitation = this._dataSetElementService.isType(this.dataSetElement, 'rehabilitation');
        this._initAvailableFeatures();
        this._initDiagnosticsToDisplay();
        await this._subscribeToBroadcast();
    }

    private async _subscribeToBroadcast() {
        const sub = this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'diagnostic::replaceImpreciseDiagnostic':
                        this._stayDetailCodificationDiagnosticService.editDiagnostic(this.dataSetElement, this.dataSetCodification.id, res.data)
                            .then(() => {
                                // To update list in the view
                                this._initDiagnosticsToDisplay();
                            });
                        break;
                    case 'diagnostic::refreshDisplay':
                        this._initDiagnosticsToDisplay();
                        break;
                    case 'diagnostic::updateCodification':
                        // Since this component is also used in the diagnostic add dialog
                        // without this.canTriggerCodificationUpdate it would be triggered twice (codification box and add dialog)
                        if (this.canTriggerCodificationUpdate && res.data) {
                            this._updateDiagnosticCodification(res.data)
                                .then(async () => {
                                    // To update list in the view
                                    // If no filterSearchId the diag come from another source
                                    if (this.dataSetElement.parentId && res.data.filterSearchId) {
                                        if (await this.dataSetElementService.setFilterSearchValidity(this.dataSetElement.parentId, res.data.filterSearchId, 1)) {
                                            const eventData = {
                                                stayId: this.dataSetElement.dataSetContent.stayId,
                                                filterSearchStatus: 1,
                                                filterSearchName: res.data.diagnostic.name,
                                                filterSearchId: res.data.filterSearchId,
                                                endDate: this.dataSetElement.dataSetContent.endDate,
                                                type: this.dataSetElement.dataSetContent.type
                                            };
                                            await this._dashboardService.sendEvent('stay_rule_status', eventData);
                                            this.dataSetElement.filtersSearch.filter(el => el.id === res.data.filterSearchId).map(el => el.validityStatus = 1);
                                            this._broadcastService.send('diagnostic::updateFilterSearchTab', res.data);
                                        }
                                    }
                                    this._initDiagnosticsToDisplay();
                                });
                        }
                        break;
                }
            });

        this._subscriptions.push(sub);
    }

    private _initAvailableFeatures() {
        this.canDisplayDR = this._translationHelperService.isFeatureAvailable('DR');
        this.canDisplaySeverityLevel = this._translationHelperService.isFeatureAvailable('diagnosticSeverityLevel');
    }

    private _initDiagnosticsToDisplay() {
        this.codificationLabels.getAppTypeLabels(this.isTypeRehabilitation).forEach(codificationLabelId => {
            this.diagnosticsToDisplay[codificationLabelId] = this.diagnostics
                .filter(diagnostic => diagnostic.codificationLabel.id === codificationLabelId);
            if (codificationLabelId === this.codificationLabels.DA) {
                if (!this.isTypeRehabilitation) {
                    // Remove excluding diagnostics
                    this.diagnosticsToDisplay[codificationLabelId] =
                        this._stayDetailCodificationDiagnosticService
                            .removeDAByExclusion(this.diagnosticsToDisplay[codificationLabelId], this.dataSetElement.excludingDiagnostics);
                }
                // Sort by level and slug
                this.diagnosticsToDisplay[codificationLabelId] = this.diagnosticsToDisplay[codificationLabelId]
                    .sort((a, b) => this._sortByLevelAndSlug(a, b));
            }
        });
    }

    private _sortByLevelAndSlug(a: any, b: any) {
        if (a.diagnostic.diagnosticLevel && b.diagnostic.diagnosticLevel && a.diagnostic.diagnosticLevel.level < b.diagnostic.diagnosticLevel.level) {
            return 1;
        } else if (a.diagnostic.diagnosticLevel && b.diagnostic.diagnosticLevel && a.diagnostic.diagnosticLevel.level > b.diagnostic.diagnosticLevel.level) {
            return -1;
        } else if (a.diagnostic.diagnosticLevel && b.diagnostic.diagnosticLevel && a.diagnostic.diagnosticLevel.level === b.diagnostic.diagnosticLevel.level) {
            return 0;
        } else if (a.diagnostic.slug > b.diagnostic.slug) {
            return 1;
        } else if (a.diagnostic.slug < b.diagnostic.slug) {
            return -1;
        } else {
            return 0;
        }
    }

    /**
     * Add/remove diagnostic
     *
     * @param data
     * @private
     */
    private async _updateDiagnosticCodification(data: any) {
        await this._stayDetailCodificationDiagnosticService.updateDiagnosticCodification(
            this.dataSetElement,
            this.dataSetCodification.id,
            data,
        );
    }

    /**
     * Triggered when a chip is moved from a list to another
     *
     * @param event
     * @param diagnostic
     */
    onDrop(event: CdkDragDrop<string[]>, diagnostic: any) {
        if (event.previousContainer === event.container) {
            return;
        }

        this._layoutService.startLoading();

        transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex
        );

        const codificationLabelSlug = event.container.id.split('_')[0];
        const codificationLabelId = this.codificationLabels[codificationLabelSlug];

        this._updateDiagnosticCodification({diagnostic: diagnostic.diagnostic, codificationLabelId: codificationLabelId, isUpdate: true})
            .then(() => {
                this._layoutService.stopLoading();
                // Init to reorder DA
                this._initDiagnosticsToDisplay();
            })
            .catch(() => {
                this._layoutService.stopLoading();
                this._initDiagnosticsToDisplay();
            });
    }

    isSelected(diagnostic: any) {
        if (!diagnostic?.slug) {
            return;
        }

        return this._stayDetailJustificationService.isTabActiveByName(diagnostic.diagnostic.slug);
    }

    getDiagnosticTooltip(diagnostic: any, displaySeverityLevel: boolean): string {
        if (diagnostic?.diagnostic &&
            (diagnostic.diagnostic.name || diagnostic.diagnostic.description)) {
            return `${diagnostic.diagnostic.name || diagnostic.diagnostic.description} ${displaySeverityLevel && this.canDisplaySeverityLevel && diagnostic.diagnostic.diagnosticLevel ? `(${this._translateService.instant('STUFF.LEVEL')}. ${diagnostic.diagnostic.diagnosticLevel.level})` : ''}`;
        }
        return '';
    }

    getDiagnosticLabelColor(diagnostic: any, suffix?: string): string {
        const stringToReturn = `md-${this._stayDetailCodificationDiagnosticService.getDiagnosticLabelColor(diagnostic, false, this.canDisplaySeverityLevel)}`;
        return !suffix ? stringToReturn : `${stringToReturn}-${suffix}`;
    }

    showJustification(diagnostic: any, isAct?: false) {
        let stay: any;
        if (this.dataSetElement) {
            stay = this.dataSetElement.dataSetContent;
        }
        this._broadcastService.send('dataSetElementStayJustification::addTab', {
            diagnostic: diagnostic.diagnostic,
            stay,
            isAct
        });
        this._logService.logInfo('Looking at diagnostic justification');
    }

    addDiagnostic(data: any) {
        this._layoutService.startLoading();
        this._updateDiagnosticCodification(data)
            .then(() => {
                this._layoutService.stopLoading();
                // Init to reorder DA
                this._initDiagnosticsToDisplay();
            })
            .catch(() => {
                this._layoutService.stopLoading();
                this._initDiagnosticsToDisplay();
            });
    }

    removeDiagnostic(event: any, diagnostic: any) {
        event.stopPropagation();
        this._layoutService.startLoading();
        this._updateDiagnosticCodification({diagnostic: diagnostic.diagnostic, codificationLabelId: diagnostic.codificationLabel.id, isRemoval: true})
            .then(() => {
                this._layoutService.stopLoading();
                // To update list in the view
                this._initDiagnosticsToDisplay();
            })
            .catch(() => {
                this._layoutService.stopLoading();
            });
    }

    openDiagnosticNoteDisplayDialog(diagnostic: any) {
        if (this.hasDiagnosticNotes(diagnostic)) {
            this._diagnosticNoteService
                .openDiagnosticNoteDisplayDialog(diagnostic.diagnostic.id);
            this._logService.logInfo('Looking at diagnostic note');
        }
    }

    hasDiagnosticNotes(diagnostic: any) {
        return diagnostic &&
            diagnostic.diagnostic &&
            diagnostic.diagnostic.diagnosticNote &&
            diagnostic.diagnostic.diagnosticNote.length > 0;
    }

    /**
     * Returns a unique ID based on the codification label
     * and data set element ID.
     *
     * @param codificationLabel
     */
    getUniqueIdFromCodificationLabel(codificationLabel: string): string {
        return `${codificationLabel}_${this.dataSetElement.id}`;
    }

    ngOnDestroy() {
        if (this._subscriptions &&
            this._subscriptions.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }
}
