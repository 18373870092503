<div
    class="tag tag-{{color}} tag-raised align-center"
    (click)="changeValue()"
>
    <mat-icon *ngIf="obj && key" class="margin-right-10">
        {{ obj[key] === true ? 'check_box' : 'check_box_outline_blank' }}
    </mat-icon>

    <mat-icon *ngIf="icon" class="margin-right-5">
        {{ icon }}
    </mat-icon>

    <img
        *ngIf="displayCTLogo"
        class="ct-logo"
        src="./assets/images/i4h.svg"
    />

    <span>{{ text }}</span>
</div>
