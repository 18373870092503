import {HealthStayApiService} from '../../../../core/api-services/health-stay/health-stay.api.service';
import {Injectable} from '@angular/core';
import {DataSetDataSetElementApiService} from '../../../../core/api-services/data-set/data-set-element/data-set-data-set-element.api.service';
import {DataSetCodificationApiService} from '../../../../core/api-services/data-set/codification/data-set-codification.api.service';
import {map} from 'rxjs/operators';
import {DataHelperService} from '../../../../core/services/data.helper.service';
import {DataSetElementService} from '../../shared/data-set-element.service';
import {DataSetElement} from '../../shared/data-set-element.model';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {SearchEngineAdvancedService} from '../../../search-engine/advanced/search-engine-advanced.service';
import {StateService} from '@uirouter/core';
import {QualityControlService} from '../../../quality-control/shared/quality-control.service';

@Injectable({
    providedIn: 'root'
})
export class StayListService {
    private readonly _DATE_SORT_TYPE: string;
    private _APP_TYPE_IS_MCO_AND_SSR: boolean;
    private isAtypicalRequest = false;

    constructor(private _dataSetDataSetElementApiService: DataSetDataSetElementApiService,
                private _dataSetCodificationApiService: DataSetCodificationApiService,
                private _searchEngineAdvancedService: SearchEngineAdvancedService,
                private _qualityControlService: QualityControlService,
                private _dataSetElementService: DataSetElementService,
                private _configurationService: ConfigurationService,
                private _healthStayApiService: HealthStayApiService,
                public $state: StateService) {

        this._DATE_SORT_TYPE = this._configurationService.getConfigurationContent('front', 'advancedSearch.stayResultsDateSortType') || 'endDate';
        this._APP_TYPE_IS_MCO_AND_SSR = this._configurationService.appTypeIsMCOAndSSR();
    }

    updateBodyToLoadDataSetElements(body: any, include: string[]) {
        // Null means we don't want to have include param
        // Undefined means we want the default include param
        if (include !== null) {
            include = include || ['subStays', 'diagnostics', 'establishment', 'user', 'patient'];
            body.include = include;
        }
        body.sort = `-${this._DATE_SORT_TYPE}`;
        body.perPage = body.perPage ? body.perPage : 10;
    }

    async loadDataSetElements(
        dataSetId: number,
        codificationId: number,
        body: any,
        include?: string[],
        isFromPatient: boolean = false,
        isFromNearAutomation: boolean = false
    ) {
        try {
            const shouldAddFloorDate = !isFromPatient && !body.query.args.criteria.some(criterion => criterion.type === 'floorDate');

            if (shouldAddFloorDate) {
                body.query.args.criteria.push({
                    'type': 'floorDate',
                    'args': {
                        'value': true
                    }
                });
            }

            body.codificationId = codificationId;
            this.updateBodyToLoadDataSetElements(body, include);

            return await this._dataSetDataSetElementApiService
                .getAll(dataSetId, body)
                .pipe(map(res => DataHelperService.addInnerHitsToData(res)),
                    map(res => {
                        if (body.dataSetId) {
                            this._searchEngineAdvancedService.filterSearchQuery = body.query;
                            this._searchEngineAdvancedService.temporaryFilterSearchId = res.meta.tempId;
                        }
                        const storageName = isFromNearAutomation ? 'filterSearchQuery-near-automation' : 'filterSearchQuery';
                        // store query if user refresh the page to retrieve it in the detail component
                        sessionStorage.setItem(storageName, JSON.stringify(
                            this._searchEngineAdvancedService.filterSearchQuery
                        ));
                        return {
                            ...res,
                            data: DataHelperService.buildDataSetElements(res.data)
                        };
                    }))
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async countDataSetElements(
        dataSetId: number,
        codificationId: number,
        body: any,
        isFromPatient: boolean = false,
    ) {
        const shouldAddFloorDate = !isFromPatient && !body.query.args.criteria.some(criterion => criterion.type === 'floorDate');

        if (shouldAddFloorDate) {
            body.query.args.criteria.push({
                'type': 'floorDate',
                'args': {
                    'value': true
                }
            });
        }

        body.include = [];
        body.sort = 'id';

        body.codificationId = codificationId;

        try {
            return await this._dataSetDataSetElementApiService
                .count(dataSetId, body)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async attachRecordUser(dataSetId: number,
                              userId: number,
                              codificationId: number,
                              body: any,
                              type: string = 'default;rehabilitation;external',
                              include?: string[]) {
        try {
            body.codificationId = codificationId;
            body.include = ['subStays'];
            body.sort = `-${this._DATE_SORT_TYPE}`;
            this.updateBodyToLoadDataSetElements(body, include);
            return await this._dataSetDataSetElementApiService
                .setMultipleRecordUser(dataSetId, userId, body)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async detachRecordUser(dataSetId: number,
                                 userId: number,
                                 codificationId: number,
                                 body: any,
                                 type: string = 'default;rehabilitation;external',
                                 include?: string[]) {
        try {
            body.codificationId = codificationId;
            body.include = ['subStays'];
            body.sort = `-${this._DATE_SORT_TYPE}`;
            this.updateBodyToLoadDataSetElements(body, include);
            return await this._dataSetDataSetElementApiService
                .unsetMultipleRecordUser(dataSetId, userId, body)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    getObjectToStoreForListingInfo(res: any, id: number, index: number) {
        const objToReturn: any = {
            dataSetElementId: id
        };
        if (index > 0) {
            if (res.data[index - 1].data?.children) {
                objToReturn.previousDataSetElementFirstChildId =
                    res.data[index - 1].data.children[0].id;
            }

            objToReturn.previousDataSetElementId =
                res.data[index - 1].data.id;
        }
        if (index < (res.data.length - 1)) {
            if (res.data[index + 1].data.children?.length) {
                objToReturn.nextDataSetElementFirstChildId =
                    res.data[index + 1].data.children[0].id;
            }

            objToReturn.nextDataSetElementId =
                res.data[index + 1].data.id;
        }
        return objToReturn;
    }

    storeListingInfo(useCase: string, res: any, params: any, numberToLoadPerPage: number) {
        if (res && res.data) {
            const dataSetElements =
                res.data.map((element, index) => this.getObjectToStoreForListingInfo(res, element.data.id, index));

            const objToStore = {
                currentPage: res.currentPage,
                lastPage: res.lastPage,
                data: dataSetElements,
                total: res.total,
                perPage: numberToLoadPerPage,
                params
            };
            let key: string;
            switch (useCase) {
                case 'default;recurring':
                case 'stay':
                    key = 'dataSetElementListingInfo';
                    break;
                case 'patient':
                    key = 'dataSetElementPatientListingInfo';
                    break;
                case 'predictionAnalysis':
                    key = 'dataSetElementPredictionAnalysisListingInfo';
                    break;
                default:
                    return;
            }
            sessionStorage.setItem(key, JSON.stringify(objToStore));
        }
    }

    updateParamsForSwitch(params: any, numberToLoadPerPage: number, storageKey: string) {
        if (params.perPage < numberToLoadPerPage) {
            const info = JSON.parse(sessionStorage.getItem(storageKey));
            if (!info) {
                params.page = 1;
            } else {
                const count = params.page * params.perPage;
                if (count <= numberToLoadPerPage) {
                    params.page = 1;
                } else if (count > numberToLoadPerPage * (info.lastPage - 1)) {
                    params.page = info.lastPage;
                } else {
                    if (count === info.currentPage * numberToLoadPerPage) {
                        params.page = info.currentPage;
                    } else {
                        params.page = Math.ceil(count / numberToLoadPerPage);
                    }
                }
            }
        }
        params.perPage = numberToLoadPerPage;
    }
    /**
     * Load 100 more dataSetElements to be store in localStorage
     * when click on previous/next dataSetElement
     * @param dataSetElement
     * @param dataSetElementListingInfo
     * @param direction
     * @private
     */
    async loadMoreDataSetElements(dataSetElement: DataSetElement, dataSetElementListingInfo: any, direction: string) {
        try {
            const params = {...dataSetElementListingInfo.params};
            if (direction === 'next') {
                params.page = dataSetElementListingInfo.currentPage + 1;
            } else if (direction === 'previous' && dataSetElementListingInfo.currentPage > 1) {
                params.page = dataSetElementListingInfo.currentPage - 1;
            }
            params.perPage = dataSetElementListingInfo.perPage;
            const type = this._dataSetElementService.isType(dataSetElement, 'external') ? 'external' : 'default;rehabilitation';
            this.isAtypicalRequest = params?.query?.args?.criteria?.find(x => x.type === 'predictionSource')?.args === 'atypical';
            let res;
            if (this.$state.params.fromQualityControl === 'true') {
                res = await this._qualityControlService
                    .loadFilterSearchDataSetElements(params, this.isAtypicalRequest);
            } else {
                res = await this.loadDataSetElements(1, 1, params, ['subStays']);
            }


            const newDataSetElements = res.data.map((element, index) => {
                const objToReturn: any = this.getObjectToStoreForListingInfo(res, element.data.id, index);
                if (index === 0 || index === (res.data.length - 1)) {
                    objToReturn.dataSetElementFirstChildId =
                        res.data[index].data.children[0].id;
                }
                return objToReturn;
            });
            const objToStore = {
                currentPage: res.currentPage,
                lastPage: res.lastPage,
                data: newDataSetElements,
                total: res.total,
                perPage: params.perPage,
                params
            };
            sessionStorage.setItem('dataSetElementListingInfo', JSON.stringify(objToStore));
            return objToStore;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async loadCodification(dataSetId: number, codificationId: number) {
        try {
            return await this._dataSetCodificationApiService
                .get(dataSetId, codificationId)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async addStayUser(dataSetContentId: number, userId: number) {
        try {
            return await this._healthStayApiService.addUser(dataSetContentId, {
                userId: userId
            })
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async updateStayUser(dataSetContentId: number, userId: number) {
        try {
            return await this._healthStayApiService.updateUser(dataSetContentId, {
                userId: userId
            })
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async removeStayUser(dataSetContentId: number, userId: number) {
        try {
            return await this._healthStayApiService.removeUser(dataSetContentId, userId)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    getColumnsToExtractForExport(useCase: string) {
        // By default external stays columns
        let columns = [
            {value: 'medicalUnitId', translation: 'EXPORT_CONFIGURATION.MEDICAL_UNIT_NUMBER'},
            {value: 'startDate', translation: 'EXPORT_CONFIGURATION.ENTRY_DATE'},
            {value: 'endDate', translation: 'EXPORT_CONFIGURATION.EXIT_DATE'},
            {value: 'ipp', translation: 'ACRONYM.IPP'}
        ];
        if (useCase === 'stayExternal') {
            columns.push({value: 'classingAct', translation: 'EXPORT_CONFIGURATION.CLASSING_ACTS'});
        }
        const columnsForMCO = [
            {value: 'classingAct', translation: 'EXPORT_CONFIGURATION.CLASSING_ACTS'},
            {value: 'dp', translation: 'EXPORT_CONFIGURATION.DP'},
            {value: 'das', translation: 'EXPORT_CONFIGURATION.DAS'},
            {value: 'ghm', translation: 'EXPORT_CONFIGURATION.GHM'},
            {value: 'ctGhm', translation: 'EXPORT_CONFIGURATION.CT_GHM'},
            {value: 'ghmPrice', translation: 'EXPORT_CONFIGURATION.GHM_PRICE'},
            {value: 'ctGhmPrice', translation: 'EXPORT_CONFIGURATION.CT_GHM_PRICE'},
            {value: 'topDp', translation: 'EXPORT_CONFIGURATION.TOP_DP'},
            {value: 'topDpScore', translation: 'EXPORT_CONFIGURATION.TOP_DP_SCORE'},
            {value: 'topGhm', translation: 'EXPORT_CONFIGURATION.TOP_GHM'},
            {value: 'nbDay', translation: 'EXPORT_CONFIGURATION.STAY_DURATION'},
        ];
        const columnsForSSR = [
            {value: 'classingAct', translation: 'EXPORT_CONFIGURATION.ACTS_CCAM'},
            {value: 'csarrAct', translation: 'EXPORT_CONFIGURATION.ACTS_CSARR'},
            {value: 'mp', translation: 'EXPORT_CONFIGURATION.MP'},
            {value: 'ae', translation: 'EXPORT_CONFIGURATION.AE'},
            {value: 'das', translation: 'EXPORT_CONFIGURATION.DAS'},
            {value: 'gme', translation: 'EXPORT_CONFIGURATION.GME'},
            {value: 'ctGme', translation: 'EXPORT_CONFIGURATION.CT_GME'},
            {value: 'gmePrice', translation: 'EXPORT_CONFIGURATION.GME_PRICE'},
            {value: 'ctGmePrice', translation: 'EXPORT_CONFIGURATION.CT_GME_PRICE'},
            {value: 'nbDay', translation: 'EXPORT_CONFIGURATION.STAY_DURATION'},
            {value: 'dependencies', translation: 'EXPORT_CONFIGURATION.DEPENDENCIES'},
        ];
        if (useCase === 'stayRehabilitation' || useCase === 'rehabilitation') {
            columns = [...columns, ...columnsForSSR];
        } else if (useCase === 'stay' || useCase === 'default;recurring') {
            columns = [...columns, ...columnsForMCO];
        } else if (useCase === 'qualityControl') {
            if (this._APP_TYPE_IS_MCO_AND_SSR) {
                // we splice to delete duplicate columns (das and nbDay)
                columns = [...columns, ...columnsForMCO, ...columnsForSSR];
                columns.splice(4, 1);
                columns.splice(columns.length - 2, 1);
            } else {
                columns = this._configurationService.appTypeIs('MCO') ? [...columns, ...columnsForMCO] : [...columns, ...columnsForSSR];
            }
        }
        return columns;
    }

    getStayTypeOrUseCase(forRehabilitationStay: boolean,
                         forExternalStay: boolean,
                         returnUseCase: boolean = false): string {
        if (forRehabilitationStay) {
            return returnUseCase ? 'stayRehabilitation' : 'rehabilitation';
        } else if (forExternalStay) {
            return returnUseCase ? 'stayExternal' : 'external';
        } else {
            return returnUseCase ? 'stay' : 'default;recurring';
        }
    }

    async loadPredictiveActs(dataSetElement: DataSetElement) {
        try {
            const params = {
                include: 'act,codificationLabel,healthGhm,healthGhs,act.diagnostics',
            };
            return  await this._healthStayApiService
                .loadPredictiveActs(dataSetElement.dataSetContentId, params)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async loadPredictiveDependency(dataSetElement: DataSetElement) {
        try {
            const params = {
                include: 'codificationLabel,codificationLabelCount,codificationLabelExists,healthGme,healthGmeCount,healthGmeExists,healthGmt,healthGmtCount,healthGmtExists',
            };
            return  await this._healthStayApiService
                .loadPredictiveDependencies(dataSetElement.dataSetContentId, params)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}
