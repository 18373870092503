import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'ct-search-engine-condition-select-with-dynamic-data',
  templateUrl: './search-engine-condition-select-with-dynamic-data.component.html',
  styleUrls: ['./search-engine-condition-select-with-dynamic-data.component.scss']
})
export class SearchEngineConditionSelectWithDynamicDataComponent implements OnInit, AfterViewInit {
    @ViewChild('multipleSelect', { static: true }) matSelect;

    @Input() condition: any;
    @Input() data: any[] = [];
    @Input() paramKey: string;
    @Input() toggleAllMapCallback: (element: any) => any;
    @Input() placeholder: string;
    @Input() isLoading: boolean;

    constructor() {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        // To fix jumping scroll when select an option
        (<any> this.matSelect)._getItemHeight = () => this.matSelect._triggerFontSize * 1.85;
    }

    isIndeterminate(): boolean {
        return this.condition?.service?.params[this.paramKey]?.length !== 0 &&
            this.condition?.service?.params[this.paramKey]?.length !== this.data?.length;
    }

    isChecked(): boolean {
        return this.condition?.service?.params[this.paramKey]?.length === this.data?.length;
    }

    toggleAll(event: any): void {
        // To prevent the checkbox to be checked because when we update the ids
        // the isChecked method updates the checkbox checked attribute
        if (event) {
            event.preventDefault();
        }
        if (this.condition?.service?.params[this.paramKey]?.length === this.data?.length) {
            this.condition.service.params[this.paramKey] = [];
        } else {
            this.condition.service.params[this.paramKey] = this.data
                ?.slice(0)
                .map(this.toggleAllMapCallback);
        }
    }
}
