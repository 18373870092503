<div class="layout-fill align-center-center login-content">
    <form *ngIf="forgotPasswordForm"
          [formGroup]="forgotPasswordForm">
        <mat-card class="login-card">
            <mat-card-header>
                <mat-card-title class="login-card-title">
                    <span class="mat-headline">{{'AUTHENTICATION.TITLE' | translate}}</span>
                </mat-card-title>
            </mat-card-header>

            <mat-card-content>
                <ng-container *ngIf="!loading">
                    <div class="message align-center-center" [ngClass]="{'success-message': isSuccessMessage, 'error-message': !isSuccessMessage}">
                        <span>{{ message }}</span>
                    </div>

                    <div class="form-field-container">
                        <mat-icon class="mat-icon-prefix"
                                  [ngClass]="{'input-focused': emailInputFocused &&
                               !formControls['email'].errors &&
                                !error}">person</mat-icon>
                        <mat-form-field [ngClass]="{'form-field-invalid': error}">
                            <input matInput
                                   required
                                   type="email"
                                   formControlName="email"
                                   [placeholder]="'INPUT.EMAIL' | translate">

                            <mat-error *ngFor="let validationError of validationErrors.email">
                                <ng-container *ngIf="formControls['email'].hasError(validationError.type)">
                                    {{validationError.message}}
                                </ng-container>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="align-center-center">
                        <a class="forgot-password"
                           (click)="goBackToLogin()">
                            {{'AUTHENTICATION.GO_BACK' | translate}}
                        </a>
                    </div>
                </ng-container>

                <div class="align-center-center">
                    <mat-progress-spinner *ngIf="loading"
                                          mode="indeterminate"
                                          [diameter]="'170'">
                    </mat-progress-spinner>
                </div>
            </mat-card-content>

            <mat-card-actions *ngIf="!loading">
                <button mat-raised-button
                        type="submit"
                        color="primary"
                        [disabled]="!forgotPasswordForm.valid"
                        (click)="forgotPassword()">
                    {{('BUTTON.RESET_PASSWORD' | translate) | uppercase}}
                </button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>

