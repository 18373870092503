import {Injectable} from '@angular/core';
import {HttpService} from '../../http/http.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class QualifactApiService {
    private _BASE_URL = 'qualifact';

    constructor(private _http: HttpService) {
    }

    getAllControls(urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/typology`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }
}
