import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash-es';
import {StateService} from '@uirouter/core';
import {DataSetElement} from '../../shared/data-set-element.model';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {DataSetElementService} from '../../shared/data-set-element.service';
import {StayDetailService} from '../stay-detail.service';
import {AppLogService} from '../../../../core/app-log/app-log.service';
import {DashboardService} from '../../../dashboard/dashboard.service';
import {ActService} from '../../../../core/services/act.service';
import {SearchEngineAdvancedService} from '../../../search-engine/advanced/search-engine-advanced.service';
import {QueryOperators} from '../../../search-engine/advanced/query-builder/enums/operators';
import {CodificationLabelsModel} from '../../shared/codification-labels.model';
import {dependencesType} from '../../../search-engine/search-engine.helper.service';

interface TagTab {
    id: number;
    name: string;
    display: boolean;
    label: string;
    numberOfFiltersSearch: number;
}

@Component({
    selector: 'ct-stay-detail-filter-search',
    templateUrl: './stay-detail-filter-search.component.html',
    styleUrls: ['./stay-detail-filter-search.component.scss']
})
export class StayDetailFilterSearchComponent implements OnInit, OnDestroy {
    private _subscriptions: Subscription[] = [];
    private _lastFilterSearchViewed: any;
    private _filtersSearch: any[];

    @Input() dataSetElement: DataSetElement;
    @Input() dataSetElementParent: DataSetElement;
    @Input() predictiveDiagnostics: any[];
    @Input() diagnostics: any[];
    @Input() codificationLabels: CodificationLabelsModel;
    @Input() isTypeRehabilitation: boolean;

    _currentFilterSearch: any;
    activeFilterTabId: any;
    activeTabId: number;
    currentFilterSearchActs: any[] = [];
    filterSearchTagTabs: TagTab[] = [];
    filtersSearchToDisplay: any[];
    isGetActsLoading: any = {};
    isLoading: boolean;
    refreshFilterSearch = false;
    tagsTabs: TagTab[] = [];
    validityFiltersSearchToDisplay: any[];
    dependenciesType = dependencesType;

    constructor(
        public $state: StateService,
        private _broadcastService: BroadcastService,
        private _translateService: TranslateService,
        private _dataSetElementService: DataSetElementService,
        private _stayDetailService: StayDetailService,
        private _actService: ActService,
        private _logService: AppLogService,
        private _dashboardService: DashboardService,
        private _searchEngineAdvancedService: SearchEngineAdvancedService
    ) {
        this.activeTabId = 1;
        this.activeFilterTabId = 1;
    }

    ngOnInit() {
        this._lastFilterSearchViewed = JSON.parse(sessionStorage.getItem('lastFilterSearchViewed')) || {};
        this._currentFilterSearch = JSON.parse(sessionStorage.getItem('currentFilterSearch')) || null;
        const isFilterSearchUpdated = sessionStorage.getItem('isFilterSearchUpdated') === 'true';
        if (!isFilterSearchUpdated &&
            !this._currentFilterSearch &&
            this.$state.params.fromFilterSearchList === 'true') {
            this._currentFilterSearch = this._lastFilterSearchViewed;
        }

        if (this._currentFilterSearch &&
            this._currentFilterSearch.id &&
            this._currentFilterSearch.name) {
            this._sendEventForDashboard(this._currentFilterSearch.name, this._currentFilterSearch.id);
        }

        if (this.dataSetElement) {
            this.tagsTabs = [
                {
                    id: 1,
                    name: 'all',
                    display: true,
                    label: this._translateService.instant('FILTER_SEARCH.LIST.SEARCH.ALL'),
                    numberOfFiltersSearch: -1
                },
                {
                    id: 2,
                    name: 'diagnosis',
                    display: !this._dataSetElementService.isType(this.dataSetElement, 'external'),
                    label: this._translateService.instant('HEALTH.DIAGNOSTICS'),
                    numberOfFiltersSearch: -1
                },
                {
                    id: 3,
                    name: 'act',
                    display: !this._dataSetElementService.isType(this.dataSetElement, 'external'),
                    label: this._translateService.instant('HEALTH.ACTS'),
                    numberOfFiltersSearch: -1
                },
                {
                    id: 4,
                    name: 'atypia',
                    display: !this._dataSetElementService.isType(this.dataSetElement, 'external'),
                    label: this._translateService.instant('HEALTH.ATYPIAS'),
                    numberOfFiltersSearch: -1
                },
                {
                    id: 6,
                    name: 'Dependency',
                    display: !this._dataSetElementService.isType(this.dataSetElement, 'external') && this.isTypeRehabilitation,
                    label: this._translateService.instant('HEALTH.DEPENDENCY'),
                    numberOfFiltersSearch: -1
                },
                {
                    id: 5,
                    name: 'other',
                    display: !this._dataSetElementService.isType(this.dataSetElement, 'external'),
                    label: this._translateService.instant('STUFF.OTHER_PLURAL'),
                    numberOfFiltersSearch: -1
                }
            ];
            this.filterSearchTagTabs = [
                {
                    id: 1,
                    name: 'all',
                    display: true,
                    label: this._translateService.instant('FILTER_SEARCH.LIST.SEARCH.ALL'),
                    numberOfFiltersSearch: -1
                },
                {
                    id: 2,
                    name: 'waiting',
                    display: !this._dataSetElementService.isType(this.dataSetElement, 'external'),
                    label: this._translateService.instant('STAY.STATUS_WAITING'),
                    numberOfFiltersSearch: -1
                },
                {
                    id: 3,
                    name: 'valid',
                    display: !this._dataSetElementService.isType(this.dataSetElement, 'external'),
                    label: this._translateService.instant('STAY.STATUS_VALID'),
                    numberOfFiltersSearch: -1
                },
                {
                    id: 4,
                    name: 'invalid',
                    display: !this._dataSetElementService.isType(this.dataSetElement, 'external'),
                    label: this._translateService.instant('STAY.STATUS_INVALID'),
                    numberOfFiltersSearch: -1
                }
            ];
            this._loadFilterSearchRules();
        }
        this._subscribeToBroadcast();
    }

    hasDiagnosisMissingSlugFilter(filterSearch: any) {
        return !!this._searchEngineAdvancedService.filterSearchQueryHasCondition(
            'diagnosisMissing', filterSearch?.params?.query?.args?.criteria
        );
    }

    hasDependencyMissingSlugFilter(filterSearch: any) {
        const elements = [];
        this.dependenciesType.forEach((dependencyType) => {
            elements.push(...this._searchEngineAdvancedService.getFilterSearchSlugsByConditionType(
                dependencyType, filterSearch?.params?.query?.args?.criteria, 'NAND'
            ));
        });
        return elements.length > 0;
    }
    get hasActPresenceSlug(): boolean {
        return !!this._searchEngineAdvancedService.filterSearchQueryHasCondition(
            'actPresence',
        );
    }
    private getActPresenceSlug(filterSearch: any) {
        return this._searchEngineAdvancedService.filterSearchQueryGetCondition(
            'actPresence',
            filterSearch?.params?.query?.args?.criteria
        );
    }
    get hasActMissingSlug(): any {
        return !!this.isOperatorNandOrNor() ? true ?? false : false;
    }

    private _subscribeToBroadcast() {
        const sub = this._broadcastService.broadcastData
            .subscribe(async (res) => {
                switch (res.message) {
                    case 'stayDetailJustification::search':
                        this._currentFilterSearch = null;
                        break;
                    case 'stayDetailFilterSearch::clickOnTagTab':
                        if (res.data) {
                            this.activeTabId = res.data.id;
                            this._updateFilterSearchToDisplay();
                            // We reload and empty state params if filter search in
                            // new tab does not contain currentFilterSearch
                            if (!this._hasCurrentFilterSearch()) {
                                this.search(null, true);
                            }
                        }
                        break;
                    case 'stayDetailFilterSearch::clickOnFilterSearchTagTab':
                        if (res.data) {
                            this.activeFilterTabId = res.data.id;
                            this._updateFilterSearchToDisplay();
                            // We reload and empty state params if filter search in
                            // new tab does not contain currentFilterSearch
                            if (!this._hasCurrentFilterSearch()) {
                                this.search(null, true);
                            }
                        }
                        break;
                    case 'act::addActs':
                    case 'act::addAct':
                        if (res.data?.acts) {
                            this.dataSetElement.dataSetContent.linkActNotGrouped = this.dataSetElement.dataSetContent.linkActNotGrouped.concat(res.data.acts);
                        }
                        break;
                    case 'act::deleteAct':
                        if (res.data?.act) {
                            this.dataSetElement.dataSetContent.linkActNotGrouped = this.dataSetElement.dataSetContent.linkActNotGrouped.filter(act => act.id !== res.data.act.id);
                        }
                        break;
                    case 'diagnostic::removeDiagnostic':
                    case 'diagnostic::editDiagnostic':
                    case 'diagnostic::updateFilterSearchTab':
                        this._setFiltersSearch();
                        this._updateFilterSearchToDisplay();
                        this._initTabsNumberOfFiltersSearch();
                        break;
                    case 'filterSearch::load':
                        this.refreshFilterSearch = true;
                        await this._loadFilterSearchRules();
                        break;
                    default:
                }
            });
        this._subscriptions.push(sub);
    }

    private _hasCurrentFilterSearch() {
        if (this._currentFilterSearch) {
            return !!this.filtersSearchToDisplay.find(el => el.id === this._currentFilterSearch.id);
        }
        return false;
    }

    private _getFilterSearchFilteredByType(type: string): any[] {
        return this._filtersSearch?.filter(el => el.type === type) ?? [];
    }

    private _getFilterSearchFilteredByTagName(tagName: string): any[] {
        switch (tagName) {
            case 'all':
            default:
                return _.cloneDeep(this._filtersSearch);
            case 'diagnosis':
                return this._filtersSearch.filter(el => !!(el.tags && el.tags.find(tag => tag.name === 'diagnosis')));
            case 'act':
                return this._filtersSearch.filter(el => this.isActFilterSearch(el));
            case 'none':
                return this._filtersSearch.filter(el => el.tags && !el.tags.length);
             case 'dependency':
                 return this._filtersSearch.filter(el => !!(el.tags && el.tags.find(tag => tag.name === 'dependency')));
        }
    }

     getFilterSearchFilteredByFilterSearchTagName(filterSearch: any[], tagName: string): any[] {
        switch (tagName) {
            case 'all':
            default:
                return filterSearch;
            case 'waiting':
                return filterSearch?.filter(el => el.validityStatus == 0);
            case 'valid':
                return filterSearch?.filter(el => el.validityStatus == 1);
            case 'invalid':
                return filterSearch?.filter(el => el.validityStatus == 2);
        }
    }

    isActFilterSearch(filterSearch: any): boolean {
        if (filterSearch?.tags) {
            const tags = filterSearch.tags.map(tag => tag.name);
            // If we have a filterSearch with the tags act and diagnosis we give the priority to diagnosis (so it's not considered as an act filter search)
            return tags.includes('act') && !tags.includes('diagnosis');
        }
        return false;
    }

    private _updateFilterSearchToDisplay(): void {
        switch (this.activeTabId) {
            case 1:
            default:
                this.filtersSearchToDisplay = this._getFilterSearchFilteredByTagName('all');
                this.validityFiltersSearchToDisplay = this.getFilterSearchFilteredByFilterSearchTagName(this.filtersSearchToDisplay, this.getFilterSearchTabByName(this.activeFilterTabId));
                break;
            case 2:
                this.filtersSearchToDisplay = this._getFilterSearchFilteredByTagName('diagnosis');
                this.validityFiltersSearchToDisplay = this.getFilterSearchFilteredByFilterSearchTagName(this.filtersSearchToDisplay, this.getFilterSearchTabByName(this.activeFilterTabId));
                break;
            case 3:
                this.filtersSearchToDisplay = this._getFilterSearchFilteredByTagName('act');
                this.validityFiltersSearchToDisplay = this.getFilterSearchFilteredByFilterSearchTagName(this.filtersSearchToDisplay, this.getFilterSearchTabByName(this.activeFilterTabId));
                break;
            case 4:
                this.filtersSearchToDisplay = this._getFilterSearchFilteredByType('healthStayAtypia');
                this.validityFiltersSearchToDisplay = this.getFilterSearchFilteredByFilterSearchTagName(this.filtersSearchToDisplay, this.getFilterSearchTabByName(this.activeFilterTabId));
                break;
            case 5:
                this.filtersSearchToDisplay = this._getFilterSearchFilteredByTagName('none');
                this.validityFiltersSearchToDisplay = this.getFilterSearchFilteredByFilterSearchTagName(this.filtersSearchToDisplay, this.getFilterSearchTabByName(this.activeFilterTabId));
                break;
            case 6:
                this.filtersSearchToDisplay = this._getFilterSearchFilteredByTagName('dependency');
                this.validityFiltersSearchToDisplay = this.getFilterSearchFilteredByFilterSearchTagName(this.filtersSearchToDisplay, this.getFilterSearchTabByName(this.activeFilterTabId));
                break;
        }
        this._initTabsNumberOfFiltersSearch();
    }

    private _initTabsNumberOfFiltersSearch(): void {
        if (this.tagsTabs) {
            this.tagsTabs[0].numberOfFiltersSearch = this._getFilterSearchFilteredByTagName('all')?.length;
            this.tagsTabs[1].numberOfFiltersSearch = this._getFilterSearchFilteredByTagName('diagnosis')?.length;
            this.tagsTabs[2].numberOfFiltersSearch = this._getFilterSearchFilteredByTagName('act')?.length;
            this.tagsTabs[3].numberOfFiltersSearch = this._getFilterSearchFilteredByType('healthStayAtypia')?.length;
            this.tagsTabs[4].numberOfFiltersSearch = this._getFilterSearchFilteredByTagName('none')?.length;
            this.tagsTabs[5].numberOfFiltersSearch = this._getFilterSearchFilteredByType('dependency')?.length;
        }

        if (this.filterSearchTagTabs) {
            this.filterSearchTagTabs[0].numberOfFiltersSearch = this.getFilterSearchFilteredByFilterSearchTagName(this.filtersSearchToDisplay, this.getFilterSearchTabByName(1))?.length;
            this.filterSearchTagTabs[1].numberOfFiltersSearch = this.getFilterSearchFilteredByFilterSearchTagName(this.filtersSearchToDisplay, this.getFilterSearchTabByName(2))?.length;
            this.filterSearchTagTabs[2].numberOfFiltersSearch = this.getFilterSearchFilteredByFilterSearchTagName(this.filtersSearchToDisplay, this.getFilterSearchTabByName(3))?.length;
            this.filterSearchTagTabs[3].numberOfFiltersSearch = this.getFilterSearchFilteredByFilterSearchTagName(this.filtersSearchToDisplay, this.getFilterSearchTabByName(4))?.length;
        }
    }

    private async _loadFilterSearchRules() {
        try {
            if (this.dataSetElement) {
                this.isLoading = true;
                if (this.dataSetElement?.filtersSearch?.length && !this.refreshFilterSearch) {
                    this._filtersSearch = this.dataSetElement.filtersSearch;
                } else {
                    const filtersSearch = await this._dataSetElementService
                        .loadFilterSearchRules(this.dataSetElement.parentId);
                    if (this.dataSetElement) {
                        this.dataSetElement.filtersSearch = filtersSearch;
                    }
                    this._filtersSearch = filtersSearch;
                }
                if (this._currentFilterSearch && this.hasActMissingSlug) {
                    await this._loadMissingActs();
                }
                this._setFiltersSearch();
                this._updateFilterSearchToDisplay();
                this._initTabsNumberOfFiltersSearch();
                this.isLoading = false;
                this.refreshFilterSearch = false;
            }
        } catch (e) {
            this.isLoading = false;
            console.error(e);
            throw e;
        }
    }

    private async _loadMissingActs() {
        try {
            this.isGetActsLoading.value = true;
            let actMissingParam: any[];
            this.isOperatorNandOrNor() ? actMissingParam = this.getActPresenceSlug(this._currentFilterSearch)?.args?.slugs ?? [] : actMissingParam = [];
            // Have to do a query per slug ...
            const promises = [];
            if (actMissingParam?.length) {
                const date = this.dataSetElement?.dataSetContent?.endDate || this.dataSetElement?.dataSetContent?.startDate;
                actMissingParam.forEach(slug => promises.push(this._actService.loadHealthActsWithES(slug, null, date).toPromise()));
                const data = await Promise.all(promises);
                if (data?.length) {
                    this.currentFilterSearchActs = [].concat(...data);
                }
                this.isGetActsLoading.value = false;
            }
        } catch (e) {
            this.isGetActsLoading.value = false;
            console.error(e);
            throw e;
        }
    }

    private _setFiltersSearch() {
        // If user is collective we want to display all private filtersSearch
        if (!this._isCurrentUserCollective()) {
            const currentUserId = (JSON.parse(localStorage.getItem('user')) || {}).id;
            this._filtersSearch = this._filtersSearch.filter(filterSearch => filterSearch.isPublic ||
                (!filterSearch.isPublic && filterSearch.userId === currentUserId)); // Display public and current user private filters search
        }
    }

    private _isCurrentUserCollective(): boolean {
        const currentUser = JSON.parse(localStorage.getItem('user')) || {};
        return !!(currentUser.email && currentUser.email.includes('@collective-thinking'));
    }

    private _orderFiltersSearch(filtersSearch: any[]): any[] {
        return _.orderBy(filtersSearch, ['name'], ['asc']);
    }

    trackByFn(index: number) {
        return index;
    }

    filterFiltersSearch(useCase?: string) {
        switch (useCase) {
            case 'codedActs':
                // If we have a filterSearch with the tags act and diagnosis, prioritize diagnosis
                return this._orderFiltersSearch(this.validityFiltersSearchToDisplay.filter(el =>
                    this.dataSetElementParent.children.some(dataElement =>
                        this._stayDetailService.hasCodedActs(dataElement, el) &&
                        !this._stayDetailService.hasCodedDiagnostics(dataElement, el) &&
                        !this.hasRevaluation(el)
                    )
                ));

            case 'codedDiagnostics':
                // Filter for elements with coded diagnostics
                return this._orderFiltersSearch(this.validityFiltersSearchToDisplay.filter(el =>
                    this.dataSetElementParent.children.some(dataElement =>
                        this._stayDetailService.hasCodedDiagnostics(dataElement, el)
                    )
                ));
                // every so the revaluation does not appear duplicate in display
            case 'revaluation':
                // Filter for elements with revaluation
                return this._orderFiltersSearch(this.validityFiltersSearchToDisplay.filter(el =>
                    this.dataSetElementParent.children.every(dataElement =>
                        !this._stayDetailService.hasCodedDiagnostics(dataElement, el) &&
                        this.hasRevaluation(el)
                    )
                ));

            default:
                // Default case: Filter for elements without coded acts, diagnostics, and revaluation
                return this._orderFiltersSearch(this.validityFiltersSearchToDisplay.filter(el =>
                    this.dataSetElementParent.children.every(dataElement =>
                        !this._stayDetailService.hasCodedActs(dataElement, el) &&
                        !this._stayDetailService.hasCodedDiagnostics(dataElement, el) &&
                        !this.hasRevaluation(el)
                    )
                ));
        }
    }

    getFilterSearchTabByName(activeFilterTabId: any) {
        switch (activeFilterTabId) {
            case 1:
            default:
                return 'all';
            case 2:
                return 'waiting';
            case 3:
                return 'valid';
            case 4:
                return 'invalid';
        }
    }

    isFilterSearchSelected(filterSearch: any): boolean {
        return !!(this._currentFilterSearch &&
            this._currentFilterSearch.id === filterSearch.id &&
            ((this._currentFilterSearch.id === this._lastFilterSearchViewed.id &&
                    !this._currentFilterSearch.params !== this._lastFilterSearchViewed.params) ||
                this._currentFilterSearch.id !== this._lastFilterSearchViewed.id)
        );
    }

    getChipColor(filterSearch: any): string {
        if (filterSearch) {
            const diagnostic = this._stayDetailService.findRevalDiagnostic(this.dataSetElement, filterSearch);
            return diagnostic ? 'color-red' : '';
        }
        return '';
    }

    getTooltip(filterSearch: any, isDiagnostic: boolean, useCase?: string): string {
        if (filterSearch) {
            switch (useCase) {
                case 'coded':
                    const key = isDiagnostic ? 'TOOLTIP.ALREADY_CODED_DIAGNOSTIC' : 'TOOLTIP.ALREADY_CODED_ACT';
                    const codedText = `${filterSearch.name} </br> ${this._translateService.instant(key)}`;
                    return filterSearch.isPublic ? codedText : `${codedText} </br> ${this._translateService.instant('TOOLTIP.FILTER_SEARCH_PRIVATE')}`;
                case 'revaluation':
                    const revaluationText = `${filterSearch.name} </br> ${this._translateService.instant('TOOLTIP.CMA_REVALUATION')}`;
                    return filterSearch.isPublic ? revaluationText : `${revaluationText} </br> ${this._translateService.instant('TOOLTIP.FILTER_SEARCH_PRIVATE')}`;
                default:
                    const defaultText = filterSearch.name;
                    return filterSearch.isPublic ? defaultText : `${defaultText} </br> ${this._translateService.instant('TOOLTIP.FILTER_SEARCH_PRIVATE')}`;
            }
        }
        return '';
    }

    hasRevaluation(filterSearch: any): boolean {
        return this.getChipColor(filterSearch) === 'color-red';
    }
    private _sendEventForDashboard(filterSearchName: string, filterSearchId: number) {
        const eventData = {
            stayId: this.dataSetElement.dataSetContent.stayId,
            endDate: this.dataSetElement.dataSetContent.endDate,
            type: this.dataSetElement.dataSetContent.type,
            filterSearchName,
            filterSearchId
        };
        this._dashboardService.sendEvent('stay_rule_check', eventData);
    }

    /**
     * Searches and potentially reloads a specific filter search.
     *
     * Handles the logic for searching filters and managing their state within the session.
     *
     * @param filterSearch The search filter object.
     * @param reloadAndEmpty Indicate whether to reload and empty the filter search.
     */
    search(filterSearch: any, reloadAndEmpty: boolean = false) {
        if (filterSearch) {
            this._sendEventForDashboard(filterSearch.name, filterSearch.id);
        }

        // Click on selected filterSearch
        if (reloadAndEmpty || this._currentFilterSearch?.id === filterSearch?.id) {
            sessionStorage.removeItem('currentFilterSearch');

            this._currentFilterSearch = null;
            this.currentFilterSearchActs = [];
            this._broadcastService.send('stayDetailFilterSearch::reload', {isSelected: true, reloadOnlyFirstTab: true, filtersSearch: this._currentFilterSearch});
        } else {
            sessionStorage.setItem('currentFilterSearch', JSON.stringify(filterSearch));

            this._logService.logInfo('Click on linked filter search');
            this._currentFilterSearch = filterSearch;
            if ([QueryOperators.Nand, QueryOperators.Nor].includes(this.getActPresenceSlug(filterSearch)?.args?.operator)) {
                this._loadMissingActs();
            } else {
                this.currentFilterSearchActs = [];
            }
            this._broadcastService.send('stayDetailFilterSearch::reload', {isSelected: false, reloadOnlyFirstTab: true, filtersSearch: this._currentFilterSearch});
        }
    }

    /**
     * Determines if the operator in the `_getActPresenceSlug` is 'NAND' or 'NOR'.
     *
     * @returns {boolean}
     */
    private isOperatorNandOrNor(): boolean {
        return [QueryOperators.Nand, QueryOperators.Nor].includes(this.getActPresenceSlug(this._currentFilterSearch)?.args?.operator);
    }

    ngOnDestroy() {
        if (this._subscriptions?.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
            this.currentFilterSearchActs = [];
        }
    }
}
