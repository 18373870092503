import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {ActApiService} from '../api-services/act/act.api.service';
import {TranslationHelperService} from './translation.helper.service';

@Injectable({
    providedIn: 'root'
})
export class ActService {
    constructor(private _translationHelperService: TranslationHelperService,
                private _actApiService: ActApiService) {}

     loadHealthActsWithES(query: string, type?: string|string[], date?: string) {
        const params: any = {q: query, perPage: 1000};
        // Acts CSARR only in France then we need all the acts for other languages
        if (this._translationHelperService.isFeatureAvailable('CSARRActs') && type) {
            params.type = type;
        }
        if (date) {
            params.validFrom = `${date.substr(0, 10)};<=`;
            params.validTo = `${date.substr(0, 10)};>=`;
        }
        return this._actApiService
            .getAllWithES(params)
            .pipe(map(res => res.map(el => el.data)));
    }
}
