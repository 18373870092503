import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ct-stay-detail-element-qualifact-control',
  templateUrl: './stay-detail-element-qualifact-control.component.html',
  styleUrls: ['./stay-detail-element-qualifact-control.component.scss']
})
export class StayDetailElementQualifactControlComponent implements OnInit {

   panelOpenState = false;
   @Input() qualifactControl: any;
   @Input() isActived: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
