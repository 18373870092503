import {TranslateService} from '@ngx-translate/core';
import {SearchEngineConditionQualifactComponent} from './search-engine-condition-qualifact.component';
import {DocumentTypeListService} from '../../../document-type/list/document-type-list.service';
import {ApiCriteria, ComparisonArgs, SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionQualifactService extends SearchEngineCondition {

    constructor(private _translateService: TranslateService,
                private _documentTypeListService: DocumentTypeListService) {
        super([],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.QUALIFACT'),
            'qualifactControl',
            SearchEngineConditionQualifactComponent,
            true);
        this.init();
    }

    async init(uriParams?: any, apiParams?: ApiCriteria): Promise<void> {
        this.params = {
            codes: [],
            label: '',
            diagnoses: [],
            acts: []
        };
        await this.setApiParams(apiParams);
    }

    convertToParamsFromApiParams(): void {
        this.params = {...this.apiParams.args};
    }


    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {...this.params};
    }
}
