<div class="field-container"
     [ngStyle]="{'justify-content': justifyContent}">
    <span class="ct-text-field"
          [ngClass]="{'font-weight-700': isKeyBold}"
          *ngIf="key">{{ key }} :</span>
    <br *ngIf="breakLine === true">
    <span class="no-data"
          *ngIf="!value && value !== 0">{{ 'ERROR.NO_FIELD' | translate }}</span>

    <span [ngClass]="{'font-weight-700': isValueBold, 'no-data': isValueDisabled}"
          [placement]="tooltipPlacement"
          tooltipClass="ngb-tooltip"
          [ngbTooltip]="tooltipText">
        <span ctCustomHtmlAttribute
              [htmlAttributeName]="htmlAttributeName"
              [htmlAttributeValue]="htmlAttributeValue">{{ value }}</span> {{ unit }}</span>
</div>
