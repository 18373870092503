import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FilterSearchService} from '../filter-search.service';
import {FilterSearch} from '../filter-search.model';
import * as _ from 'lodash-es';
import {TranslateService} from '@ngx-translate/core';
import {FormControl} from '@angular/forms';
import {debounceTime, startWith} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {StateService} from '@uirouter/core';
import {AuthenticationService} from '../../../../core/authentication/authentication.service';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {ConfirmDialogComponent} from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import {FilterSearchEditDialogService} from './filter-search-edit-dialog.service';
import {ColorService} from '../../../../core/services/color.service';
import {DateHelperService} from '../../../../core/services/date.helper.service';
import {WorklistUserElementComponent} from '../../../../shared/components/worklist-user-element/worklist-user-element.component';
import {SearchEngineAdvancedService} from '../../../search-engine/advanced/search-engine-advanced.service';
import {StayDetailService} from '../../../stay/detail/stay-detail.service';
import {dependencesType} from '../../../search-engine/search-engine.helper.service';

@Component({
    selector: 'ct-filter-search-edit-dialog',
    templateUrl: './filter-search-edit-dialog.component.html',
    styleUrls: ['./filter-search-edit-dialog.component.scss']
})
export class FilterSearchEditDialogComponent implements OnInit, OnDestroy {
    @ViewChild('submitButton', {read: ElementRef}) submitButton: ElementRef;

    // We pass the service as parameter because otherwise the MatDialogComponent
    // throws a dependency injection error (we inject the service that created the dialog)
    private _filterSearchService: FilterSearchService;
    private _filterSearch: any;
    private _params: any;
    private _initialFiltersSearch: FilterSearch[]; // Copy of filtersSearch for research
    private _subscriptions: Subscription[] = [];
    private _lastFilterSearchViewed = null;
    private _lastFilterSearchViewedStorageKey: string;
    private _isStayListUseCase: boolean;

    canDisplayCommon: boolean;
    filterSearchData: any = {};
    filtersSearch: FilterSearch[] = [];
    filterSearchToUpdateId: number;
    filterSearchCtrl: FormControl = new FormControl();
    isLoading = false;
    saveType = 'creation';
    useCase: string;
    dateFormInvalidPropertyValue: boolean;
    isSetValidityFrameActivated = true;
    setValidityFrame = false;
    validityFrameStartDate: Date;
    validityFrameEndDate: Date;
    isWorkList = false;
    ruleType = 'isPublic';
    private userList: any[];
    private removeUser: any[] | undefined;
    dependenciesType = dependencesType;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public $state: StateService,
        public dialogRef: MatDialogRef<FilterSearchEditDialogComponent>,
        public dialog: MatDialog,
        public colorService: ColorService,
        public authenticationService: AuthenticationService,
        private _filterSearchEditDialogService: FilterSearchEditDialogService,
        private _translateService: TranslateService,
        private _broadcastService: BroadcastService,
        private _configurationService: ConfigurationService,
        private _searchEngineAdvancedService: SearchEngineAdvancedService,
        private stayDetailService: StayDetailService,
    ) {
    }

     ngOnInit(): void {
        this._filterSearchService = this.data.service;
        this.useCase = this.data.useCase;
        this._isStayListUseCase = ['default', 'default;recurring', 'rehabilitation', 'default;recurring;rehabilitation'].includes(this.useCase);
        this._lastFilterSearchViewedStorageKey = this.useCase === 'patient' ? 'lastFilterSearchPatientViewed' : 'lastFilterSearchViewed';
        if (this.data.filterSearch) {
            this._filterSearch = _.cloneDeep(this.data.filterSearch);
            this.isSetValidityFrameActivated = false;
        }
        this._params = _.cloneDeep(this.data.params);

        const canCreateCommonFilterSearch = this.authenticationService.currentUserHasPermission('create-common-filter-search');
        this.canDisplayCommon = this._configurationService.getConfigurationContent('front', 'filterSearch.search.isCommonActivated') && canCreateCommonFilterSearch;

        if (this.$state.params?.fromFilterSearchList == 'true') {
            this.saveType = 'update';
            this._lastFilterSearchViewed = JSON.parse(sessionStorage.getItem(this._lastFilterSearchViewedStorageKey));
        }

        if (!this._filterSearch) {
            this.loadAllFiltersSearch();
        }
        this.initData();

        const sub = this.filterSearchCtrl
            .valueChanges
            .pipe(startWith(''), debounceTime(200))
            .subscribe(() => {
                this._filterOptions();
            });
        this._subscriptions.push(sub);

       this.handleDiagnoses();
       this.handleActs();
       this.handleDepedencys();
    }

    public handleDiagnoses() {
        const slugs = [...new Set(this._getDiagnosisMissingSlug())];
        this.filterSearchData.diagnoses = slugs.map((slug: string) => {
            return {
                slug,
                codificationLabel: this.setCodificationLabel(slug)
            };
        });
    }

    public handleActs() {
        const slugs = [...new Set(this._getActSlug())];
        this.filterSearchData.acts = slugs.map((slug: string) => {
            return {
                slug,
                codificationLabel: 'CCAM'
            };
        });
    }

    public handleDepedencys() {
        const elements: any[] = [];
        this.dependenciesType.forEach((dependencyType) => {
            elements.push(...this._getDependencyData(dependencyType));
        });
        this.filterSearchData.dependencies = [];
         elements.forEach(dependency => {
            dependency.args.values.forEach((value: any) => {
                this.filterSearchData.dependencies.push({ 'rating': value, 'codificationLabel': dependency.type.replace(/([A-Z])/g, '_$1').toUpperCase()});
            });
        });
    }
    private setCodificationLabel(slug: string): string {
        const diagnosticStorage = this._lastFilterSearchViewed?.linkDiagnostic?.find(diag => slug == diag.diagnostic.slug);
        return diagnosticStorage?.codificationLabel?.slug ?? 'DA';
    }

    private _getDiagnosisMissingSlug(): any {
        return this._searchEngineAdvancedService.getFilterSearchSlugsByConditionSlug(
            'diagnosisMissing', this.filterSearchData.params?.query?.args?.criteria
        );
    }

    private _getActSlug(): any {
        return this._searchEngineAdvancedService.getFilterSearchSlugsByConditionSlug(
            'actPresence', this.filterSearchData.params?.query?.args?.criteria, 'NAND'
        );
    }

    private _getDependencyData(dependencyType: string): any {
        return this._searchEngineAdvancedService.getFilterSearchSlugsByConditionType(
            dependencyType, this.filterSearchData.params?.query?.args?.criteria, 'NAND'
        );
    }

    /**
     * Init of the objects needed
     */
    initData(): void {
        if (this._filterSearch) {
            this.saveType = 'edition';
            this.filterSearchData = {...this._filterSearch};
            if (this.filterSearchData.isCommon) {
                this.filterSearchData.sharedStatus = 'isCommon';
            } else if (this.filterSearchData.isPublic) {
                this.filterSearchData.sharedStatus = 'isPublic';
            } else {
                this.filterSearchData.sharedStatus = 'isPrivate';
            }
        } else {
            this.filterSearchData = {
                name: '',
                isPublic: true,
                isCommon: false,
                sharedStatus: 'isPublic',
                status: true,
                params: this._params,
                codificationId: 1,
                dataSetId: 1,
                isAtypia: this._lastFilterSearchViewed?.type === 'healthStayAtypia',
                codify: true,
                validFrom: '',
                validTo: '',
                automatable: false,
                autoSupervised: false
            };
            // ici si on supprime le updateSharingStatus ou qu'il passe a private, cela fait crash tous les test de ce fichier
            this.updateSharingStatus('isPublic');
            if (this._lastFilterSearchViewed) {
                this.filterSearchToUpdateId = this._lastFilterSearchViewed.id;
                this.filterSearchData.name = this._lastFilterSearchViewed.name;
                this.filterSearchData.codify = this._lastFilterSearchViewed.codify;
                this.filterSearchData.automatable = this._lastFilterSearchViewed.automatable;
                this.filterSearchData.autoSupervised = this._lastFilterSearchViewed.autoSupervised;
                this._setFilterSearchDataSharedStatus(this._lastFilterSearchViewed);
                this.updateSharingStatus(this.filterSearchData.sharedStatus);
            }
            if (this._lastFilterSearchViewed?.tags) {
                if (typeof this._lastFilterSearchViewed.tags === 'object') {
                    !!this._lastFilterSearchViewed.tags.find(tag => tag.name === 'worklist') ? this.isWorkList = true : this.isWorkList = false;
                } else if (typeof this._lastFilterSearchViewed.tags === 'string') {
                    this._lastFilterSearchViewed.tags === 'worklist' ? this.isWorkList = true : this.isWorkList = false;
                }
            }
        }
    }

    onDateFormChange(data: any): void {
        if (data) {
            this.dateFormInvalidPropertyValue = data.dateFormInvalidPropertyValue;
            this.validityFrameStartDate = data.validityFrameStartDate;
            this.validityFrameEndDate = data.validityFrameEndDate;
        }
    }

    /**
     * Filter initialFiltersSearch according to value of input search inside
     * the select input search
     */
    private _filterOptions(): void {
        if (this.filtersSearch) {
            const searchValue = this.filterSearchCtrl.value;
            if (!searchValue) {
                this.filtersSearch = this._initialFiltersSearch;
            } else {
                this.filtersSearch = this._initialFiltersSearch.filter(filter =>
                    filter.name.toLowerCase().includes(searchValue.toLowerCase()));
            }
        }
    }

    private _setFilterSearchDataSharedStatus(filterSearch: FilterSearch): void {
        if (filterSearch.isCommon) {
            this.filterSearchData.sharedStatus = 'isCommon';
        } else if (filterSearch.isPublic) {
            this.filterSearchData.sharedStatus = 'isPublic';
        } else {
            this.filterSearchData.sharedStatus = 'isPrivate';
        }
    }

    selectFilterSearchToUpdate(): void {
        if (this.filterSearchToUpdateId) {
            this._filterSearchService
                .loadFilterSearch(this.filterSearchToUpdateId)
                .then((filterSearch) => {
                    this.filterSearchData.name = filterSearch.name;
                    this.filterSearchData.codify = filterSearch.codify;
                    this._setFilterSearchDataSharedStatus(filterSearch);
                    this.updateSharingStatus(this.filterSearchData.sharedStatus);
                });
        }
    }

    updateSharingStatus(event?: string): void {
        this.filterSearchData.sharedStatus = event;
        this.filterSearchData.isPublic = false;
        this.filterSearchData.isCommon = false;
        if (this.filterSearchData.sharedStatus === 'isPublic') {
            this.filterSearchData.isPublic = true;
        } else if (this.filterSearchData.sharedStatus === 'isCommon') {
            this.filterSearchData.isPublic = true;
            this.filterSearchData.isCommon = true;
        }
    }

    loadAllFiltersSearch(): void {
        this.isLoading = true;
        const params: any = {
            collection: 'shared,own',
            perPage: 1000
        };
        if (this._configurationService.getConfigurationContent('front', 'filterSearch.search.isCothActivated')) {
            params.collection += ',default';
        }
        if (this.canDisplayCommon) {
            params.collection += ',common';
        }
        if (this.authenticationService.hasRole('admin')) {
            params.collection += ',automatable';
        }
        params.collection += ',autoSupervised';
        this._filterSearchService
            .loadAllFiltersSearch(this.useCase, params)
            .then(res => {
                this.filtersSearch = res.data;
                this.filtersSearch.sort(this._filterSearchEditDialogService.sortFiltersSearch);
                this._initialFiltersSearch = _.cloneDeep(this.filtersSearch);
                this.isLoading = false;
            })
            .catch(() => this.isLoading = false);
    }

    canDisplayWarningPrivateFilterSearch(): boolean {
        return this.filterSearchData.sharedStatus === 'isPrivate' &&
            (this.saveType === 'creation' ||
                (!this.filterSearchData.codify && (this.saveType === 'update' || this.saveType === 'edition')));
    }

    /**
     * Disable or not the validate button depending on saveType
     * and name or filterSearchToUpdate
     */
    isValidationDisabled(): boolean {
        if (this.saveType === 'update') {
            return !this.filterSearchToUpdateId || (this.setValidityFrame && this.dateFormInvalidPropertyValue);
        } else if (this.saveType === 'creation') {
            return !this.filterSearchData.name || (this.setValidityFrame && this.dateFormInvalidPropertyValue);
        }
        return false;
    }

    onEnterKeyPressed(): void {
        if (this.submitButton &&
            this.submitButton.nativeElement) {
            this.submitButton.nativeElement.click();
        }
    }

    /**
     * Call update() or create() depending on saveType
     */
    validate(): void {
        this._setValidityFrameFilterSearchData();
        if (this._isStayListUseCase) {
            this.filterSearchData.params.isStayRehabilitation = this.useCase === 'stayRehabilitation';
        }
        if (this.saveType === 'update') {
            this.update(false, this.filterSearchToUpdateId);
        } else if (this.saveType === 'creation') {
            let sameNameFound = false;
            this.filtersSearch.forEach(filterSearch => {
                if (filterSearch.name === this.filterSearchData.name) {
                    sameNameFound = true;
                }
            });

            // If a filter search as the same name then open confirm dialog else create
            if (sameNameFound) {
                const title = this._translateService.instant('FILTER_SEARCH.EDIT_DIALOG.CONFIRM_CREATION');
                const confirmDialogRef: MatDialogRef<ConfirmDialogComponent> =
                    this.dialog.open(ConfirmDialogComponent, {
                        data: {
                            title
                        }
                    });

                confirmDialogRef
                    .afterClosed()
                    .subscribe(res => {
                        // res = true => confirmed to have two filters search with the same name
                        if (res) {
                            this._create();
                        }
                    });
            } else {
                this._create();
            }
        }
    }

    private _setValidityFrameFilterSearchData(): void {
        if (this.setValidityFrame &&
            this.validityFrameStartDate &&
            this.validityFrameEndDate) {
            this.filterSearchData.validFrom = DateHelperService.toMysql(this.validityFrameStartDate);
            this.filterSearchData.validTo = DateHelperService.toMysql(this.validityFrameEndDate);
        }
    }

    private _updateLastFilterSearchViewedInStorage(newFilterSearch: FilterSearch): void {
        if (newFilterSearch) {
            sessionStorage.setItem(this._lastFilterSearchViewedStorageKey, JSON.stringify({
                id: newFilterSearch.id,
                name: newFilterSearch.name,
                params: newFilterSearch.params,
                codify: newFilterSearch.codify,
                isPublic: newFilterSearch.isPublic,
                isCommon: newFilterSearch.isCommon,
                tags: newFilterSearch.params.tags,
                automatable: newFilterSearch.automatable,
                autoSupervised: newFilterSearch.autoSupervised,
                linkDiagnostic: newFilterSearch.linkDiagnostic
            }));
        }
    }

     setFilterSearchType() {
        if (this.filterSearchData.isAtypia) {
            this.filterSearchData.type = 'healthStayAtypia';
        } else if (this.useCase === 'patient') {
            this.filterSearchData.type = 'healthPatient';
        } else if (this._isStayListUseCase) {
            this.filterSearchData.type = 'healthStay';
        } else {
            this.filterSearchData.type = 'healthStayExternal';
        }
    }

    private async _create(): Promise<void> {
        try {
            this.resetDiagMissingRevaluationValue();

            this.isLoading = true;
            if (this.isWorkList) {
                this.filterSearchData.params.tags = 'worklist';
            }

            this.setFilterSearchType();

            const newFilterSearch = await this._filterSearchService
                .createFilterSearch(this.filterSearchData);

            if (newFilterSearch && this.isWorkList) {
                this.assignUserWorklist(newFilterSearch.id);
            }
            this.isLoading = false;
            // We update lastFilterSearchViewed only if it's we are from filterSearchList
            if (this._lastFilterSearchViewed &&
                newFilterSearch) {
                this._updateLastFilterSearchViewedInStorage(newFilterSearch);
            }

            this._broadcastService.send('filterSearchEditDialog::update', {filterSearch: newFilterSearch});
            this.dialogRef.close({action: 'creation'});
        } catch (e) {
            this.isLoading = false;
            throw e;
        }
    }

    /**
     * Set the revaluation value to "false" to avoid the diagnosticMissingSlug
     * to be added in the query.
     *
     * @private
     */
    private resetDiagMissingRevaluationValue(): void {
        const diagMissing = this.stayDetailService?.getDiagnosisMissingSlug(this.filterSearchData?.params?.query);
        if (diagMissing) {
            diagMissing.args.revaluation = false;
        }
    }

    async updateWorklist(id?: number): Promise<void> {
        if (this.isWorkList && id) {
            if (this.removeUser?.length > 0) {
                await this.removeUser.forEach((element) => {
                    this._filterSearchService.deleteWorklistUser(id, element);
                });
            }
            if (this.userList?.length > 0) {
                await this._filterSearchService
                    .setWorklistUser(id, this.userList);
            }
        } else if (!this.isWorkList && id) {
                    // empty the worklist for this filtersearch when not tagged as worklist anymore
                    const params = {
                        include: ['workListUser']
                    };
                    const data = await this._filterSearchService.loadFilterSearch(id, params);
                    data.workListUser.forEach((element) => {
                        this._filterSearchService.deleteWorklistUser(id, element.id);
                    });
        }
    }

    setCodifyValue() {
        this.updateSharingStatus(this.filterSearchData.sharedStatus);
        // codify is false for private filtersSearch
        this.filterSearchData.codify = !!this.filterSearchData.isPublic;
    }
    async update(isEdition: boolean, id?: number): Promise<boolean> {
        try {
            // If we don't have an id then it's an edition
            id = id || this.filterSearchData.id;
            this.isLoading = true;
            delete this.filterSearchData.paramsToDisplay;
            delete this.filterSearchData.total;
            delete this.filterSearchData.isTotalLoading;
            // If the filterSearch is update then the name is updated at the init and
            // we don't change the filterSearch to update therefore we need to update the name
            if (this._lastFilterSearchViewed &&
                this._lastFilterSearchViewed.id === this.filterSearchToUpdateId) {
                this.filterSearchData.name = this._lastFilterSearchViewed.name;
            }
            if (this.isWorkList) {
                this.filterSearchData.params.tags = 'worklist';
            }
            this.setFilterSearchType();
            const newFilterSearch = await this._filterSearchService
                .updateFilterSearch(id, this.filterSearchData);
           if (this.isWorkList && newFilterSearch) {
               this.assignUserWorklist(newFilterSearch.id);
           }
            this.isLoading = false;
            if (newFilterSearch) {
                this._updateLastFilterSearchViewedInStorage(newFilterSearch);
            }

            this._broadcastService.send('filterSearchEditDialog::update', {filterSearch: newFilterSearch});
            this.dialogRef.close({action: isEdition ? 'edition' : 'update'});
            return true;
        } catch (e) {
            this.isLoading = false;
            throw e;
        }
    }

    assignUserWorklist(FiltersearchId?: number): void {
        if (FiltersearchId) {
            const dialogRef: MatDialogRef<WorklistUserElementComponent> =
                this.dialog.open(WorklistUserElementComponent, {
                    data : {
                        filterSearchId: this.filterSearchToUpdateId,
                        autoFocus: false
                    }});
            dialogRef
                .afterClosed().subscribe(async res => {
                this.userList = res?.userList;
                this.removeUser = res?.removeUserList;
                await this.updateWorklist(FiltersearchId);
            });
        }
    }
    ngOnDestroy(): void {
        if (this._subscriptions &&
            this._subscriptions.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }
}
