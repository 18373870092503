import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ct-search-engine-condition-prediction-source',
  templateUrl: './search-engine-condition-prediction-source.component.html',
  styleUrls: ['./search-engine-condition-prediction-source.component.scss']
})
export class SearchEngineConditionPredictionSourceComponent implements OnInit {
  @Input() condition: any;

  sources = ['rule', 'ml', 'atypical'];
  constructor() { }

  ngOnInit(): void {
  }

}
