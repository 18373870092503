import {Component, Input, OnInit} from '@angular/core';
import {StateService} from '@uirouter/core';

@Component({
    selector: 'ct-stay-detail-dependency-prediction-list',
    templateUrl: './stay-detail-dependency-prediction-list.component.html',
    styleUrls: ['./stay-detail-dependency-prediction-list.component.scss']
})
export class StayDetailDependencyPredictionListComponent implements OnInit {

    @Input() filtersSearch: any;
    @Input() dataSetElement: any;
    @Input() codificationLabels: any;
    constructor(public $state: StateService) {
    }

    ngOnInit(): void {}
}
