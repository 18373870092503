<div class="dialog-container">
    <mat-toolbar class="dialog-toolbar mat-elevation-z5">
        <h2 class="title">{{'TOOLBAR.STAY_DETAIL' | translate}}</h2>
        <button mat-icon-button
                mat-dialog-close>
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </mat-toolbar>

    <div class="dialog-content"
         (scroll)="onDialogScroll($event)">
        <ct-stay-detail></ct-stay-detail>
    </div>
</div>
