<mat-form-field class="width-190">
    <mat-select #multipleSelect
                multiple
                [placeholder]="'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.DEPENDENCY_LEVEL' | translate"
                [(ngModel)]="condition?.service?.params[paramKey]"
                [disabled]="data?.length === 0">
        <mat-option *ngFor="let el of data"
                    [value]="el.id">
            {{ el.level + " "}} {{el.text | translate}}
        </mat-option>
    </mat-select>
</mat-form-field>


<mat-checkbox
              [(ngModel)]="condition?.service?.params.isValidated">
  <span ngbTooltip="{{ 'STAY.STATUS_VALID' | translate }}"
        tooltipClass="ngb-tooltip">
    {{'STAY.STATUS_VALID' | translate}}
  </span>
</mat-checkbox>
