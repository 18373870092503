import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpService} from '../../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class ActApiService {
    private _BASE_URL = 'act';

    constructor(private _http: HttpService) { }

    getAllWithES(urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/search`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }
    getActJustification(actId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${actId}/act-justification`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }
}
