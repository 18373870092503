import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionDependencyComponent} from './search-engine-condition-dependency.component';
import {Injectable} from '@angular/core';

@Injectable()
export class SearchEngineConditionDependencyService extends SearchEngineCondition {
    // Because of shared component
    paramKey: string;
    operator: string;
    placeholder: string;
    paramType = 'dependency';

    data: {id: number, text: string, level: number}[] = [
        {id: 1, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.1', level: 1},
        {id: 2, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.2', level: 2},
        {id: 3, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.3', level: 3},
        {id: 4, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.4', level: 4}
    ];

   // @ts-ignore
    constructor(private _translateService: TranslateService, private _operator: string, private _paramKey: string, private _tradText) {
        const field = _translateService.instant(
                `DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.${_tradText}`
        );
        super([_paramKey], field, _paramKey, SearchEngineConditionDependencyComponent, true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.operator = this._operator;
        this.paramKey = this._paramKey;
        this.params = {[this.paramKey]: [], isValidated: false, operator: this.operator};
        this.uriParams = {[this.paramKey]: '', isValidated: false, operator: this.operator};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        if (this.params[this.paramKey]) {
            this.uriParams[this.paramKey] = this.params[this.paramKey].join(';');
        }
    }

    convertToParams(): void {
        if (this.uriParams[this.paramKey]) {
            this.params[this.paramKey] = this.uriParams[this.paramKey].split(';')?.map(id => Number.parseFloat(id));
        }
    }
    convertToParamsFromApiParams(): void {
        if (this._apiParams.args.operator === 'NAND') {
            const translatedString = this._tradText.startsWith('MISSING_') ? this._tradText : 'MISSING_' + this._tradText;
            this._displayName = this._translateService.instant(`DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.${translatedString}`);
            this.params = {
                type: this._apiParams.args.type + 'Missing',
                operator: 'NAND',
                isValidated: this._apiParams.args.isValidated,
                [this.paramKey]: this._apiParams.args.values
            };
        } else {
            this.params = {
                type: this._apiParams.args.type,
                operator: 'AND',
                isValidated: this._apiParams.args.isValidated,
                [this.paramKey]: this._apiParams.args.values
            };
        }
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        if (this.paramKey && this.paramKey.includes('Missing')) {
            this.params.type = this.paramType;
            this.params.operator = 'NAND';
        } else {
            this.params.type = this.paramType;
        }
        this.apiParams.type = this.params.type ? this.params.type : this.paramKey;
        this.apiParams.args = {
            values: this.params[this.paramKey],
            operator: this.params.operator,
            isValidated: this.params.isValidated,
            type: this.paramKey.replace('Missing', '')
        };
    }

    isValid(fields?: any): boolean {
        return !!(fields && !this.isUndefined(fields[this.paramKey]) &&
            !this.isEmpty(fields[this.paramKey]) && this.params[this.paramKey].length > 0);
    }
}
