import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {StateService} from '@uirouter/core';

@Component({
    selector: 'ct-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    validationErrors = {
        email: [
            {
                type: 'required',
                message: this.translateService.instant('ERROR.REQUIRED_FIELD')
            },
            {
                type: 'email',
                message: this.translateService.instant('ERROR.WRONG_EMAIL')
            }
        ]
    };

    loading = false;
    error = false;
    message = '';
    isSuccessMessage = false;
    forgotPasswordForm: FormGroup;
    email: string;

    emailInputFocused = false;

    constructor(public $state: StateService,
                public formBuilder: FormBuilder,
                public translateService: TranslateService,
                private _authenticationService: AuthenticationService) {}

    async ngOnInit() {
        this._initForm();
    }

    get formControls() {
        return this.forgotPasswordForm.controls;
    }

    private _initForm() {
        this.forgotPasswordForm = this.formBuilder
            .group({
                email: [null, [
                    Validators.required,
                    Validators.email,
                    (control: AbstractControl) => {
                        if (!control.value) {
                            return null;
                        }
                        this.email = control.value;
                    }]
                ]
            });
    }

    async forgotPassword() {
        this.message = '';
        try {
            this.loading = true;
            const response = await this._authenticationService.forgotPassword(this.email);
            this.loading = false;

            if (response?.status === 200) {
                this.message = this.translateService.instant('AUTHENTICATION.RESET_PASSWORD_MESSAGE');
                this.isSuccessMessage = true;
            } else {
                this.message = this.translateService.instant('AUTHENTICATION.RESET_PASSWORD_ERROR_MESSAGE');
                this.isSuccessMessage = false;
            }
        } catch (error) {
            this.loading = false;
            this.message = this.translateService.instant('AUTHENTICATION.RESET_PASSWORD_ERROR_MESSAGE');
        this.isSuccessMessage = false;
        }
    }


    goBackToLogin() {
        this.$state.go('authentication.login');
    }

}
