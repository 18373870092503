<div class="parent-element">
    <div class="text-color element-container"
         ctTooltip
         [config]="config"
         [templateComponent]="tooltipComponent"
         [right]="'-5px'"
    >
        <div class="block margin-right-10 space-y-1">
            <b *ngIf="predictiveDependency?.rating === 1"
                class="rounded inline-block px-2 py-1 text-xs text-bold bg-green-100 text-green-800">
                 {{ 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.' + predictiveDependency?.rating | translate }}
            </b>
            <b *ngIf="predictiveDependency?.rating === 2"
                  class="rounded inline-block px-2 py-1 text-xs text-bold bg-blue-100 text-blue-800">
                 {{ 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.' + predictiveDependency?.rating | translate }}
            </b>
            <b *ngIf="predictiveDependency?.rating === 3"
                class="rounded inline-block px-2 py-1 text-xs text-bold bg-amber-100 text-amber-700">
                 {{ 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.' + predictiveDependency?.rating | translate }}
            </b>
            <b *ngIf="predictiveDependency?.rating === 4"
                class="rounded inline-block px-2 py-1 text-xs text-bold bg-red-100 text-red-700">
                 {{ 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.' + predictiveDependency?.rating | translate }}
            </b>

            <div class="diagnostic-slug margin-right-5 text-sm font-semibold">
                {{ ('DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.' + setCodificationLabelTrad()) | translate }}
            </div>
        </div>
    </div>
</div>
