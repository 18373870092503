<ng-container *ngIf="dataSetElement">
    <div class="margin-bottom-20">
        <ct-tab-list [(activeTabId)]="activeTabId"
                     [broadcastMessage]="'stayDetailFilterSearch::clickOnTagTab'">
            <ng-container *ngFor="let tab of tagsTabs">
                <ct-tab-list-item
                    *ngIf="tab.display"
                    [label]="tab.label"
                    [id]="tab.id"
                    [canDisplayBadge]="true"
                    [badgeValue]="tab.numberOfFiltersSearch"
                ></ct-tab-list-item>
            </ng-container>
        </ct-tab-list>
       <ct-tab-list [(activeTabId)]="activeFilterTabId"
        [broadcastMessage]="'stayDetailFilterSearch::clickOnFilterSearchTagTab'">
        <ng-container *ngFor="let tab of filterSearchTagTabs">
            <ct-tab-list-item *ngIf="tab.display"
                              [label]="tab.label"
                              [id]="tab.id"
                              [canDisplayBadge]="true"
                              [badgeValue]="tab.numberOfFiltersSearch"></ct-tab-list-item>
        </ng-container>
        </ct-tab-list>
    </div>

    <ng-container *ngIf="!isLoading">
        <div id="chip-list-container"
             *ngIf="filtersSearchToDisplay?.length > 0">
            <mat-chip-list>
                <!--Filters search with DA revaluation-->
                <mat-chip *ngFor="let filter of filterFiltersSearch('revaluation'); trackBy: trackByFn"
                          class="{{getChipColor(filter)}}"
                          [value]="filter"
                          [selected]="isFilterSearchSelected(filter)"
                          [ngbTooltip]="chipTooltipRevaluation"
                          placement="top"
                          (click)="search(filter)">
                    <div class="euro-icon">
                        <mat-icon>euro_symbol</mat-icon>
                    </div>

                    <div class="filter-name">{{filter.name}}</div>

                    <mat-icon class="private-icon"
                              *ngIf="!filter.isPublic">person</mat-icon>

                    <ng-template #chipTooltipRevaluation>
                        <span [innerHTML]="getTooltip(filter, true, 'revaluation') | safeHtml"></span>
                    </ng-template>
                    <div class="filter-search-menu">
                        <ct-stay-detail-filter-search-menu [filterSearchStatus]="activeFilterTabId" [filterSearch]="filter" [dataSetElement]="dataSetElement"></ct-stay-detail-filter-search-menu>
                    </div>
                </mat-chip>

                <!--Default ones-->
                <mat-chip *ngFor="let filter of filterFiltersSearch(); trackBy: trackByFn"
                          [ngClass]="{ 'light-grey': isActFilterSearch(filter), 'grey': !isActFilterSearch(filter)}"
                          [value]="filter"
                          [selected]="isFilterSearchSelected(filter)"
                          [ngbTooltip]="chipTooltipDefault"
                          placement="top"
                          (click)="search(filter)">
                    <div class="">
                        <div class="filter-name">{{filter.name}}</div>

                        <mat-icon class="private-icon"
                                  *ngIf="!filter.isPublic">person</mat-icon>

                        <ng-template #chipTooltipDefault>
                            <span [innerHTML]="getTooltip(filter, true) | safeHtml"></span>
                        </ng-template>
                    </div>
                    <div class="filter-search-menu">
                        <ct-stay-detail-filter-search-menu [filterSearchStatus]="activeFilterTabId" [filterSearch]="filter" [dataSetElement]="dataSetElement"></ct-stay-detail-filter-search-menu>
                    </div>
                </mat-chip>

                <!--Filters search with coded DA-->
                <ng-container *ngFor="let filter of filterFiltersSearch('codedDiagnostics'); trackBy: trackByFn">
                    <ng-container [ngTemplateOutlet]="filtersSearchCoded"
                                  [ngTemplateOutletContext]="{$implicit: filter, isDiagnostic: true}"></ng-container>
                </ng-container>

                <!--Filters search with coded acts-->
                <ng-container *ngFor="let filter of filterFiltersSearch('codedActs'); trackBy: trackByFn">
                    <ng-container
                        [ngTemplateOutlet]="filtersSearchCoded"
                        [ngTemplateOutletContext]="{$implicit: filter, isDiagnostic: false}"
                    ></ng-container>
                </ng-container>

                <ng-template
                    #filtersSearchCoded
                    let-filter
                    let-isDiagnostic="isDiagnostic"
                >
                    <mat-chip
                        [ngClass]="{'color-green': isDiagnostic, 'color-light-green': !isDiagnostic, 'no-margin-right': isFilterSearchSelected(filter)}"
                        [value]="filter"
                        [selected]="isFilterSearchSelected(filter)"
                        [ngbTooltip]="chipTooltipCoded"
                        placement="top"
                        (click)="search(filter)"
                    >
                        <div class="done-icon">
                            <mat-icon>done</mat-icon>
                        </div>

                        <div class="filter-name">{{filter.name}}</div>

                        <mat-icon class="private-icon" *ngIf="!filter.isPublic">person</mat-icon>

                        <ng-template #chipTooltipCoded>
                            <span [innerHTML]="getTooltip(filter, isDiagnostic, 'coded') | safeHtml"></span>
                        </ng-template>
                        <div class="filter-search-menu">
                            <ct-stay-detail-filter-search-menu [filterSearch]="filter" [filterSearchStatus]="activeFilterTabId" [dataSetElement]="dataSetElement"></ct-stay-detail-filter-search-menu>
                        </div>
                    </mat-chip>
                </ng-template>
            </mat-chip-list>
        </div>

        <div id="no-data-message"
             class="text-disabled text-center margin-bottom-10"
             *ngIf="!filtersSearchToDisplay || filtersSearchToDisplay.length === 0">
            <span>{{'DATA_SET.CODIFICATION_ELEMENT.NO_FILTER_SEARCH' | translate}}</span>
        </div>

        <mat-divider *ngIf="hasDiagnosisMissingSlugFilter(this._currentFilterSearch) || hasActMissingSlug"></mat-divider>
        <ct-stay-detail-diagnostic-prediction-list
            [dataSetElement]="dataSetElement"
            [codificationLabels]="codificationLabels"
            [filtersSearch]="_currentFilterSearch"
            [codificationLabelIds]="[-1, this.codificationLabels.DA, this.codificationLabels.DA_ROOT]"
            [diagnostics]="diagnostics"
            [predictiveDiagnostics]="predictiveDiagnostics"
            [orderBy]="['diagnostic.slug']"
            [canDisplayPredictionListMenu]="false"
            [canDisplayTooltip]="true"
            [displayOnlySimplifiedScore]="false"
            [isTypeRehabilitation]="isTypeRehabilitation"
            [predictionListMaxHeight]="'200px'"></ct-stay-detail-diagnostic-prediction-list>

        <mat-divider *ngIf="hasDiagnosisMissingSlugFilter(this._currentFilterSearch) && hasActMissingSlug"></mat-divider>

        <ct-stay-detail-filter-search-act-list
            [dataSetElement]="dataSetElement"
            [acts]="currentFilterSearchActs"
            [isLoading]="isGetActsLoading"></ct-stay-detail-filter-search-act-list>
        <mat-divider *ngIf="hasDependencyMissingSlugFilter(this._currentFilterSearch)"></mat-divider>

        <ct-stay-detail-dependency-prediction-list
            *ngIf="hasDependencyMissingSlugFilter(_currentFilterSearch)"
            [dataSetElement]="dataSetElement"
            [filtersSearch]="_currentFilterSearch"
            [codificationLabels]="codificationLabels"></ct-stay-detail-dependency-prediction-list>

    </ng-container>

    <div class="align-center-center margin-top-10">
        <mat-progress-spinner
            mode="indeterminate"
            *ngIf="isLoading"
            [diameter]="70"></mat-progress-spinner>
    </div>
</ng-container>
