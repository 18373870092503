import {Injectable} from '@angular/core';
import {SearchEngineCondition} from '../search-engine.condition';
import {TranslateService} from '@ngx-translate/core';

type TypeDatasetElementCount = typeof SearchEngineConditionDatasetElementCountService.SORT_DATASET_ELEMENT_COUNT_DESC
    | typeof SearchEngineConditionDatasetElementCountService.SORT_DATASET_ELEMENT_COUNT_ASC
    | typeof SearchEngineConditionDatasetElementCountService.SORT_DATASET_ELEMENT_COUNT_NONE;

@Injectable({
    providedIn: 'root'
})
export class SearchEngineConditionDatasetElementCountService extends SearchEngineCondition {

    public static readonly SORT_DATASET_ELEMENT_COUNT_DESC = '-datasetElementCount';
    public static readonly SORT_DATASET_ELEMENT_COUNT_ASC = 'datasetElementCount';
    public static readonly SORT_DATASET_ELEMENT_COUNT_NONE = null;

    public datasetElementCount: string;

    constructor(private _translateService: TranslateService) {
        super(['datasetElementCount'],
            _translateService.instant('STAY.STAYS'),
            'datasetElementCount',
            null,
            false
        );

        this.init();
    }

    public init(): void {
        this.setDatasetElementCount(null);
    }

    public setDatasetElementCount(count?: any): void {
        this.params = {datasetElementCount: count};
        this.uriParams = this.params;
    }

    public getUriParams(): { datasetElementCount: any } {
        return this.uriParams;
    }
}
