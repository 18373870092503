import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DataSetElement} from '../../shared/data-set-element.model';
import {StayDetailCodificationDiagnosticAddDialogComponent} from './diagnostic/add-dialog/stay-detail-codification-diagnostic-add-dialog.component';
import {StayDetailCodificationActAddDialogComponent} from './act/add-dialog/stay-detail-codification-act-add-dialog.component';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {SnackBarService} from '../../../../core/services/snack-bar.service';
import {TranslateService} from '@ngx-translate/core';
import {StayDetailCodificationDiagnosticService} from './diagnostic/stay-detail-codification-diagnostic.service';
import {StayDetailDataSetElementDatesEditDialogComponent} from '../shared/stay-detail-data-set-element-dates-edit-dialog/stay-detail-data-set-element-dates-edit-dialog.component';
import {DataSetElementService} from '../../shared/data-set-element.service';
import {ConfirmDialogComponent} from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import {LayoutService} from '../../../layout/layout.service';
import {CodificationLabelsModel} from '../../shared/codification-labels.model';

@Injectable({
    providedIn: 'root'
})
export class StayDetailCodificationService {
    constructor(private _matDialog: MatDialog,
                private _broadcastService: BroadcastService,
                private _snackBarService: SnackBarService,
                private _translateService: TranslateService,
                private _layoutService: LayoutService,
                private _dataSetElementService: DataSetElementService,
                private _stayDetailCodificationDiagnosticService: StayDetailCodificationDiagnosticService) {}

    openDiagnosticAddDialog(dataSetElement: DataSetElement,
                            dataSetElementDiagnostics: any[],
                            predictiveDiagnostics: any[],
                            isSSRUseCase: boolean,
                            codificationLabels: CodificationLabelsModel): void {
        const dialogRef: MatDialogRef<StayDetailCodificationDiagnosticAddDialogComponent> = this._matDialog.open(StayDetailCodificationDiagnosticAddDialogComponent, {
            data: {
                codificationLabels: codificationLabels,
                service: this._stayDetailCodificationDiagnosticService,
                dataSetElement,
                dataSetElementDiagnostics,
                predictiveDiagnostics
            },
            autoFocus: true,
            panelClass: 'diagnostic-add-dialog'
        });

        dialogRef
            .afterClosed()
            .subscribe(res => {
                if (res &&
                    res.openAddActDialog) {
                    this.openActAddDialog(dataSetElement, isSSRUseCase, codificationLabels);
                }
            });
    }

    openActAddDialog(dataSetElement: DataSetElement, isSSRUseCase: boolean, codificationLabels: CodificationLabelsModel): void {
        const dialogRef: MatDialogRef<StayDetailCodificationActAddDialogComponent> =
            this._matDialog.open(StayDetailCodificationActAddDialogComponent, {
                data: {
                    dataSetElement,
                    isSSRUseCase
                },
                autoFocus: true,
                panelClass: 'act-add-dialog'
            });

        dialogRef
            .afterClosed()
            .subscribe(res => {
                if (res) {
                    if (res.openAddDiagnosticDialog) {
                        this.openDiagnosticAddDialog(dataSetElement, dataSetElement.dataSetElementDiagnostic, dataSetElement.dataSetElementDiagnosticPredictive, isSSRUseCase, codificationLabels);
                    }
                } else {
                    this._broadcastService.send('act::resetLinkActs');
                }
            });
    }

    openDataSetElementDatesEditDialog(dataSetElement: DataSetElement) {
        const dialogRef: MatDialogRef<StayDetailDataSetElementDatesEditDialogComponent> =
            this._matDialog.open(StayDetailDataSetElementDatesEditDialogComponent, {
                data: {
                    dataSetElement
                },
                autoFocus: false,
                panelClass: 'data-set-element-dates-edit-dialog'
            });

        dialogRef
            .afterClosed()
            .subscribe(res => {
                if (res) {
                    // To update local dataSetElement dates
                    dataSetElement.dataSetContent.startDate = res.startDate;
                    dataSetElement.dataSetContent.endDate = res.endDate;
                    // To update parentDataSetElement
                    this._broadcastService.send('stayDetail::editDataSetElementDates');
                }
            });
    }

    openConfirmDialog(dataSetElement: DataSetElement) {
        const confirmDialogRef: MatDialogRef<ConfirmDialogComponent> =
            this._matDialog.open(ConfirmDialogComponent, {
                data: {
                    title: this._translateService.instant('DATA_SET.CODIFICATION_ELEMENT.CONFIRM_DUPLICATE_FIRST_SSR_WEEK_CODIFICATION')
                },
                autoFocus: false
            });

        confirmDialogRef
            .afterClosed()
            .subscribe(res => {
                if (res) {
                    this._duplicateFirstSSRWeekCodification(dataSetElement);
                }
            });
    }

    private _getAddDiagnosticPromises(dataSetElement: DataSetElement, firstSSRWeekCodification: any): Promise<any>[] {
        const promises: Promise<any>[] = [];
        const diagnostics = firstSSRWeekCodification ? JSON.parse(firstSSRWeekCodification).diagnostics : [];
        if (diagnostics) {
            diagnostics.forEach(diagnostic => {
                if (diagnostic) {
                    const data = {
                        diagnostic: diagnostic.diagnostic,
                        codificationLabelId: diagnostic.codificationLabelId,
                        codificationLabelSlug: diagnostic.codificationLabel.slug,
                        isNearAutomation: false
                    };
                    promises.push(this._stayDetailCodificationDiagnosticService.updateDiagnosticCodification(dataSetElement, 1, data, false));
                }
            });
        }
        return promises;
    }

    private _getActIdsPerType(type: string, linkActs: any[]): any[] {
        if (linkActs) {
            return linkActs.reduce((accumulator, currentValue) => {
                if (currentValue.act?.type === type) {
                    accumulator.push([currentValue.act.id, currentValue.execution_date]);
                }
                return accumulator;
            }, []);
        }
        return [];
    }

    private _getAddActsPromises(dataSetElementId: number, firstSSRWeekCodification: any): Promise<any>[] {
        const promises: Promise<any>[] = [];
        const linkActs = firstSSRWeekCodification ? JSON.parse(firstSSRWeekCodification).linkActs : null;
        if (linkActs) {
            const actPerType = {
                ccam: this._getActIdsPerType('ccam', linkActs),
                csarr: this._getActIdsPerType('csarr', linkActs)
            };
            if (actPerType.ccam?.length) {
                promises.push(this._dataSetElementService.addActs(dataSetElementId, {data: [actPerType], type: 'ccam' }, false));
            }
            if (actPerType.csarr?.length) {
                promises.push(this._dataSetElementService.addActs(dataSetElementId, {data: actPerType.csarr, type: 'csarr'}, false));
            }
        }
        return promises;
    }

    private async _duplicateFirstSSRWeekCodification(dataSetElement: DataSetElement) {
        try {
            if (dataSetElement) {
                this._layoutService.startLoading();
                const firstSSRWeekCodification = sessionStorage.getItem('firstSSRWeekCodification');
                const diagnosticPromises = this._getAddDiagnosticPromises(dataSetElement, firstSSRWeekCodification) || [];
                const actPromises = this._getAddActsPromises(dataSetElement.id, firstSSRWeekCodification) || [];
                // Two Promise.all() because we need to handle actPromises result separately, and it can contain 0, 1 or 2 array
                const newActsResult = await Promise.all([...actPromises]);
                await Promise.all([...diagnosticPromises]);
                const newActs = [].concat(...newActsResult);
                // To launch grouping update
                this._broadcastService.send('diagnostic::addDiagnostic', {
                    dataSetElementId: dataSetElement.id,
                    success: true,
                    reload: true,
                });
                // To update diagnostics in codification box
                this._broadcastService.send('diagnostic::refreshDisplay');
                if (newActs?.length) {
                    // To update acts in codification box
                    this._broadcastService.send('act::addActs', {acts: newActs});
                }
                this._snackBarService.success(this._translateService.instant('SUCCESS.DUPLICATE_FIRST_SSR_WEEK_CODIFICATION'));
                this._layoutService.stopLoading();
            }
        } catch (e) {
            this._layoutService.stopLoading();
            console.error(e);
            throw e;
        }
    }

    getDependencyTranslationKeyFromId(value: number): string {
        const data: {id: number, text: string}[] = [
            {id: 1, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.1'},
            {id: 2, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.2'},
            {id: 3, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.3'},
            {id: 4, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.4'}
        ];
        const key = data.find(el => el.id === value)?.text;
        return key ?? '';
    }
}
