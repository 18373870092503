<ng-container *ngIf="qualifactControls?.length">
    <div id="qualifact-id" class="container-qualifact">
        <h3 [matTooltip]="'STUFF.QUALIFACT.QUALIFACT_CONTROL' | translate">{{'STUFF.QUALIFACT.QUALIFACT_CONTROL' | translate}}</h3>
        <h4 *ngIf="targetControls?.length" class="label">{{'STUFF.QUALIFACT.RULE_ACTIVE' | translate}}</h4>
        <ng-container *ngFor="let qualifactControl of qualifactControls; index as i">
            <ct-stay-detail-element-qualifact-control
                *ngIf="targetControls?.includes(i)"
                [qualifactControl]="qualifactControl"
                [isActived]="true"
            ></ct-stay-detail-element-qualifact-control>
        </ng-container>
        <h4 *ngIf="targetControls?.length" class="label">{{'STUFF.QUALIFACT.OTHERS' | translate}}</h4>
        <ng-container *ngFor="let qualifactControl of qualifactControls; index as i">
            <ct-stay-detail-element-qualifact-control
                *ngIf="!targetControls?.includes(i)"
                [qualifactControl]="qualifactControl"
                [isActived]="false"
            ></ct-stay-detail-element-qualifact-control>
        </ng-container>
    </div>
</ng-container>

