const protocol = location.protocol;
const slashes = protocol.concat('//');
const host = slashes.concat(window.location.hostname);

export const environment = {
    production: true,
    apiUrl: host + ':' + window.location.port,
    features: {
        codificationHistory: true
    },
    configurations: [
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 50,
            name: 'documentTypeOccurenceThreshold'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 'fr',
            name: 'language'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 'public',
            name: 'clientType'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 'dev',
            name: 'clientName'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'advancedSearch.isPerformanceStatsActivated'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'advancedSearch.isStructuredDataActivated'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 'all',
            name: 'advancedSearch.stayStatusDefaultValue'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 'all',
            name: 'advancedSearch.isBilled.stayListDefaultValue'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 'false',
            name: 'advancedSearch.isBilled.qualityControlDefaultValue'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: '5;10;15;20;30;40;50',
            name: 'advancedSearch.valuation.confidenceSliderTicksDA'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: '50;70;80;90;95;97;99',
            name: 'advancedSearch.valuation.confidenceSliderTicksDP'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: '50;70;80;90;95;97;99',
            name: 'advancedSearch.valuation.confidenceSliderTicksMP'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: '50;70;80;90;95;97;99',
            name: 'advancedSearch.valuation.confidenceSliderTicksAE'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 'endDate',
            name: 'advancedSearch.stayResultsDateSortType'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'diagnostic.diagnosticAct'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'diagnostic.isAutoEncodingActive'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'diagnostic.prediction.isMixedDisplayActivated'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'diagnostic.prediction.primaryDiagnostic.display'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 6,
            name: 'diagnostic.prediction.primaryDiagnostic.limitMin'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.7,
            name: 'diagnostic.prediction.primaryDiagnostic.exactCodeConfidenceThreshold'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.7,
            name: 'diagnostic.prediction.primaryDiagnostic.rootCodeConfidenceThreshold'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 10,
            name: 'diagnostic.prediction.primaryDiagnostic.limitMax'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.001,
            name: 'diagnostic.prediction.primaryDiagnostic.confidenceMin'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.05,
            name: 'diagnostic.prediction.primaryDiagnostic.confidenceDefined'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 'diagnostic',
            name: 'diagnostic.prediction.primaryDiagnostic.typeDisplay'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'diagnostic.prediction.associateDiagnostic.display'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.6,
            name: 'diagnostic.prediction.associateDiagnostic.exactCodeConfidenceThreshold'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.6,
            name: 'diagnostic.prediction.associateDiagnostic.rootCodeConfidenceThreshold'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 20,
            name: 'diagnostic.prediction.associateDiagnostic.limitMin'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 50,
            name: 'diagnostic.prediction.associateDiagnostic.limitMax'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.001,
            name: 'diagnostic.prediction.associateDiagnostic.confidenceMin'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.1,
            name: 'diagnostic.prediction.associateDiagnostic.confidenceDefined'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 'diagnostic',
            name: 'diagnostic.prediction.associateDiagnostic.typeDisplay'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.001,
            name: 'diagnostic.prediction.associateDiagnostic.root.confidenceMin'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.1,
            name: 'diagnostic.prediction.associateDiagnostic.root.confidenceDefault'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'diagnostic.prediction.associateDiagnostic.level.1.display'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 20,
            name: 'diagnostic.prediction.associateDiagnostic.level.1.limitMin'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 50,
            name: 'diagnostic.prediction.associateDiagnostic.level.1.limitMax'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.01,
            name: 'diagnostic.prediction.associateDiagnostic.level.1.confidenceMin'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.7,
            name: 'diagnostic.prediction.associateDiagnostic.level.1.confidenceDefined'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'diagnostic.prediction.associateDiagnostic.level.2.display'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 20,
            name: 'diagnostic.prediction.associateDiagnostic.level.2.limitMin'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 50,
            name: 'diagnostic.prediction.associateDiagnostic.level.2.limitMax'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.01,
            name: 'diagnostic.prediction.associateDiagnostic.level.2.confidenceMin'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.5,
            name: 'diagnostic.prediction.associateDiagnostic.level.2.confidenceDefined'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'diagnostic.prediction.associateDiagnostic.level.3.display'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 20,
            name: 'diagnostic.prediction.associateDiagnostic.level.3.limitMin'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 50,
            name: 'diagnostic.prediction.associateDiagnostic.level.3.limitMax'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.01,
            name: 'diagnostic.prediction.associateDiagnostic.level.3.confidenceMin'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.4,
            name: 'diagnostic.prediction.associateDiagnostic.level.3.confidenceDefined'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'diagnostic.prediction.associateDiagnostic.level.4.display'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 20,
            name: 'diagnostic.prediction.associateDiagnostic.level.4.limitMin'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 50,
            name: 'diagnostic.prediction.associateDiagnostic.level.4.limitMax'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.01,
            name: 'diagnostic.prediction.associateDiagnostic.level.4.confidenceMin'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.2,
            name: 'diagnostic.prediction.associateDiagnostic.level.4.confidenceDefined'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'diagnostic.prediction.severity.display'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.4,
            name: 'diagnostic.prediction.severity.threshold'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'diagnostic.prediction.morbidManifestationDiagnostic.display'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 6,
            name: 'diagnostic.prediction.morbidManifestationDiagnostic.limitMin'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 10,
            name: 'diagnostic.prediction.morbidManifestationDiagnostic.limitMax'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.6,
            name: 'diagnostic.prediction.morbidManifestationDiagnostic.exactCodeConfidenceThreshold'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.6,
            name: 'diagnostic.prediction.morbidManifestationDiagnostic.rootCodeConfidenceThreshold'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.001,
            name: 'diagnostic.prediction.morbidManifestationDiagnostic.confidenceMin'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.01,
            name: 'diagnostic.prediction.morbidManifestationDiagnostic.confidenceDefined'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 'diagnostic',
            name: 'diagnostic.prediction.morbidManifestationDiagnostic.typeDisplay'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'diagnostic.prediction.etiologicalAffectionDiagnostic.display'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 6,
            name: 'diagnostic.prediction.etiologicalAffectionDiagnostic.limitMin'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 10,
            name: 'diagnostic.prediction.etiologicalAffectionDiagnostic.limitMax'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.6,
            name: 'diagnostic.prediction.etiologicalAffectionDiagnostic.exactCodeConfidenceThreshold'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.6,
            name: 'diagnostic.prediction.etiologicalAffectionDiagnostic.rootCodeConfidenceThreshold'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.001,
            name: 'diagnostic.prediction.etiologicalAffectionDiagnostic.confidenceMin'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.01,
            name: 'diagnostic.prediction.etiologicalAffectionDiagnostic.confidenceDefined'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 'diagnostic',
            name: 'diagnostic.prediction.etiologicalAffectionDiagnostic.typeDisplay'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'diagnostic.prediction.option.changeLimitDisplay'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'diagnostic.diagnosticRoot'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.001,
            name: 'diagnostic.justification.minScore'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.001,
            name: 'diagnostic.goldIncreaseScore'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'dataSetElement.isReady'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'filterSearch.revaluationDefaultValue'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'filterSearch.search.isCommonActivated'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'filterSearch.search.isCothActivated'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'filterSearch.search.cothDefaultValue'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'filterSearch.search.isPerPageFilterActivated'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'health.canDisplayEssentialDocuments'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'health.canDisplayStayRisk'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'health.canDisplayStayStartDate'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'health.canDisplayBilling'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'health.canDisplayFinessId'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'health.showAdditionalStayInfoDefaultValue'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'health.document.isPreDefault'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'health.document.canDisplayDocumentTypeFromML'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.627,
            name: 'health.document.documentTypeFromMLDisplayThreshold.crh'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.885,
            name: 'health.document.documentTypeFromMLDisplayThreshold.cro'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 0.892,
            name: 'health.document.documentTypeFromMLDisplayThreshold.anapath'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: '12px',
            name: 'health.document.synthesisFontSize'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 'documentTypeName',
            name: 'health.document.listingBoxDisplayType'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'health.isGroupingActivated'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 90,
            name: 'predictionAnalysis.minGoldThreshold'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 70,
            name: 'predictionAnalysis.minSilverThreshold'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 50,
            name: 'predictionAnalysis.minBronzeThreshold'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 6,
            name: 'predictionAnalysis.rangeOfActivity'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: 'https://logs.cothinking.org',
            name: 'logStashEndpoint'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'menu.canDisplayQualityControlModule'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'menu.canDisplayQualityControlImpreciseDiagnosticModule'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'menu.canDisplayFilterSearchModule'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'menu.canDisplayPredictionAnalysisModule'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'menu.canDisplayAutoDiagnosisModule'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'menu.canDisplayPatientModule'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'menu.canDisplayPatientFilterSearchModule'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'menu.canDisplayDashboardModule'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'menu.canDisplayExternalStayModule'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'menu.canDisplayChronicDiagnosticModule'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'menu.canDisplayModularDashboardModule'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 'currentMonth',
            name: 'qualityControl.exitDateRange'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'qualityControl.canDisplayUpdateStayStatusConfirmationDialogForPatientModule'
        },
        {
            type: 'front',
            isPublic: true,
            version: 1,
            content: 'Traité',
            name: 'customWording.stayStatus.checked'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: 'Traités',
            name: 'customWording.stayStatus.checkedPlural'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: 'traité(s)',
            name: 'customWording.stayStatus.checkedBis'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: 'Non traité',
            name: 'customWording.stayStatus.unchecked'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: 'Non traités',
            name: 'customWording.stayStatus.uncheckedPlural'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: 'Vérifié',
            name: 'customWording.stayStatus.verified'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: 'Vérifiés',
            name: 'customWording.stayStatus.verifiedPlural'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: 'vérifié(s)',
            name: 'customWording.stayStatus.verifiedBis'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: 'currentYear',
            name: 'dashboard.defaultDateRange'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: 'month',
            name: 'dashboard.defaultScale'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: true,
            name: 'nearAutomation.useLessThan3NightsFilter'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'nearAutomation.displayAllStayPredictions'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'date.canDisplayImpreciseStayDateIndicator'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'patient.canDisplayMissingIPPIndicator'
        },
        {
            type:  'front',
            isPublic: true,
            version: 1,
            content: false,
            name: 'SSR.stayCodification.canDuplicateActCodification'
        }
    ]
};
