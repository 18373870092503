/**
 * Spatie/QueryBuilder payload configurator.
 */
export class FilterConfigurator {
    /**
     * Configurator's list of FilterItems.
     */
    public filters: FilterItem[] = [];

    /**
     * Configurator's list of SortItems.
     */
    public sorts: SortItem[] = [];

    /**
     * @param filters The configurator's filters list.
     * @param sorts The configurator's sorts list.
     */
    constructor(filters: FilterItem[] = [], sorts: SortItem[] = []) {
        this.filters = filters;
        this.sorts = sorts;
    }

    /**
     * Static shorthand method to create a new FilterItem.
     *
     * @param filters The configurator's filters list.
     * @param sorts The configurator's sorts list.
     */
    public static create(filters: FilterItem[] = [], sorts: SortItem[] = []): FilterConfigurator {
        return new FilterConfigurator(filters, sorts);
    }

    /**
     * Serialize the current FilterConfigurator to a JSON object.
     */
    public toJSON() {
        const includes = [];
        const filters = {};

        this.filters.filter(filter => filter.value).forEach(filter => {
            includes.push(...filter.includes);

            Object.assign(filters, filter.toJSON());
        });

        const payload = {
            ...filters,
            sort: this.sorts.filter(sort => sort.key).map(sort => {
                includes.push(...sort.includes);

                return sort.key;
            }).join(','),
            include: includes.join(',')
        };

        if (!payload.include.length) {
            delete payload.include;
        }

        if (!payload.sort.length) {
            delete payload.sort;
        }

        return payload;
    }
}

/**
 * Filter item used with FilterConfigurator.
 *
 * @see FilterConfigurator.create
 */
export class FilterItem {
    /**
     * Key that will be used in the request's payload.
     */
    public key: string | null = null;

    /**
     * Filter's value.
     */
    public value: string | number | boolean | null;

    /**
     * List of relations to include.
     */
    public includes: string[];

    /**
     * @param key The key that will be used in the request's payload.
     * @param value The filter's value.
     * @param includes The list of relations to include.
     */
    constructor(key: string | null, value: string | number | boolean | null, includes: string[] = []) {
        this.key = key;
        this.value = value;
        this.includes = includes;
    }

    /**
     * Static shorthand method to create a new FilterItem.
     *
     * @param key The key that will be used in the request's payload.
     * @param value The filter's value.
     * @param includes The list of relations to include.
     */
    public static create(key: string | null, value: string | number | boolean | null, includes: string[] = []): FilterItem {
        return new FilterItem(key, value, includes);
    }

    /**
     * Serialize the current FilterItem to a JSON object.
     *
     * @see FilterConfigurator.toJSON
     */
    public toJSON() {
        const obj = {};

        obj[`filter[${this.key}]`] = this.value;

        return obj;
    }
}

/**
 * Sort item used with FilterConfigurator.
 *
 * @see FilterConfigurator.create
 */
export class SortItem {
    /**
     * Key that will be used in the request's payload.
     */
    public key: string | null = null;

    /**
     * List of relations to include.
     */
    public includes: string[];

    /**
     * @param value The sort's value.
     * @param includes The list of relations to include.
     */
    constructor(value: string | null, includes: string[] = []) {
        this.key = value;
        this.includes = includes;
    }

    /**
     * Static shorthand method to create a new SortItem.
     *
     * @param value The sort's value.
     * @param includes The list of relations to include.
     */
    public static create(value: string | null, includes: string[] = []): SortItem {
        return new SortItem(value, includes);
    }
}
