import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {StayDetailDependencyPredictionElementComponent} from './stay-detail-dependency-prediction-element.component';
import {MatIconModule} from '@angular/material/icon';
import {SimplifiedScoreModule} from '../../../../../shared/components/simplified-score/simplified-score.module';
import {TooltipDirectiveModule} from '../../../../../shared/directives/tooltip/tooltip.directive.module';
import { StayDetailDependencyPredictionElementTooltipComponent } from './tooltip/stay-detail-dependency-prediction-element-tooltip.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {WarningIndicatorModule} from '../../../../../shared/components/warning-indicator/warning-indicator.module';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    declarations: [
        StayDetailDependencyPredictionElementComponent,
        StayDetailDependencyPredictionElementTooltipComponent,
    ],
    exports: [
        StayDetailDependencyPredictionElementComponent
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatIconModule,
        SimplifiedScoreModule,
        TooltipDirectiveModule,
        MatButtonModule,
        MatCardModule,
        WarningIndicatorModule,
        MatTooltipModule,
    ]
})
export class StayDetailDependencyPredictionElementModule {
}
