import {Component, Input} from '@angular/core';
import {AuthenticationService} from '../../../../../core/authentication/authentication.service';
import {FilterSearch} from '../../../../filter-search/shared/filter-search.model';
import {StateService} from '@uirouter/core';
import {DataSetElement} from '../../../shared/data-set-element.model';
import {DataSetElementService} from '../../../shared/data-set-element.service';
import {BroadcastService} from '../../../../../core/services/broadcast.service';
import {DashboardService} from '../../../../dashboard/dashboard.service';

@Component({
    selector: 'ct-stay-detail-filter-search-menu',
    templateUrl: './stay-detail-filter-search-menu.component.html',
    styleUrls: ['./stay-detail-filter-search-menu.component.scss']
})
export class StayDetailFilterSearchMenuComponent {

    @Input() filterSearch: FilterSearch;
    @Input() dataSetElement: DataSetElement;
    @Input() filterSearchStatus: number;

    constructor(
        public $state: StateService,
        private _authenticationService: AuthenticationService,
        private dataSetElementService: DataSetElementService,
        private _broadcastService: BroadcastService,
        private _dashboardService: DashboardService,
    ) {
    }

    displayMenu(): boolean {
        return this._authenticationService.hasRole('admin');
    }

    async goToFilterSearchDetail(event: any) {
        event.stopPropagation();
        if (this.filterSearch) {
            const {fromExternalStayList} = this.$state.params;

            // Save lastFilterSearchName to display the rule information in the detail page rule
            sessionStorage.setItem('lastFilterSearchViewed', JSON.stringify({
                id: this.filterSearch.id,
                name: this.filterSearch.name,
                params: this.filterSearch.params,
                codify: this.filterSearch.codify,
                isPublic: this.filterSearch.isPublic,
                isCommon: this.filterSearch.isCommon,
                tags: this.filterSearch?.tags,
                type: this.filterSearch?.type,
            }));

            // Save filterSearchQuery to display rule criteria information in the rule detail page
            sessionStorage.setItem('filterSearchQuery', JSON.stringify(
                this.filterSearch.params.query
            ));

            // Save default uriParams in lastFilterSearchStateParams to force return to rules list instead of worklist
            const uriParams = {
                collection: 'own,shared,common,default',
                diagnosticAutoPricing: 'true',
                revaluationCodificationLabel: 'DA_RULE',
                codificationStatus: ['0'],
                page: '1',
                perPage: '10',
                tagName: 'diagnosis,act,worklist,none',
                type: '-1'
            };
            sessionStorage.setItem('lastFilterSearchStateParams', JSON.stringify(
                uriParams
            ));

            sessionStorage.setItem('initFirstSearch', 'true');

            let url = '#/data-set/1/codification/1';
            if (fromExternalStayList) {
                url = url + '/external';
            }
            url = url + '?page=1&fromFilterSearchList=true';
            window.open(url, '_blank');
            sessionStorage.removeItem('filterSearchQuery');
        }
    }

    async editFilterSearchStatus(status: number) {
        if (this.filterSearch) {
            this.dataSetElementService.setFilterSearchValidity(this.dataSetElement.parentId, this.filterSearch.id, status).then(async (data) => {
                const eventData = {
                    stayId: this.dataSetElement.dataSetContent.stayId,
                    filterSearchStatus: status,
                    filterSearchName: this.filterSearch.name,
                    filterSearchId: this.filterSearch.id,
                    endDate: this.dataSetElement.dataSetContent.endDate,
                    type: this.dataSetElement.dataSetContent.type
                };
                await this._dashboardService.sendEvent('stay_rule_status', eventData);
                this.dataSetElement.filtersSearch.filter(el => el.id === this.filterSearch.id).map(el => el.validityStatus = status);
                    this._broadcastService.send('diagnostic::updateFilterSearchTab', data);
                }
            );
        }
    }

    public isAtypie() {
        return this.filterSearch.type === 'healthStayAtypia';
    }
}
