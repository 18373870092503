import {Component, HostListener, OnInit} from '@angular/core';
import {BroadcastService} from '../../../core/services/broadcast.service';

@Component({
    selector: 'ct-stay-detail-dialog',
    templateUrl: './stay-detail-dialog.component.html',
    styleUrls: ['./stay-detail-dialog.component.scss']
})
export class StayDetailDialogComponent implements OnInit {

    constructor(private _broadcastService: BroadcastService) {}

    ngOnInit() {}

    @HostListener('scroll', ['$event'])
    onDialogScroll(event) {
        if (event &&
            event.target) {
            this._broadcastService.send('stayDetailDialog::scroll', {scrollTop: event.target.scrollTop});
        }
    }
}
