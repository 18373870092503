import {Injectable} from '@angular/core';
import {MenuApiService, MenuItemFromApi} from '../../api-services/menu/menu.api.service';
import {ConfigurationService} from '../../../modules/configuration/configuration.service';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '@uirouter/core';
import {AuthenticationService} from '../../authentication/authentication.service';
import {TranslationHelperService} from '../../services/translation.helper.service';
import {BroadcastService} from '../../services/broadcast.service';

export interface MenuItemGroup {
    id: number;
    rank: number;
    name: string;
    color: string;
    icon?: string; // icon or text
    text?: string; // icon or text
    items: MenuItem[];
    canDisplay: boolean;
}

export interface MenuItem {
    groupId: number;
    rank: number;
    name: string;
    params?: any;
    state?: string;
    canDisplay?: boolean;
    isActive?: boolean;
    hasBadge?: boolean;
    badgeValue?: number;
}

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    private _HAS_ALREADY_LOADED_MENU_ITEMS = false;
    private _MENU_ITEM_GROUPS: MenuItemGroup[] = [];

    constructor(public $state: StateService,
                private _menuApiService: MenuApiService,
                private _broadcastService: BroadcastService,
                private _configurationService: ConfigurationService,
                private _authenticationService: AuthenticationService,
                private _translationHelperService: TranslationHelperService,
                private _translateService: TranslateService) {
        this._initMenuItemGroups();
    }

    get menuItemGroups(): MenuItemGroup[] {
        return this._MENU_ITEM_GROUPS;
    }

    updateMenuItemBadgeValue(itemGroupId: number,
                             state: string,
                             newBadgeValue: number): void {
        const itemGroup = this._MENU_ITEM_GROUPS.find(el => el.id === itemGroupId);
        if (itemGroup) {
            const menuItem: MenuItem = itemGroup.items.find(item => item.state === state);
            if (menuItem) {
                menuItem.badgeValue = newBadgeValue;
            }
        }
    }

    async loadAllMenuItems(): Promise<MenuItemGroup[]> {
        try {
            if (this._HAS_ALREADY_LOADED_MENU_ITEMS) {
                // For logout
                this._emptyMenuItemGroupsItems();
            }
            const itemsFromApi: MenuItemFromApi[] = await this._menuApiService
                .getAll()
                .toPromise();
            this._HAS_ALREADY_LOADED_MENU_ITEMS = true;
            this._updateMenuItemGroups(itemsFromApi);
            return this.menuItemGroups;
        } catch (e) {
            // If user is not authenticated then redirect to login
            if (e?.status === 401) {
                this._authenticationService.storeStateRedirection();
                this.$state.go('authentication.login');
            }
            console.error(e);
            throw e;
        }
    }

    /**
     * Deactivate all items in the menu except the one that is clicked
     * @param menuItem item clicked
     */
    handleClickOnItem(menuItem: MenuItem): void {
        if (menuItem) {
            this._MENU_ITEM_GROUPS = this._MENU_ITEM_GROUPS.map(itemGroup => ({
                ...itemGroup,
                items: itemGroup.items
                    .map(item => ({
                        ...item,
                        isActive: menuItem.groupId === itemGroup.id && menuItem.state === item.state
                    }))
            }));
            this._broadcastService.send('subMenu::itemClicked');
        }
    }

    canDisplayItemBadge(item: MenuItem): boolean {
        return !!(item?.hasBadge && item?.badgeValue > 0);
    }

    private _initMenuItemGroups(): void {
        this._MENU_ITEM_GROUPS = [
            {id: 1, rank: 1, icon: 'insert_drive_file', color: 'md-icon-blue', name: this._translateService.instant('MENU.CODIFICATION'), items: [], canDisplay: false},
            {id: 2, rank: 10, icon: 'dvr', color: 'md-icon-blue', name: this._translateService.instant('MENU.QUALITY_CONTROL'), items: [], canDisplay: false},
            {id: 3, rank: 20, icon: 'assignment_turned_in', color: 'md-icon-blue', name: this._translateService.instant('MENU.DEPENDENCIES'), items: [], canDisplay: true},
            {id: 4, rank: 30, icon: 'dashboard', color: 'md-icon-blue', name: this._translateService.instant('MENU.PILOTING'), items: [], canDisplay: false},
            {id: 5, rank: 40, icon: 'people_outline', color: 'md-icon-blue', name: this._translateService.instant('MENU.CLINICAL_RESEARCH'), items: [], canDisplay: false},
            {id: 6, rank: 50, icon: 'settings', color: 'md-icon-blue', name: this._translateService.instant('MENU.SETTINGS'), items: [], canDisplay: false},
            {id: 7, rank: 60, icon: 'brightness_auto', color: 'md-icon-blue', name: this._translateService.instant('MENU.ADMIN'), items: [], canDisplay: false},
            {id: 8, rank: 70, icon: 'account_box', color: 'md-icon-blue', name: this._translateService.instant('MENU.MY_SPACE'), items: [], canDisplay: false},
        ];
    }

    private _canDisplayMenuItem(item: any): boolean {
        if (!item) {
            return false;
        }

        switch (item.state) {
            case 'layout.quality-control':
                return this._configurationService.getConfigurationContent('front', 'menu.canDisplayQualityControlModule');
            case 'layout.quality-control-imprecise-diagnostic':
                return this._configurationService.getConfigurationContent('front', 'menu.canDisplayQualityControlImpreciseDiagnosticModule');
            case 'layout.quality-control-near-automation':
                return this._configurationService.getConfigurationContent('front', 'menu.canDisplayQualityControlNearAutomationModule');
            case 'layout.stay-list-external':
                return this._configurationService.getConfigurationContent('front', 'menu.canDisplayExternalStayModule');
            case 'layout.stay-list':
                return this._configurationService.getConfigurationContent('back', 'APP.TYPE') === 'MCO' || this._configurationService.appTypeIsMCOAndSSR();
            case 'layout.stay-list-rehabilitation':
                return this._configurationService.getConfigurationContent('back', 'APP.TYPE') === 'SSR' || this._configurationService.appTypeIsMCOAndSSR();
            case 'layout.filter-search-stay-list-external':
                return this._configurationService.getConfigurationContent('front', 'menu.canDisplayExternalStayModule');
            case 'layout.filter-search-stay-list':
                return this._configurationService.getConfigurationContent('front', 'menu.canDisplayFilterSearchModule');
            case 'layout.filter-search-patient-list':
                return this._configurationService.getConfigurationContent('front', 'menu.canDisplayPatientFilterSearchModule');
            case 'layout.prediction-analysis':
                return this._configurationService.getConfigurationContent('front', 'menu.canDisplayPredictionAnalysisModule');
            case 'layout.diagnostic-automatic':
                return this._configurationService.getConfigurationContent('front', 'menu.canDisplayAutoDiagnosisModule');
            case 'layout.patient-list':
                return this._configurationService.getConfigurationContent('front', 'menu.canDisplayPatientModule');
            case 'layout.dashboard':
                return this._configurationService.getConfigurationContent('front', 'menu.canDisplayDashboardModule');
            case 'layout.dashboard-custom':
                return this._configurationService.getConfigurationContent('front', 'menu.canDisplayModularDashboardModule');
            case 'layout.chronic-diagnostic-list':
                return this._configurationService.getConfigurationContent('front', 'menu.canDisplayChronicDiagnosticModule');
            case 'layout.log':
                return this._configurationService.getConfigurationContent('front', 'menu.canDisplayAdminLogsModule');
            case 'layout.data-monitoring':
                return this._configurationService.getConfigurationContent('front', 'menu.canDisplayDataMonitoringModule');
            case 'layout.stay-dependency':
                return this._configurationService.getConfigurationContent('front', 'menu.canDisplayDashboardDependency');
            default:
                return true;
        }
    }

    private _getDefaultMenuItem(stateName: string): MenuItem {
        const itemToReturn: MenuItem = {groupId: 0, rank: 0, name: this._translationHelperService.getStateDisplayName(stateName), hasBadge: false};

        switch (stateName) {
            case 'dashboard':
                return {...itemToReturn, groupId: 4, rank: 1};
            case 'dashboard-custom':
                return {...itemToReturn, groupId: 4, rank: 2};
            case 'stay-list':
                return {...itemToReturn, groupId: 1, rank: 1};
            case 'stay-dependency':
                return {...itemToReturn, groupId: 3, rank: 1};
            case 'stay-list-rehabilitation':
                return {...itemToReturn, groupId: 1, rank: 2};
            case 'stay-list-external':
                return {...itemToReturn, groupId: 1, rank: 3};
            case 'filter-search-stay-list':
                return {...itemToReturn, groupId: 2, rank: 3};
            case 'filter-search-patient-list':
                return {...itemToReturn, groupId: 5, rank: 2};
            case 'quality-control':
                return {...itemToReturn, groupId: 2, rank: 1};
            case 'quality-control-imprecise-diagnostic':
                return {...itemToReturn, groupId: 2, rank: 2};
            case 'quality-control-near-automation':
                return {...itemToReturn, groupId: 1, rank: 3};
            case 'diagnostic-note-list':
                return {...itemToReturn, groupId: 8, rank: 2};
            case 'filter-search-stay-list-external':
                return {...itemToReturn, groupId: 2, rank: 4};
            case 'patient-list':
                return {...itemToReturn, groupId: 5, rank: 1};
            case 'document-type-list':
                return {...itemToReturn, groupId: 6, rank: 2};
            case 'user-list':
                return {...itemToReturn, groupId: 6, rank: 1};
            case 'ml-report-list':
                return {...itemToReturn, groupId: 7, rank: 5};
            case 'storyboard-list':
                return {...itemToReturn, groupId: 7, rank: 3};
            case 'monitoring':
                return {...itemToReturn, groupId: 7, rank: 4};
            case 'log':
                return {...itemToReturn, groupId: 7, rank: 2};
            case 'configuration':
                return {...itemToReturn, groupId: 7, rank: 1};
            case 'chronic-diagnostic-list':
                return {...itemToReturn, groupId: 6, rank: 5};
            case 'prediction-analysis':
                return {...itemToReturn, groupId: 6, rank: 3};
            case 'diagnostic-automatic':
                return {...itemToReturn, groupId: 6, rank: 4};
            case 'worklist-user':
                return {...itemToReturn, groupId: 8, rank: 3};
            case 'data-monitoring':
                return {...itemToReturn, groupId: 7, rank: 6};
            default:
                return {...itemToReturn, groupId: 0, rank: 1000};
        }
    }

    /**
     * In DB state is like layout.stay-list but because of lazy loading we only need stay-list
     * @param name
     * @private
     */
    private _extractState(name: string): string {
        return name?.split('.')[1] || '';
    }

    private _mapToMenuItem(itemFromApi: MenuItemFromApi): MenuItem {
        if (itemFromApi) {
            const stateName = this._extractState(itemFromApi.state);
            return {
                ...this._getDefaultMenuItem(stateName),
                state: stateName,
                params: itemFromApi.params ? JSON.parse(itemFromApi.params) : {},
                canDisplay: this._canDisplayMenuItem(itemFromApi),
                isActive: this.$state.current.name === stateName
            };
        }
        return {groupId: 0, name: '', rank: 0};
    }

    private _fillMenuItemGroups(menuItem: MenuItem): void {
        if (menuItem) {
            const menuItemGroup: MenuItemGroup = this._MENU_ITEM_GROUPS.find(itemGroup => itemGroup.id === menuItem.groupId);
            menuItemGroup?.items.push(menuItem);
        }
    }

    private _updateMenuItemGroupsCanDisplayProperty(): void {
        for (const itemGroup of this._MENU_ITEM_GROUPS) {
            itemGroup.canDisplay = itemGroup.items.some(item => item.canDisplay);
        }
    }

    private _updateMenuItemGroups(itemsFromApi: MenuItemFromApi[]): void {
        itemsFromApi?.forEach(itemFromApi => {
            this._fillMenuItemGroups(this._mapToMenuItem(itemFromApi));
        });
        this._updateMenuItemGroupsCanDisplayProperty();
    }

    private _emptyMenuItemGroupsItems(): void {
        this._MENU_ITEM_GROUPS = this._MENU_ITEM_GROUPS.map(itemGroup => ({...itemGroup, items: []}));
    }
}
