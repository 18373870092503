import {TranslationHelperService} from '../../../../core/services/translation.helper.service';
import {Injectable} from '@angular/core';
import {CodificationLabelEnum} from '../../../stay/shared/codification-label-enum';

@Injectable({
    providedIn: 'root'
})
export class DiagnosticSlugHelperService {

    constructor(private _translationHelperService: TranslationHelperService) {
    }

    public getDefaultCodificationLabels(isMCOUseCase: boolean,
                                        isSSRUseCase: boolean,
                                        isDRAvailable: boolean): string {
        let defaultCodificationLabels = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.DA);
        if (isMCOUseCase && isSSRUseCase) {
            // Means we are in patient-list or patient-detail
            defaultCodificationLabels += `;${this._getDefaultCodificationLabelsMCO(isDRAvailable)};${this._getDefaultCodificationLabelsSSR()}`;
        } else if (isMCOUseCase && !isSSRUseCase) {
            // Means we are in stay-list
            defaultCodificationLabels += `;${this._getDefaultCodificationLabelsMCO(isDRAvailable)}`;
        } else if (isSSRUseCase && !isMCOUseCase) {
            // Means we are in stay-list-rehabilitation
            defaultCodificationLabels += `;${this._getDefaultCodificationLabelsSSR()}`;
        }
        return defaultCodificationLabels;
    }

    private _getDefaultCodificationLabelsMCO(isDRAvailable: boolean): string {
        return `${this._translationHelperService.getCodificationLabel(CodificationLabelEnum.DP)}${isDRAvailable ? ';' + this._translationHelperService.getCodificationLabel(CodificationLabelEnum.DR) : ''}`;
    }

    private _getDefaultCodificationLabelsSSR(): string {
        return `${this._translationHelperService.getCodificationLabel(CodificationLabelEnum.MP)};${this._translationHelperService.getCodificationLabel(CodificationLabelEnum.AE)}`;
    }
}
