<div class="row">
    <div class="col no-padding">
        <ct-qualifact-control-search
                *ngIf="!isLoading && qualifactControls"
                [(param)]="condition.service.params.codes"
                [qualifactControls]="qualifactControls">
        </ct-qualifact-control-search>
    </div>
</div>
<div class="row">
    <div class="col no-padding">
        <ct-diagnostic-search [(param)]="condition.service.params.diagnoses"></ct-diagnostic-search>
    </div>
</div>
<div class="row">
    <div class="col no-padding">
        <ct-act-search [(param)]="condition.service.params.acts"
                       [useCase]="['ccam', 'csarr']"></ct-act-search>
    </div>
</div>
