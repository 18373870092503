import {Component, Input, OnInit} from '@angular/core';
import {ColorService} from '../../../../core/services/color.service';

@Component({
    selector: 'ct-search-engine-condition-select-with-static-data-validity',
    templateUrl: './search-engine-condition-select-with-static-data-validity.component.html',
    styleUrls: ['./search-engine-condition-select-with-static-data-validity.component.scss']
})
export class SearchEngineConditionSelectWithStaticDataValidityComponent implements OnInit {
    constructor(
        public colorService: ColorService,
    ) { }

    @Input() condition: any;
    @Input() data: any[] = [];
    @Input() paramKey: string;
    @Input() placeholder: string;

    ngOnInit(): void {
    }
}
