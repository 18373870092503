interface UseCases {
    Add: string;
    AutoCodification: string;
    Cohort: string;
    DP: string;
    Edit: string;
    Entrance: string;
    GHM: string;
    GHS: string;
    Gold: string;
    NearAutoCodification: string;
    Patient: string;
    QualityControl: string;
    Silver: string;
    Stay: string;
    StayExternal: string;
    StayList: string;
    Work: string;
    WorkList: string;
    FilterSearch: string;
}

/**
 * Define search engine uses cases.
 *
 * @type UseCases
 */
export const UseCases: UseCases = {
    Add: 'add',
    AutoCodification: 'autoCodification',
    Cohort: 'cohort',
    DP: 'DP',
    Edit: 'edit',
    Entrance: 'entrance',
    FilterSearch: 'filterSearch',
    GHM: 'ghm',
    GHS: 'ghs',
    Gold: 'gold',
    NearAutoCodification: 'nearAutoCodification',
    Patient: 'patient',
    QualityControl: 'qualityControl',
    Silver: 'silver',
    Stay: 'stay',
    StayExternal: 'stayExternal',
    StayList: 'stayList',
    Work: 'work',
    WorkList: 'worklist',
};
