import {Component, Input, OnInit} from '@angular/core';
import {HttpService} from '../../../core/http/http.service';
import {StateService} from '@uirouter/core';

@Component({
    selector: 'ct-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Input() icon: string;
    @Input() title: string;
    @Input() largeMargin: boolean;
    @Input() withoutMargin: boolean;
    @Input() color: string;
    @Input() useGradient = true;

    constructor(
        private $state: StateService,
        private _httpService: HttpService
    ) {}

    ngOnInit() {
    }

    getClasses() {
        const classes = {
            'with-margin': !this.withoutMargin,
            'large-margin': this.largeMargin
        };

        if (!this.useGradient) {
            classes[`md-${this.color}`] = true;

            return classes;
        }

        this._httpService.isResearchUrl(this.$state.href(this.$state.current.name, this.$state.params))
            ? classes['gradient-research'] = true
            : classes['gradient-blue'] = true;

        return classes;
    }

}
