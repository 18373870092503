import {Component, OnInit} from '@angular/core';
import {DiagnosticService} from '../../../../../diagnostic/diagnostic.service';
import {TranslationHelperService} from '../../../../../../core/services/translation.helper.service';
import {DataSetElementService} from '../../../../shared/data-set-element.service';
import {BroadcastService} from '../../../../../../core/services/broadcast.service';
import {LayoutService} from '../../../../../layout/layout.service';
import {TranslateService} from '@ngx-translate/core';
import {DataSetElement} from '../../../../shared/data-set-element.model';
import {MatDialog} from '@angular/material/dialog';
import {SnackBarService} from '../../../../../../core/services/snack-bar.service';
import {CodificationLabelsModel} from '../../../../shared/codification-labels.model';

@Component({
    selector: 'ct-stay-detail-dependency-prediction-element-tooltip',
    templateUrl: './stay-detail-dependency-prediction-element-tooltip.component.html',
    styleUrls: ['./stay-detail-dependency-prediction-element-tooltip.component.scss',
        '../../../diagnostic-prediction/element/tooltip/stay-detail-diagnostic-prediction-element-tooltip.component.scss']
})
export class StayDetailDependencyPredictionElementTooltipComponent implements OnInit {

    predictiveDependencies: any;
    codificationLabels: CodificationLabelsModel;
    dataSetElement: DataSetElement;

    constructor(
        private _matDialog: MatDialog,
        private _dataSetElementService: DataSetElementService,
        private _diagnosticService: DiagnosticService,
        private _translationHelperService: TranslationHelperService,
        private _broadcastService: BroadcastService,
        private _layoutService: LayoutService,
        private _translateService: TranslateService,
        private _snackBarService: SnackBarService,
    ) {
    }

    ngOnInit(): void {
    }

    async updateCodification(label: any): Promise<void> {
        if (this.predictiveDependencies
            && label) {
            const data = {
                predictiveDependency: [this.predictiveDependencies.id],
                type: label.slug,
            };
            this._layoutService.startLoading();
            // const resp = await this._dataSetElementService.addDependency(this.dataSetElement.id, data);
          //  this._broadcastService.send('dependency::AddDependency', resp);
            this._layoutService.stopLoading();
        }
    }

    getMatTooltip(label: any): string {
        if (label) {
            return `${this._translateService
                .instant('DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.' + label)}`;
        }
        return '';
    }

    showJustification(): void {
        if (this.predictiveDependencies && this.predictiveDependencies.act.diagnostics.length) {
            let stay: any;
            if (this.dataSetElement) {
                stay = this.dataSetElement.dataSetContent;
            }
                this._broadcastService.send('dataSetElementStayJustification::addTab', {
                    diagnostic: this.predictiveDependencies.act,
                    stay
                });
        }
    }
}
