<div class="row">
    <div class="col no-padding">

        <div
            *ngIf="predictiveActs?.length"
            class="predictions-block">
            <div class="row"
                 *ngFor="let predictiveAct of filterActPrediction(); index as i; last as isLast">
                <div class="col no-padding hover-prediction">
                    <div>

                        <div class="flex-grow-1">
                            <ct-stay-detail-act-prediction-element
                                [predictiveAct]="predictiveAct"
                            ></ct-stay-detail-act-prediction-element>
                        </div>
                    </div>
                    <mat-divider *ngIf="!isLast"></mat-divider>
                </div>
            </div>
        </div>

        <ng-container
            *ngIf="!filterActPrediction()?.length"
        >
            <!--No data-->
            <div class="row">
                <div class="col no-padding">
                    <div class="padding-top-16 text-disabled text-center">
                        <span>{{'DATA_SET.CODIFICATION_ELEMENT.PREDICTIVE_DIAGNOSTIC.NO_DATA' | translate}}</span>
                    </div>
                </div>
            </div>
        </ng-container>

    </div>
</div>


