import {Component, OnInit} from '@angular/core';
import {TranslationHelperService} from '../../../../core/services/translation.helper.service';
import {ColorService} from '../../../../core/services/color.service';
import {StateService} from '@uirouter/core';
import {CodificationLabelEnum} from '../../../stay/shared/codification-label-enum';
import {UseCases} from '../../../filter-search/enums/use-cases';

@Component({
    selector: 'ct-search-engine-condition-diagnostic-missing',
    templateUrl: './search-engine-condition-diagnostic-missing.component.html',
    styleUrls: ['./search-engine-condition-diagnostic-missing.component.scss']
})
export class SearchEngineConditionDiagnosticMissingComponent implements OnInit {
    private _codificationLabels = {
        DP: '',
        DA: ''
    };

    condition: any;
    revaluation = {
        DP: false,
        DA: true
    };
    isMCOUseCase: boolean;
    isSSRUseCase: boolean;

    constructor(public $state: StateService,
                public colorService: ColorService,
                private _translationHelperService: TranslationHelperService) { }

    ngOnInit(): void {
        this._initCodificationLabels();
        this._initRevaluation();
    }

    private _initCodificationLabels(): void {
        if (!this.isSSRUseCase) {
            this._codificationLabels.DP = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.DP) || 'DP';
        }
        this._codificationLabels.DA = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.DA) || 'DA';
    }

    private _initRevaluation(): void {
        if (this.condition.service.params.diagnosticMissingCodificationLabel) {
            if (!this.isSSRUseCase) {
                this.revaluation.DP = this.condition.service.params.diagnosticMissingCodificationLabel.includes(this._codificationLabels.DP) && this.condition.service.params.diagnosticAutoPricing === true;
            }
            this.revaluation.DA = this.condition.service.params.diagnosticMissingCodificationLabel.includes(this._codificationLabels.DA) && this.condition.service.params.diagnosticAutoPricing === true;
        }
    }

    private _updateDiagnosticAutoPricing(): void {
        // If we have none diagnosticAutoPricing is false
        if (!this.condition.service.params.diagnosticMissingCodificationLabel.some(x => x === this._codificationLabels.DP) &&
            !this.condition.service.params.diagnosticMissingCodificationLabel.some(x => x === this._codificationLabels.DA)) {
            this.condition.service.params.diagnosticAutoPricing = false;
        } else if (this.condition.service.params.diagnosticMissingCodificationLabel.some(x => x === this._codificationLabels.DP) ||
            this.condition.service.params.diagnosticMissingCodificationLabel.some(x => x === this._codificationLabels.DA)) {
            this.condition.service.params.diagnosticAutoPricing = true;
        }
    }

    updateDiagnosticMissingCodificationLabelParam(useCase: string): void {
        if (this.condition.service.params.diagnosticMissingCodificationLabel?.length > 0 && this.condition.service.params.diagnosticAutoPricing === false) {
            this.condition.service.params.diagnosticMissingCodificationLabel = [];
        }

        if (!this.condition.service.params.diagnosticMissingCodificationLabel) {
            return;
        }

        const revaluation = useCase === UseCases.DP ? this.revaluation.DP : this.revaluation.DA;
        const codificationLabel = useCase === UseCases.DP ? this._codificationLabels.DP : this._codificationLabels.DA;

        if (revaluation) {
            this.condition.service.params.diagnosticMissingCodificationLabel.push(codificationLabel);
        } else {
            const index = this.condition.service.params.diagnosticMissingCodificationLabel.indexOf(codificationLabel);
            this.condition.service.params.diagnosticMissingCodificationLabel.splice(index, 1);
        }

        this._updateDiagnosticAutoPricing();

        if (this.condition.service.params.diagnosticMissingCodificationLabel.length === 0) {
            this.condition.service.params.diagnosticMissingCodificationLabel = ['DA', 'DP'];
        }
    }
}
