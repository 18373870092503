import {Injectable} from '@angular/core';
import {HttpService} from '../../http/http.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HealthStayApiService {
    private _BASE_URL = 'health-stay';

    constructor(private _http: HttpService) { }

    addUser(dataSetContentId: number, data?: any) {
        return this._http
            .post(`${this._BASE_URL}/${dataSetContentId}/user`, data)
            .pipe(map(res => res.data));
    }

    updateUser(dataSetContentId: number, data?: any) {
        return this._http
            .patch(`${this._BASE_URL}/${dataSetContentId}/user`, data)
            .pipe(map(res => res.data));
    }

    removeUser(dataSetContentId: number, userId: number) {
        return this._http
            .delete(`${this._BASE_URL}/${dataSetContentId}/user/${userId}`)
            .pipe();
    }

    loadPredictiveActs(dataSetContentId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${dataSetContentId}/act-predictive`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }

    loadPredictiveDependencies(dataSetElementId: number, urlRequestParams?: any) {
        return this._http
            .get(`${this._BASE_URL}/${dataSetElementId}/dependency-predictive`, {params: urlRequestParams})
            .pipe(map(res => res.data));
    }
}
