import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';
import {ConfigurationService} from '../../../configuration/configuration.service';

@Injectable()

export class SearchEngineConditionPatientIppService extends SearchEngineCondition {
    // Because of shared component
    paramKey = 'patientIpp';
    placeholder = 'ACRONYM.PATIENTIPP';

    constructor(private _translateService: TranslateService,
                private _configurationService: ConfigurationService) {
        super(['patientIpp'],
            _translateService.instant('ACRONYM.PATIENTIPP'),
            'patientIpp',
            null,
            false);
        this.init();
    }
    private isSpaceInStayIdSearch = true;

    init(apiParams?: ApiCriteria): void {
        this.isSpaceInStayIdSearch = this._configurationService.getConfigurationContent('front', 'advancedSearch.isSpaceInStayIdSearch');
        this.params = {patientIpp: null};
        this.setApiParams(apiParams);
    }

    private _storeIppList(obj: any): void {
        let ippList = null;
        if (obj.patientIpp) {
                ippList = obj.patientIpp;
        }
        ippList && !!ippList[0] ? sessionStorage.setItem('patientSearchIppList', JSON.stringify(ippList)) : sessionStorage.setItem('patientSearchIppList', '');
    }

    convertToUriParams(): void {
        this.uriParams.patientIpp = this.params.patientIpp;
        this._storeIppList(this.params);
    }

    convertToParams(): void {
        this.params.patientIpp = this.uriParams.patientIpp;
        this._storeIppList(this.uriParams);
    }

    convertToParamsFromApiParams(): void {
        this.params.patientIpp = this.apiParams.args.values;
        this._storeIppList(this.params);
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        if (this.params.patientIpp) {
            const separatorRegex = this.isSpaceInStayIdSearch ? /[,; |_]/ : /[,;|_]/;
            this.apiParams.args = { values: this.params.patientIpp.split(separatorRegex) };
            this._storeIppList(this.params);
        }
    }
}
