<ng-container>
    <button mat-icon-button
            class="menu-button"
            (click)="$event.stopPropagation()"
            [matMenuTriggerFor]="handleFilterMenu">
        <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #handleFilterMenu="matMenu"
              [class]="'filter-search-menu'">
        <ng-template matMenuContent>
            <button mat-menu-item
                    *ngIf="displayMenu()"
            (click)="goToFilterSearchDetail($event)">
                <span>{{'FILTER_SEARCH.SEE_DETAIL' | translate}}</span>
            </button>
            <button mat-menu-item
                    *ngIf="filterSearchStatus !== 3 && isAtypie()"
                    (click)="editFilterSearchStatus(1)">
                <span>{{'FILTER_SEARCH.VALIDATE_FILTERSEARCH' | translate}}</span>
            </button>
            <button mat-menu-item
                    *ngIf="filterSearchStatus !== 4"
            (click)="editFilterSearchStatus(2)">
                <span>{{'FILTER_SEARCH.INVALIDATE_FILTERSEARCH' | translate}}</span>
            </button>
            <button mat-menu-item
                    *ngIf="filterSearchStatus !== 2"
                    (click)="editFilterSearchStatus(0)">
                <span>{{'FILTER_SEARCH.NEUTRAL_FILTERSEARCH' | translate}}</span>
            </button>
        </ng-template>
    </mat-menu>
</ng-container>
