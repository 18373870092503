import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FilterSearchEditDialogComponent} from './edit-dialog/filter-search-edit-dialog.component';
import {BroadcastService} from '../../../core/services/broadcast.service';
import {FilterSearchApiService} from '../../../core/api-services/filter-search/filter-search.api.service';
import {FilterSearchDiagnosticApiService} from '../../../core/api-services/filter-search/diagnostic/filter-search-diagnostic.api.service';
import {AppLogService} from '../../../core/app-log/app-log.service';
import {FilterSearchSubscribeDialogComponent} from './subscribe-dialog/filter-search-subscribe-dialog.component';
import * as moment from 'moment';
import {SnackBarService} from '../../../core/services/snack-bar.service';
import {TranslateService} from '@ngx-translate/core';
import {FilterSearch} from './filter-search.model';
import {FilterConfigurator, FilterItem, SortItem} from './filter-configurator';

@Injectable({
    providedIn: 'root'
})
export class FilterSearchService {
    private readonly _collectiveUserIds = [1];

    get collectiveUserIds(): number[] {
        return this._collectiveUserIds;
    }

    constructor(
        private _matDialog: MatDialog,
        private _broadcastService: BroadcastService,
        private _filterSearchApiService: FilterSearchApiService,
        private _filterSearchDiagnosticService: FilterSearchDiagnosticApiService,
        private _snackbarService: SnackBarService,
        private _translateService: TranslateService,
        private _logService: AppLogService,
    ) {
    }

    private _getFiltersFromParams(params: any) {
        if (!params) {
            return {};
        }

        const configurator = FilterConfigurator.create([
            FilterItem.create('linkDiagnostic.diagnostic.slug', params.diagnosticMissingSlug),
            FilterItem.create('linkDiagnostic.diagnostic.parentSlug', params.diagnosticSlugParent),
            FilterItem.create('collection', params.collection),
            FilterItem.create('name', params.name),
            FilterItem.create('codify', params.codify),
            FilterItem.create('type', params.type),
            FilterItem.create('tags.name', params.tagName),
            FilterItem.create('validityFrame', params.validityFrame),
            FilterItem.create('workListUser.id', params.userId),
        ], [
            SortItem.create(params.revaluation),
            SortItem.create(params.datasetElementCount),
        ]);

        return configurator.toJSON();
    }

    private _deleteUnusedParams(params: any): void {
        delete params.startDate;
        delete params.endDate;
        delete params.customStartDateSubtractUnit;
        delete params.customEndDateSubtractUnit;
        delete params.medicalUnitId;
        delete params.diagnosticAutoPricing;
        delete params.revaluationCodificationLabel;
        delete params.diagnosticMissingSlug;
        delete params.diagnosticMissingCodificationLabel;
        delete params.diagnosticSlugParent;
        delete params.collection;
        delete params.name;
        delete params.codify;
        delete params.type;
        delete params.tagName;
        delete params.validityFrame;
        delete params.userId;
        delete params.revaluation;
        delete params.datasetElementCount;
    }

    async loadAllFiltersSearch(useCase: string, params: any) {
        const useCases = {
            'patient': 'healthPatient',
            'stay': 'healthStay,healthStayAtypia',
            'default;recurring': 'healthStay',
            'rehabilitation': 'healthStay',
            'worklist': 'healthStay',
            'external': 'healthStayExternal',
            'stayExternal': 'healthStayExternal',
            'atypia': 'healthStayAtypia',
        };

        params.type = useCases[useCase] ?? useCases.stay;

        const paramsWithFilters = {
            ...params,
            ...this._getFiltersFromParams(params),
        };
        if (params.include) {
            paramsWithFilters.include += (',' + params.include);
        }

        this._deleteUnusedParams(paramsWithFilters);

        try {
            return await this._filterSearchApiService
                .getAll(paramsWithFilters)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async loadFilterSearch(filterSearchId: number, params?: any) {
        try {
            return await this._filterSearchApiService
                .get(filterSearchId, params)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async createFilterSearch(data: any) {
        try {
            const res = await this._filterSearchApiService
                .create(data)
                .toPromise();
            this._snackbarService.success(this._translateService.instant('SUCCESS.CREATION'));
            return res;
        } catch (e) {
            throw e;
        }
    }

    async updateFilterSearch(filterSearchId: number, data: any) {
        try {
            const res = await this._filterSearchApiService
                .update(filterSearchId, data)
                .toPromise();
            this._snackbarService.success(this._translateService.instant('SUCCESS.CREATION'));
            return res;
        } catch (e) {
            throw e;
        }
    }

    async setWorklistUser(filterSearchId: number, data: any) {
        try {
            const res = await this._filterSearchApiService
                .setWorklistUserFilterSearch(filterSearchId, data)
                .toPromise();
            this._snackbarService.success(this._translateService.instant('SUCCESS.UPDATE'));
            return res;
        } catch (e) {
            throw e;
        }
    }

    async deleteWorklistUser(filterSearchId: number, data: any) {
        try {
            const res = await this._filterSearchApiService
                .deleteWorklistUserFilterSearch(filterSearchId, data)
                .toPromise();
            this._snackbarService.success(this._translateService.instant('SUCCESS.UPDATE'));
            return res;
        } catch (e) {
            throw e;
        }
    }

    async deleteFilterSearch(filterSearchId: number) {
        try {
            const res = await this._filterSearchApiService
                .delete(filterSearchId)
                .toPromise();
            this._snackbarService.success(this._translateService.instant('SUCCESS.DELETION'));
            return res;
        } catch (e) {
            throw e;
        }
    }

    async updateFilterSearchCodifyStatus(filterSearchId: number, codifyStatus: boolean) {
        try {
            const res = await this._filterSearchApiService
                .update(filterSearchId, {codify: codifyStatus})
                .toPromise();
            this._snackbarService.success(this._translateService.instant('SUCCESS.UPDATE'));
            return res;
        } catch (e) {
            throw e;
        }
    }

    async refreshScore(filterSearchId: number) {
        try {
            const res = await this._filterSearchDiagnosticService
                .refreshScore(filterSearchId)
                .toPromise();
            this._snackbarService.success(this._translateService.instant('SUCCESS.UPDATE'));
            return res;
        } catch (e) {
            throw e;
        }
    }

    openFilterSearchEditDialog(useCase: string, saveButton?: any, filterSearch?: any, params?: any) {
        // We pass the service as parameter because otherwise the MatDialogComponent
        // throws a dependency injection error (we inject the service that created the dialog)
        const dialogRef: MatDialogRef<FilterSearchEditDialogComponent> =
            this._matDialog.open(FilterSearchEditDialogComponent, {
                data: {
                    service: this,
                    useCase,
                    filterSearch,
                    params,
                },
                autoFocus: false,
                panelClass: 'filter-search-edit-dialog'
            });

        dialogRef
            .afterClosed()
            .subscribe(res => {
                // Remove focus highlight
                if (saveButton) {
                    saveButton._elementRef.nativeElement.classList.remove('cdk-program-focused');
                }
                if (res &&
                    res.action) {
                    this._broadcastService.send('initFirstSearch::update', {status: false});

                    switch (res.action) {
                        case 'creation':
                            this._logService.logInfo(`A new${useCase === ' patient ' ? 'patient' : ' '}filter search has been created`);
                            break;
                        case 'edition':
                            this._logService.logInfo(`A${useCase === ' patient ' ? 'patient' : ' '}filter search has been edited`);
                            break;
                        case 'update':
                            this._logService.logInfo(`A${useCase === ' patient ' ? 'patient' : ' '}filter search has been updated`);
                            break;
                        default:
                    }
                }
            });
    }

    getDefaultValidityFrame() {
        let startDate: any;
        let endDate: any;
        const currentLanguage = this._translateService.currentLang;
        if (currentLanguage === 'fr') {
            // From 01/02 of currentYear to last day of February of next year
            startDate = moment('01-03', 'DD-MM').toDate();
            endDate = moment('02', 'MM').add(1, 'year').endOf('month').toDate();
        } else {
            // Current calendar year
            startDate = moment().startOf('year').toDate();
            endDate = moment().endOf('year').toDate();
        }
        return {startDate, endDate};
    }

    openFilterSearchSubscribeDialog(filterSearch: FilterSearch,
                                    isUnsubscription: boolean,
                                    isEdition: boolean,
                                    isCurrentUserSubscribed: boolean = true) {
        this._matDialog.open(FilterSearchSubscribeDialogComponent, {
            data: {
                filterSearch,
                isUnsubscription,
                isEdition,
                isCurrentUserSubscribed
            },
            autoFocus: false,
            panelClass: 'filter-search-subscribe-dialog'
        });
    }

    updateLastFilterSearchViewed(
        uriParams: any,
        lastFilterSearchViewed: any,
        storageKeyName: string,
        isFirstSearch: boolean
    ) {
        if (lastFilterSearchViewed?.id) {
            const params = lastFilterSearchViewed.params;
            let loop = true;
            const forbiddenUriParams = [
                'codificationId',
                'dataSetId',
                'fromFilterSearchList',
                'page',
                'pageId',
                'perPage',
                'sort',
                'q',
                'include'
            ];
            const filteredParams = Object.keys(uriParams)
                .filter(key => !forbiddenUriParams.includes(key))
                .reduce((obj, key) => {
                    obj[key] = uriParams[key];
                    return obj;
                }, {});
            const tmpParams = {};
            for (const filterParam in filteredParams) {
                if (filteredParams[filterParam]) {
                    if (!isFirstSearch &&
                        loop && uriParams[filterParam] != params[filterParam]) {
                        loop = false;
                    }
                    tmpParams[filterParam] = uriParams[filterParam];
                }
            }

            if (isFirstSearch) {
                lastFilterSearchViewed.params = JSON.stringify(tmpParams);
                sessionStorage.setItem(storageKeyName, JSON.stringify(lastFilterSearchViewed));
            }
        }
    }

    editParamsAdvancedSearch(filterSearch: any) {
        if (filterSearch?.query?.args?.criteria?.includes(undefined)) {
            filterSearch.query.args.criteria = filterSearch.query.args.criteria.filter(item => item !== undefined);
        }
        if (filterSearch?.query?.args?.criteria) {
            const criteria = filterSearch.query.args.criteria;

            const hasBoolCriteria = criteria.some((criterion: any) => criterion && criterion.type === 'bool');
            if (!hasBoolCriteria) {
                criteria.push({
                    type: 'bool',
                    args: {
                        criteria: [],
                        operator: 'AND'

                    },
                });
            }
        }
        return filterSearch;
    }

    /**
     * Return a URI friendly sort parameters.
     *
     * @param sorts
     *
     * @private
     */
    private getUriSorts(sorts: string[]): string {
        return sorts.join(',');
    }

    /**
     * Return a URI friendly sort parameters.
     *
     * @param includes
     *
     * @private
     */
    private getUriIncludes(includes: string[]): string {
        return includes.join(',');
    }
}
