import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StayDetailDiagnosticPredictionElementTooltipComponent} from './tooltip/stay-detail-diagnostic-prediction-element-tooltip.component';
import {Subscription} from 'rxjs';
import {StateService} from '@uirouter/core';
import {DataSetElement} from '../../../shared/data-set-element.model';
import {DiagnosticNoteService} from '../../../../diagnostic/note/diagnostic-note.service';
import {BroadcastService} from '../../../../../core/services/broadcast.service';
import {ConfigurationService} from '../../../../configuration/configuration.service';
import {AppLogService} from '../../../../../core/app-log/app-log.service';
import {TranslationHelperService} from '../../../../../core/services/translation.helper.service';
import {StayDetailCodificationDiagnosticService} from '../../codification/diagnostic/stay-detail-codification-diagnostic.service';
import {CodificationLabelsModel} from '../../../shared/codification-labels.model';
import {SimplifiedScoreEnum} from '../../../../diagnostic/diagnostic.model';
import {StayDetailCodificationNearAutomationService} from '../../codification/near-automation/stay-detail-codification-near-automation.service';
import {SnackBarService} from '../../../../../core/services/snack-bar.service';
import {CodificationLabelEnum} from '../../../shared/codification-label-enum';

@Component({
    selector: 'ct-stay-detail-diagnostic-prediction-element',
    templateUrl: './stay-detail-diagnostic-prediction-element.component.html',
    styleUrls: ['./stay-detail-diagnostic-prediction-element.component.scss']
})
export class StayDetailDiagnosticPredictionElementComponent implements OnInit, OnDestroy, OnChanges {

    constructor(public $state: StateService,
                private _stayDetailCodificationDiagnosticService: StayDetailCodificationDiagnosticService,
                private _translateService: TranslateService,
                private _translationHelperService: TranslationHelperService,
                private _diagnosticNoteService: DiagnosticNoteService,
                private _broadcastService: BroadcastService,
                private _configurationService: ConfigurationService,
                private _logService: AppLogService,
                private _stayDetailCodificationNearAutomationService: StayDetailCodificationNearAutomationService,
                private _snackbarService: SnackBarService) { }
    private _subscriptions: Subscription[] = [];
    private _newPredictiveDiagnostics: any[];

    @Input() dataSetElement: DataSetElement;
    @Input() codificationLabels: CodificationLabelsModel;
    @Input() codificationLabelIds: number[];
    @Input() predictiveDiagnostic: any;
    @Input() diagnostics: any[];
    @Input() forceDisplay: any = {};
    @Input() groupByParent: any = {};
    @Input() isMixedDisplayActivated: boolean;
    @Input() impreciseDiagnostic: boolean;
    @Input() hasSimplifiedScore = true;
    @Input() canDisplayTooltip = true;
    @Input() isTypeRehabilitation: boolean;
    @Input() displayOnlySimplifiedScore = false;
    @Input() filterSearch: any;
    @Input() isFirst = false;
    @Output() removePredictiveDiagnostic = new EventEmitter<{predictiveDiagnosticId: number}>();

    isNearAutomationQualityControl: boolean;
    config: {
        dataSetElement,
        predictiveDiagnostic,
        codificationLabels,
        predictionElementComponent,
        referencialPrice,
        impreciseDiagnostic,
        isTypeRehabilitation,
        filterSearch
    };
    tooltipComponent: any;

    isGroupingActivated: boolean;
    isJustificationWithActsActivated: boolean;
    referencialPrice: number = null;
    canDisplaySimplifiedScore: boolean;
    isSeverityLevelActivated: boolean;

    protected readonly SimplifiedScoreEnum = SimplifiedScoreEnum;

    ngOnInit(): void {
        this.tooltipComponent = this.canDisplayTooltip ? StayDetailDiagnosticPredictionElementTooltipComponent : null;
        this._initConfig();
        this._initAvailableFeatures();
        if (this.isGroupingActivated) {
            this._calculateReferencialPrice();
        }

        this._subscribeToBroadcast();

        if (this.isFirst &&
                (this.predictiveDiagnostic.codificationLabel?.slug == CodificationLabelEnum.DP) || (this.predictiveDiagnostic.codificationLabel?.slug == CodificationLabelEnum.MP)) {
            this.showJustification();
        }

        this.isNearAutomationQualityControl = this.$state?.$current?.name === 'quality-control-near-automation';
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.codificationLabels) {
            return;
        }
        if (changes.forceDisplay?.currentValue &&
            (changes.codificationLabelIds?.currentValue || this.codificationLabelIds)) {
            this._updateCanDisplaySimplifiedScore();
        }
    }

    private _subscribeToBroadcast(): void {
        const sub = this._broadcastService.broadcastData
            .subscribe(res => {
                if (res.message === 'predictiveDiagnostic::updateReferencialPrice') {
                    if (res.data?.predictiveDiagnostics) {
                        this._newPredictiveDiagnostics = res.data.predictiveDiagnostics;
                        this._calculateReferencialPrice(true);
                    }
                }
            });
        this._subscriptions.push(sub);
    }

    private _initConfig(): void {
        this.isGroupingActivated = this._configurationService.getConfigurationContent('front', 'health.isGroupingActivated');
        this.isJustificationWithActsActivated = this._configurationService.getConfigurationContent('front', 'diagnostic.diagnosticAct');
        this.config = {
            dataSetElement: this.dataSetElement,
            predictiveDiagnostic: this.predictiveDiagnostic,
            codificationLabels: this.codificationLabels,
            predictionElementComponent: this,
            referencialPrice: this.referencialPrice,
            impreciseDiagnostic: this.impreciseDiagnostic,
            filterSearch: this.filterSearch,
            isTypeRehabilitation: this.isTypeRehabilitation,
        };
    }

    private _initAvailableFeatures(): void {
        this.isSeverityLevelActivated = this._translationHelperService.isFeatureAvailable('diagnosticSeverityLevel') && !this.codificationLabelIds.includes(-1);
    }

    private _updateCanDisplaySimplifiedScore(): void {
        if (this.codificationLabelIds) {
            const hasCodificationLabelIdMatches = this.codificationLabelIds.some(id => [this.codificationLabels.DP, this.codificationLabels.DR, this.codificationLabels.FP, this.codificationLabels.MP, this.codificationLabels.AE, this.codificationLabels.CCAM, this.codificationLabels.CSARR].includes(id));
            this.canDisplaySimplifiedScore = this.getCanDisplaySimplifiedScore(hasCodificationLabelIdMatches);
        }
    }

    private getCanDisplaySimplifiedScore(hasCodificationLabelIdMatches: boolean) {
        return !this.impreciseDiagnostic
            && this.hasSimplifiedScore
            && (
                hasCodificationLabelIdMatches
                || (
                    this.codificationLabelIds.includes(this.codificationLabels.DA)
                    && !this.codificationLabelIds.includes(-1)
                )
            );
    }

    private _calculateReferencialPrice(isUpdate = false): void {
        if (this.predictiveDiagnostic &&
            this.dataSetElement?.dataSetContent) {
            if (!isUpdate) {
                this.referencialPrice = this.predictiveDiagnostic.pricing ? this.predictiveDiagnostic.pricing - this.dataSetElement.dataSetContent.pricing : null;
            } else if (this._newPredictiveDiagnostics) {
                const diagnosticToFind = this._newPredictiveDiagnostics.find(el => el.diagnosticId === this.predictiveDiagnostic.diagnosticId);
                if (diagnosticToFind) {
                    this.referencialPrice = diagnosticToFind.pricing ? diagnosticToFind.pricing - this.dataSetElement.dataSetContent.pricing : null;
                }
            }
        }
    }

    /**
     * To know if a predictiveDiagnostic is in the selected diagnostics
     */
    getDiagnosticFromCodification(diagnostic: any): any {
        if (diagnostic) {
            const codedDiagnostic = this.diagnostics.find(el => el?.diagnostic?.id === diagnostic.diagnostic?.id);
            return codedDiagnostic ?? null;
        }
        return null;
    }

    getDiagnosticLabelColor(
        diagnostic: any,
        byLevel: boolean = false,
        forPredictiveElementTooltip = false
    ): string {
        let selectedDiagnostic = this.getDiagnosticFromCodification(diagnostic);

        if (selectedDiagnostic || forPredictiveElementTooltip) {
            // If it's for forPredictiveElementTooltip and selectedDiagnostic is null then we use the
            // diagnostic passed in param to know the level for the color
            selectedDiagnostic = selectedDiagnostic || diagnostic;
            return `md-${this._stayDetailCodificationDiagnosticService
                .getDiagnosticLabelColor(selectedDiagnostic, byLevel, this.isSeverityLevelActivated)}${!forPredictiveElementTooltip ? ' font-weight-700' : ''}`;
        }

        return '';
    }

    getGroupingClass(): string {
        if (this.referencialPrice !== null) {
            return this.referencialPrice <= 0 ? 'md-orange' : 'md-green';
        }
        return '';
    }

    getSeverityLevelTooltip(): string {
        // Severity level only for DA
        if (this.codificationLabelIds?.includes(this.codificationLabels?.DA) &&
            this.predictiveDiagnostic?.diagnostic?.diagnosticLevel) {
            const isPredictionCoded = this.getDiagnosticFromCodification(this.predictiveDiagnostic);
            if (!isPredictionCoded) {
                return `${this._translateService.instant('STUFF.LEVEL')}. ${this.predictiveDiagnostic.diagnostic.diagnosticLevel.level}`;
            }
        }
        return '';
    }

    getSimplifiedScoreTooltip(): string {
        return this.isJustificationWithActsActivated ? this._translateService.instant('TOOLTIP.SHOW_JUSTIFICATION') :
            this._diagnosticNoteService.getSimplifiedScoreText(this.predictiveDiagnostic?.simplifiedScore);
    }

    canDisplayLevel(): boolean {
        if (this.isSeverityLevelActivated &&
            this.predictiveDiagnostic?.diagnostic?.diagnosticLevel && this.codificationLabels) {
            return !this.canDisplayGrouping() &&
                this.predictiveDiagnostic.diagnostic.parentId !== null &&
                this.codificationLabelIds.some(id => [this.codificationLabels.DP, this.codificationLabels.DA].includes(id));
        }
        return false;
    }

    canDisplayGrouping(): boolean {
        return !!(this.isGroupingActivated &&
            this.referencialPrice !== null && this.codificationLabels &&
            this.codificationLabelIds.some(id => [this.codificationLabels.DP, this.codificationLabels.DA, this.codificationLabels.FP, this.codificationLabels.MP, this.codificationLabels.AE].includes(id)));
    }

    openDiagnosticNoteDisplayDialog(event: any): void {
        if (this.predictiveDiagnostic?.diagnostic?.diagnosticNote?.length) {
            if (event) {
                event.stopPropagation();
            }
            this._diagnosticNoteService
                .openDiagnosticNoteDisplayDialog(this.predictiveDiagnostic.diagnostic.id);
            this._logService.logInfo('Looking at diagnostic note');
        }
    }

    sendPredictiveDiagnosticViaBroadcast(event: any): void {
        if (event) {
            event.stopPropagation();
        }
        this._broadcastService.send('stayDetailDiagnosticPredictionElement::clickOnDiagnostic', {predictiveDiagnostic: this.predictiveDiagnostic, dataSetElementId: this.dataSetElement?.id});
    }

    showJustification(): void {
        this._broadcastService.send('stayDetailCodificationNearAutomation::showDiagnosticJustification',
            {diagnostic: this.predictiveDiagnostic.diagnostic, dataSetElement: this.dataSetElement, stay: this.dataSetElement.dataSetContent});
    }

    async validateAutomation(selectedPredictiveDiagnostic: any): Promise<void> {
        try {
            const diagnostic = selectedPredictiveDiagnostic.diagnostic;

            if (diagnostic) {
                const diagnostics = [];

                diagnostics.push({...diagnostic, codificationLabelId: selectedPredictiveDiagnostic.codificationLabelId });

                this._broadcastService.send('diagnostic::addDiagnostic', {
                    success: true,
                    reload: true,
                    diagnosticId: selectedPredictiveDiagnostic.diagnostic.id,
                    dataSetElementId: this.dataSetElement.id,
                    codificationLabelId: selectedPredictiveDiagnostic.codificationLabel.id
                });

                await this._stayDetailCodificationNearAutomationService.validateAutomation(this.dataSetElement, diagnostics, true);
                this.predictiveDiagnostic.alreadyCoded = true;

                this._broadcastService.send('stayDetailDiagnosticPredictionElement::clickOnDiagnostic', {
                    predictiveDiagnostic: selectedPredictiveDiagnostic,
                });

                const successMessage = this._translateService.instant('SUCCESS.ADD_DIAGNOSTIC');

                this._snackbarService.success(successMessage);
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async cancelAutomation(predictiveDiagnosticId: number): Promise<void> {
        try {
            await this._stayDetailCodificationNearAutomationService.cancelAutomation(predictiveDiagnosticId);
            this.removePredictiveDiagnostic.emit({
                predictiveDiagnosticId
            });
            const successMessage = this._translateService.instant('SUCCESS.DELETION');
            this._snackbarService.success(successMessage);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    ngOnDestroy(): void {
        if (this._subscriptions?.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }
}
