import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DataSetElement} from '../../../../shared/data-set-element.model';
import {CodificationLabelsModel} from '../../../../shared/codification-labels.model';

@Component({
    selector: 'ct-data-set-element-stay-diagnostic-add-dialog',
    templateUrl: './stay-detail-codification-diagnostic-add-dialog.component.html',
    styleUrls: ['./stay-detail-codification-diagnostic-add-dialog.component.scss']
})
export class StayDetailCodificationDiagnosticAddDialogComponent implements OnInit {
    dataSetElementDiagnostics: any[] = [];
    predictiveDiagnostics: any[] = [];
    dataSetElement: DataSetElement;
    codificationLabels: CodificationLabelsModel;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<StayDetailCodificationDiagnosticAddDialogComponent>) {
    }

    ngOnInit() {
        this.dataSetElementDiagnostics = this.data.dataSetElementDiagnostics;
        this.predictiveDiagnostics = this.data.predictiveDiagnostics;
        this.dataSetElement = this.data.dataSetElement;
        this.codificationLabels = this.data.codificationLabels;
    }

    openAddActDialog() {
        this.dialogRef.close({openAddActDialog: true});
    }
}
