import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {UIRouterModule} from '@uirouter/angular';
import {FormsModule} from '@angular/forms';
import {StayDetailComponent} from './stay-detail.component';
import {HeaderModule} from '../../../shared/components/header/header.module';
import {StayDetailCodificationModule} from './codification/stay-detail-codification.module';
import { StayDetailCommentModule } from './comment/stay-detail-comment.module';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {StayDetailHeaderModule} from './header/stay-detail-header.module';
import {StayDetailInfoModule} from './info/stay-detail-info.module';
import {StayDetailFilterSearchModule} from './filter-search/stay-detail-filter-search.module';
import {StayDetailDiagnosticPredictionModule} from './diagnostic-prediction/stay-detail-diagnostic-prediction.module';
import {StayDetailDocumentModule} from './document/stay-detail-document.module';
import {ExpansionPanelModule} from '../../../shared/components/expansion-panel/expansion-panel.module';
import {MatDividerModule} from '@angular/material/divider';
import {MatMenuModule} from '@angular/material/menu';
import {MatExpansionModule} from '@angular/material/expansion';
import {stayDetailState} from './stay-detail.state';
import {StayDetailCollapseButtonModule} from './shared/stay-detail-collapse-button/stay-detail-collapse-button.module';
import {StayDetailImpreciseDiagnosticModule} from './imprecise-diagnostic/stay-detail-imprecise-diagnostic.module';
import {StayDetailChronicDiagnosticModule} from './chronic-diagnostic/stay-detail-chronic-diagnostic.module';
import {FeatureToggleDirectiveModule} from '../../../shared/directives/feature-toggle/feature-toggle.directive.module';
import {StayDetailCodificationHistoryModule} from './codification/history/stay-detail-codification-history.module';
import {FieldDisplayModule} from '../../../shared/components/field-display/field-display.module';
import {TabListModule} from '../../../shared/components/tab-list/tab-list.module';
import {PeriodSelectModule} from './period-select/period-select.module';
import {DependenciesModule} from './dependencies/dependencies.module';
import { StayDetailQualifactControlComponent } from './qualifact-control/stay-detail-qualifact-control.component';
import { StayDetailElementQualifactControlComponent } from './qualifact-control/stay-detail-element-qualifact-control/stay-detail-element-qualifact-control.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {MatChipsModule} from '@angular/material/chips';
import {PipesModule} from '../../../shared/pipes/pipes.module';

@NgModule({
    declarations: [
        StayDetailComponent,
        StayDetailQualifactControlComponent,
        StayDetailElementQualifactControlComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        UIRouterModule.forChild({states: stayDetailState}),
        TranslateModule.forChild(),
        HeaderModule,
        ExpansionPanelModule,
        StayDetailHeaderModule,
        StayDetailCodificationModule,
        StayDetailCommentModule,
        StayDetailInfoModule,
        StayDetailFilterSearchModule,
        StayDetailImpreciseDiagnosticModule,
        StayDetailDiagnosticPredictionModule,
        StayDetailChronicDiagnosticModule,
        StayDetailDocumentModule,
        StayDetailCollapseButtonModule,
        StayDetailCodificationHistoryModule,
        MatDividerModule,
        MatMenuModule,
        MatExpansionModule,
        MatIconModule,
        MatCardModule,
        MatButtonModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        FeatureToggleDirectiveModule,
        FieldDisplayModule,
        TabListModule,
        PeriodSelectModule,
        DependenciesModule,
        NgxDatatableModule,
        MatChipsModule,
        PipesModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        StayDetailComponent
    ]
})
export class StayDetailModule {}
