import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {debounceTime, map, startWith, switchMap} from 'rxjs/operators';
import {ActService} from '../../../core/services/act.service';

@Component({
    selector: 'ct-act-search',
    templateUrl: './act-search.component.html',
    styleUrls: ['./act-search.component.scss']
})
export class ActSearchComponent implements OnInit {
    @Input() param: any;
    @Input() useCase: string|string[];
    @Input() required: boolean;
    @Input() returnObject: boolean;
    @Output() paramChange: EventEmitter<any> = new EventEmitter<any>();

    selectedActs: any[] = [];
    searchedActs: Observable<any>;
    formControl = new FormControl();
    isLoading: boolean;

    @ViewChild('actInput', {static: true}) actInput: ElementRef<HTMLInputElement>;

    constructor(private _actService: ActService) {
    }

    ngOnInit() {
        // To initialize the input when we load with uriParams
        if (this.param) {
            this.selectedActs = this.param
                .map(slug => {
                    return {
                        slug
                    };
                });
        }
        this.searchedActs = this.formControl.valueChanges
            .pipe(startWith(''),
                debounceTime(200),
                switchMap(value => {
                    this.isLoading = true;
                    if (value && value.length > 0) {
                        return this._search(value);
                    } else {
                        this.isLoading = false;
                        return EMPTY;
                    }
                }));
    }

    private _search(query: string) {
        return (this._actService
            .loadHealthActsWithES(query, this.useCase))
            .pipe(map(res => {
                this.isLoading = false;
                return res;
            }));
    }

    getTooltipText(act: any, i?: any) {
        if (typeof i !== 'undefined') {
            const el = document.getElementById(i);
            if (el &&
                el.children[0]) {
                const text = act.name;
                return el.children[0].scrollWidth > el.children[0].clientWidth ? text : '';
            } else {
                return '';
            }
        } else {
            return act.name;
        }
    }

    /**
     * Add the act to the chips list (not if already added)
     * @param event
     */
    selectAct(event: any) {
        if (event) {
            const act = this.selectedActs.find(a => a.slug === event.option.value.slug);
            if (!act) {
                this.selectedActs.push(event.option.value);
                if (!this.returnObject) {
                    // We update the service params
                    this.paramChange
                        .emit(this.selectedActs.map(selectedAct => selectedAct.slug));
                } else {
                    this.paramChange
                        .emit(this.selectedActs);
                }
            }
            this.actInput.nativeElement.value = '';
            this.formControl.setValue(null);
        }
    }

    removeChip(actToRemove: any) {
        if (actToRemove) {
            const newSelectedActs = this.selectedActs.filter(act => act.slug !== actToRemove.slug);
            this.selectedActs = newSelectedActs;
            if (!this.returnObject) {
                // We update the service params
                this.paramChange
                    .emit(newSelectedActs.map(act => act.slug));
            } else {
                this.paramChange
                    .emit(newSelectedActs);
            }
        }
    }

}
