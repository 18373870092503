import {Injectable} from '@angular/core';
import {DataSetCodificationDataSetElementApiService} from '../../../core/api-services/data-set/codification/data-set-element/data-set-codification-data-set-element.api.service';
import {SnackBarService} from '../../../core/services/snack-bar.service';
import {TranslateService} from '@ngx-translate/core';
import {DataSetElement, StatusName} from './data-set-element.model';
import {DataSetElementApiService} from '../../../core/api-services/data-set-element/data-set-element.api.service';
import {DataSetElementCodificationDiagnosticApiService} from '../../../core/api-services/data-set-element/codification/diagnostic/data-set-element-codification-diagnostic.api.service';
import {BroadcastService} from '../../../core/services/broadcast.service';
import {ConfigurationService} from '../../configuration/configuration.service';
import {AppLogService} from '../../../core/app-log/app-log.service';
import {DashboardService} from '../../dashboard/dashboard.service';
import {StateService} from '@uirouter/core';
import {DateHelperService} from '../../../core/services/date.helper.service';
import {DiagnosticService} from '../../diagnostic/diagnostic.service';
import {map} from 'rxjs/operators';
import {DataSetElementCodificationActApiService} from '../../../core/api-services/data-set-element/codification/act/data-set-element-codification-act.api.service';
import {SearchEngineAdvancedService} from '../../search-engine/advanced/search-engine-advanced.service';

@Injectable({
    providedIn: 'root'
})

export class DataSetElementService {
    private _isGroupingActivated: boolean;
    public dataSetElement: DataSetElement;

    constructor(private _state: StateService,
                private _dataSetCodificationDataSetElementApiService: DataSetCodificationDataSetElementApiService,
                private _dataSetElementCodificationDiagnosticApiService: DataSetElementCodificationDiagnosticApiService,
                private _dataSetElementCodificationActApiService: DataSetElementCodificationActApiService,
                private _dataSetElementApiService: DataSetElementApiService,
                private _snackbarService: SnackBarService,
                private _translateService: TranslateService,
                private _broadcastService: BroadcastService,
                private _dateHelperService: DateHelperService,
                private _diagnosticService: DiagnosticService,
                private _configurationService: ConfigurationService,
                private _logService: AppLogService,
                private _dashboardService: DashboardService,
                private searchEngineAdvancedService: SearchEngineAdvancedService) {
        this._updateEnv();
        this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'configuration::update':
                        this._updateEnv();
                        break;
                    default:
                }
            });
    }

    private _updateEnv() {
        this._isGroupingActivated = this._configurationService.getConfigurationContent('front', 'health.isGroupingActivated');
    }

    private _sendLogToLogStash(status: number) {
        switch (status) {
            case StatusName.PRICING_IMPACT:
                this._logService.logInfo('Stay status switched to UPDATED-WITH-TARIFICATION-IMPACT');
                break;
            case StatusName.VERIFIED:
                this._logService.logInfo('Stay status switched to VERIFIED');
                break;
            case StatusName.WAITING:
                this._logService.logInfo('Stay status switched to WAITING');
                break;
            case StatusName.PRIMO_CODAGE:
                this._logService.logInfo('Stay status switched to PRIMO-CODAGE');
            break;
            case StatusName.NO_PRICING_IMPACT:
                this._logService.logInfo('Stay status switched to UPDATED-WITHOUT-TARIFICATION-IMPACT');
                break;
            default:
        }
    }

    isType(dataSetElement: any, type: string) {
        return !!(dataSetElement && dataSetElement.dataSetContent && type && dataSetElement.dataSetContent.type === type);
    }

    hasImpreciseDate(dataSetElement: DataSetElement) {
        if (dataSetElement) {
            const startDateTime = this._dateHelperService.getTime(dataSetElement.dataSetContent.startDate, true);
            const endDateTime = this._dateHelperService.getTime(dataSetElement.dataSetContent.endDate, true);
            return !startDateTime || !endDateTime || startDateTime === '00:00:00' || endDateTime === '00:00:00';
        }
        return false;
    }

    getStatusText(dataSetElement: DataSetElement) {
        if (dataSetElement &&
            dataSetElement.codificationDataSetElementStatus) {
            switch (dataSetElement.codificationDataSetElementStatus.status) {
                case StatusName.PRICING_IMPACT:
                    return `${this._translateService.instant('STAY.STATUS_PRICING_IMPACT')}`;
                case StatusName.VERIFIED:
                    return `${this._translateService.instant('STAY.STATUS_VERIFIED')}`;
                case StatusName.WAITING:
                    return `${this._translateService.instant('STAY.STATUS_WAITING')}`;
                case StatusName.PRIMO_CODAGE:
                    return `${this._translateService.instant('STAY.STATUS_PRIMO_CODAGE')}`;
                case StatusName.NO_PRICING_IMPACT:
                    return `${this._translateService.instant('STAY.STATUS_NO_PRICING_IMPACT')}`;
                default:
                    return `${this._translateService.instant('STAY.STATUS_UNCHECKED')}`;
            }
        }
        return '';
    }
    getStatusDescription(id: number) {
        switch (id) {
            case StatusName.PRICING_IMPACT:
                return `${this._translateService.instant('TOOLTIP.STATUS_PRICING_IMPACT')}`;
            case StatusName.VERIFIED:
                return `${this._translateService.instant('TOOLTIP.STATUS_VERIFIED')}`;
            case StatusName.WAITING:
                return `${this._translateService.instant('TOOLTIP.STATUS_WAITING')}`;
            case StatusName.PRIMO_CODAGE:
                return `${this._translateService.instant('TOOLTIP.STATUS_PRIMO_CODAGE')}`;
            case StatusName.NO_PRICING_IMPACT:
                return `${this._translateService.instant('TOOLTIP.STATUS_NO_PRICING_IMPACT')}`;
            default:
                return `${this._translateService.instant('TOOLTIP.STATUS_UNCHECKED')}`;
        }
    }

    getStatusColor(statusName) {
            switch (statusName) {
                case StatusName.UNCHECKED:
                    return 'md-grey-100';
                case StatusName.PRICING_IMPACT:
                    return 'md-green-100';
                case StatusName.VERIFIED:
                    return 'md-blue-100';
                case StatusName.WAITING:
                    return 'md-yellow-300';
                case StatusName.PRIMO_CODAGE:
                    return 'md-green-100';
                case StatusName.NO_PRICING_IMPACT:
                    return 'md-green-100';
                default:
                    return 'md-grey-100';
            }
    }

    async loadDataSetElement(dataSetElementId: number, codificationId: number) {
        try {
            const params = {
                access: this._state.params.access || '',
                fields: 'diagnostic,diagnosticLabel,diagnosticLevel,diagnosticNote,patient,act,doctor,' +
                    'codificationStatus,parentAct,ghm,gme,validatedDependencyCollection,medicalUnit,establishment,healthMovementEntrance,healthMovementExit,user',
                codificationId
            };
            if (this._isGroupingActivated) {
                params['pricing'] = true;
            }
            this.dataSetElement = await this._dataSetElementApiService
                .get(dataSetElementId, params)
                .toPromise();

            return this.dataSetElement;
        } catch (e) {
            throw e;
        }
    }

    async loadDataSetElementParent(dataSetElementId: number, codificationId: number) {
        try {
            const params = {
                access: 'dataSetElementId',
                fields: 'children,medicalUnit,act,diagnostic,user,qualifactControls',
                codificationId
            };
            return await this._dataSetElementApiService
                .get(dataSetElementId, params)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }
    async setFilterSearchValidity(dataSetElementId: number, filterSearchId: number, status: number) {
        try {
            return await this._dataSetElementApiService
                .editFilterSearchStatus(dataSetElementId, filterSearchId, status)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }
    async loadDiagnostics(dataSetElementId: number, mainDiagnosis: any[]) {
        try {
            const params = {
                mainDiagnosis
            };
            return this._dataSetElementApiService
                .loadDiagnostics(dataSetElementId, params)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private _hasNoChildren(rootPredictiveDiagnosticId: number, predictiveDiagnostics: any[]): boolean {
        return predictiveDiagnostics ?
            predictiveDiagnostics.filter(el => el.diagnostic?.parentId === rootPredictiveDiagnosticId).length === 0 : false;
    }

    private _getChildCodeCodificationLabelId(rootCodeCodificationLabelId: number): number {
        if (rootCodeCodificationLabelId === 4) {
            // If it's a DP_ROOT then return DP
            return 1;
        } else if (rootCodeCodificationLabelId === 5) {
            // If it's a DA_ROOT then return DA
            return 3;
        }
        return rootCodeCodificationLabelId;
    }

    /**
     * Map children param to prediction obj
     * @param children
     * @param rootCodesCodificationLabelIds obj that contains the codificationLabelIds (array) of each parentId for whom we load the children
     * @private
     */
    private _mapChildrenToPredictiveDiagnostics(children: any[], rootCodesCodificationLabelIds: any): any[] {
        if (children) {
            const newChildren = children.map(child => {
                let codificationLabelId = null;
                if (rootCodesCodificationLabelIds[children[0].id]) {
                    const rootCodeCodificationLabelId = rootCodesCodificationLabelIds[children[0].id][0]; // children[0] is the parent
                    codificationLabelId = this._getChildCodeCodificationLabelId(rootCodeCodificationLabelId);
                }
                return {
                    diagnostic: {
                        ...child,
                        codificationLabelId,
                        diagnosticLevel: {level: child.level}
                    },
                    diagnosticId: child.id,
                    score: 1,
                    simplifiedScore: 4, // Does not exist but on purpose because we don't want to display a simplifiedScore icon and set it to null would display it
                    pricing: null,
                    codificationLabelId
                };
            });
            // We delete the codificationLabelId we just used so that if there is another root code with use the next codificationLabelId
            rootCodesCodificationLabelIds[children[0].id]?.splice(0, 1);
            newChildren.splice(0, 1);
            return newChildren;
        }
        return [];
    }

    private async _simulateDiagnosticsGrouping(dataSetElement: DataSetElement,
                                               diagnostics: any[]): Promise<void> {
        try {
            const body = this._diagnosticService.buildSimulateGroupingBody(dataSetElement, diagnostics);
            const res = await this._diagnosticService
                .simulateGrouping(body);
            if (res) {
                this._diagnosticService.updateDiagnosticsPricingAndGhm(res, dataSetElement.dataSetElementDiagnosticPredictive);
                this._broadcastService.send('predictiveDiagnostic::updateReferencialPrice');
            }
        } catch (e) {
            console.error(e);
        }
    }

    private async _loadRootPredictiveDiagnosticsChildren(predictiveDiagnostics: any[],
                                                         dataSetElement: DataSetElement): Promise<void> {
        try {
            if (predictiveDiagnostics) {
                const rootCodesCodificationLabelIds = {};
                const promises: Promise<any>[] = [];
                predictiveDiagnostics.forEach(el => {
                    // codificationLabelId === 7 is HEALTH_SEVERITY
                    if (el.codificationLabelId !== 7 &&
                        el.diagnostic?.parentId === null &&
                        el.diagnostic?.slug &&
                        this._hasNoChildren(el.diagnostic?.id, predictiveDiagnostics)) {
                        if (!rootCodesCodificationLabelIds[el.diagnostic.id]) {
                            rootCodesCodificationLabelIds[el.diagnostic.id] = [];
                        }
                        rootCodesCodificationLabelIds[el.diagnostic.id].push(el.codificationLabelId);
                        promises.push(this._diagnosticService.loadDiagnosticsWithES$(el.diagnostic.slug).pipe(map(obj => obj.data)).toPromise());
                    }
                });
                const arrayOfChildren: any[][] = await Promise.all(promises);
                const newChildren = [].concat(...arrayOfChildren.map(children => this._mapChildrenToPredictiveDiagnostics(children, rootCodesCodificationLabelIds)));
                predictiveDiagnostics.push(...newChildren);
                await this._simulateDiagnosticsGrouping(dataSetElement, newChildren);
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async loadPredictiveDiagnostics(dataSetElement: DataSetElement,
                                    codificationLabelIds: number[],
                                    switchGroupByParent: boolean = false) {
        try {
            const params = {
                fields: 'diagnostic,diagnosticLabel,diagnosticLevel,diagnosticNote,ghs,gmt',
                codificationLabelId: codificationLabelIds.join(',')
            };
            if (this._isGroupingActivated) {
                params['pricing'] = true;
            }
            const predictiveDiagnostics = await this._dataSetElementApiService
                .loadPredictiveDiagnostics(dataSetElement.id, params)
                .toPromise();
            const isMixedDisplayActivated = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.isMixedDisplayActivated');
            const canDisplayDiagnosticRoot = this._configurationService.getConfigurationContent('front', 'diagnostic.diagnosticRoot');
            const aDiagTypeDisplay = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.associateDiagnostic.typeDisplay');
            const pDiagTypeDisplay = this._configurationService.getConfigurationContent('front', 'diagnostic.prediction.primaryDiagnostic.typeDisplay');
            if (switchGroupByParent ||
                isMixedDisplayActivated ||
                (canDisplayDiagnosticRoot && (aDiagTypeDisplay === 'diagnosticRoot' || pDiagTypeDisplay === 'diagnosticRoot'))) {
                await this._loadRootPredictiveDiagnosticsChildren(predictiveDiagnostics, dataSetElement);
            }
            return this._diagnosticService.filterByYearOfValidity(dataSetElement, predictiveDiagnostics, false);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async loadFilterSearchRules(dataSetElementId: number, params?: any) {
        try {
            if (!params) {
                params = {};
                let collection = 'shared,own,automatable,autoSupervised';
                if (this._configurationService.getConfigurationContent('front', 'filterSearch.search.isCothActivated')) {
                    collection += ',default';
                }
                if (this._configurationService.getConfigurationContent('front', 'filterSearch.search.isCommonActivated')) {
                    collection += ',common';
                }
                params['filter[collection]'] = collection;
                params.include = 'tags';
            }
            return this._dataSetElementApiService
                .loadFilterSearchRules(dataSetElementId, params)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    private _updateCodificationDataSetElementStatus(dataSetElement: DataSetElement, status: number, loopStatus?: number) {
        // Update dataSetElement object reference status
        if (dataSetElement.codificationDataSetElementStatus) {
            dataSetElement.codificationDataSetElementStatus.status = status;
            if (loopStatus) {
                dataSetElement.codificationDataSetElementStatus.loopStatus = loopStatus;
            }

        } else {
            dataSetElement.codificationDataSetElementStatus = {status: status};
            if (loopStatus) {
                dataSetElement.codificationDataSetElementStatus = {loopStatus: loopStatus};
            }
        }

        // Update dataSetElement children object reference status
        if (dataSetElement.children &&
            dataSetElement.children.length > 0) {
            dataSetElement.children.forEach(child => {
                if (child.codificationDataSetElementStatus) {
                    child.codificationDataSetElementStatus.status = status;
                    if (loopStatus) {
                        child.codificationDataSetElementStatus.loopStatus = loopStatus;
                    }
                } else {
                    child.codificationDataSetElementStatus = {status: status};
                    if (loopStatus) {
                        child.codificationDataSetElementStatus = {loopStatus: loopStatus};
                    }
                }
            });
        }
    }

    private _sendEventForDashboard(dataSetElement: DataSetElement, newStatus: number) {
        const eventData = {
            stayId: dataSetElement.dataSetContent.stayId,
            endDate: dataSetElement.dataSetContent.endDate,
            type: dataSetElement.dataSetContent.type,
            oldStatus: dataSetElement.codificationDataSetElementStatus.status,
            newStatus
        };
        this._dashboardService.sendEvent('stay_update_status', eventData);
    }

    async removeDataSetElementAiCodedStatus(dataSetElement: DataSetElement) {
        try {
            if (dataSetElement) {
                dataSetElement.codificationDataSetElementStatus.statusLoading = true;
                const dataSetElementId = dataSetElement.parentId ? dataSetElement.parentId : dataSetElement.id;
                const data = {
                    aiCodedStatus: 0,
                    status: dataSetElement.codificationDataSetElementStatus.status // Need to send status because without it the request returns an error
                };
                await this._dataSetCodificationDataSetElementApiService
                    .update(1, 1, dataSetElementId, data)
                    .toPromise();
                dataSetElement.codificationDataSetElementStatus.aiCodedStatus = 0;
                dataSetElement.codificationDataSetElementStatus.statusLoading = false;
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async changeDataSetElementStatus(dataSetElement: DataSetElement, status: number, isRum = false, canDisplaySuccessMessage = true, loopStatus?: number) {
        try {
            if (dataSetElement) {
                dataSetElement.codificationDataSetElementStatus.statusLoading = true;
                const dataSetElementId = !isRum && dataSetElement.parentId ? dataSetElement.parentId : dataSetElement.id;
                let data: any;
                if (!loopStatus) {
                     data = await this._dataSetCodificationDataSetElementApiService
                        .update(1, 1, dataSetElementId, {status})
                        .toPromise();
                } else {
                     data = await this._dataSetCodificationDataSetElementApiService
                        .update(1, 1, dataSetElementId, {status, loopStatus})
                        .toPromise();
                }
                if (data) {
                    this._sendEventForDashboard(dataSetElement, status);
                    this._updateCodificationDataSetElementStatus(dataSetElement, status, loopStatus);
                    this._sendLogToLogStash(status);
                    if (canDisplaySuccessMessage) {
                        this._snackbarService.success(this._translateService.instant('SUCCESS.UPDATE'));
                    }
                }
                dataSetElement.codificationDataSetElementStatus.statusLoading = false;
                return data;
            }
        } catch (e) {
            dataSetElement.codificationDataSetElementStatus.statusLoading = false;
            throw e;
        }
    }

    async isReady(dataSetElementId: number, params?: any) {
        try {
            return this._dataSetElementApiService
                .isReady(dataSetElementId, params)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    /**
     * Add diagnostic to dataSetElement
     * @param dataSetElement
     * @param codificationId
     * @param data
     */
    async addDiagnostic(dataSetElement: DataSetElement, codificationId: number, data: any) {
        try {
            const res: any[] = await this._dataSetElementCodificationDiagnosticApiService
                .addDiagnostic(dataSetElement.id, codificationId, data)
                .toPromise();
            const newDiagnosticObj = res.find(diagnostic => diagnostic.diagnostic.id === data.diagnosticId);
            if (!dataSetElement.dataSetElementDiagnostic) {
                dataSetElement.dataSetElementDiagnostic = [];
            }
            if (newDiagnosticObj) {
                newDiagnosticObj.new = true;
                dataSetElement.dataSetElementDiagnostic.length = 0;
                [].push.apply(dataSetElement.dataSetElementDiagnostic, res);
                // To be able to display in snackbar
                // diagnostic that has been downgraded
                let downgradedDiagnostic = null;
                for (let i = 0; i < dataSetElement.dataSetElementDiagnostic.length; i++) {
                    const el = dataSetElement.dataSetElementDiagnostic[i];
                    if (el.downgradedDiagnostic &&
                        el.downgradedDiagnostic === true) {
                        downgradedDiagnostic = el;
                        break;
                    }
                }
                if (downgradedDiagnostic) {
                    return downgradedDiagnostic;
                } else {
                    return dataSetElement.dataSetElementDiagnostic;
                }
            } else if ( res.find(element => element.reason === 'webpims')) {
                return res;
            } else {
                return null;
            }
        } catch (e) {
            throw e;
        }
    }

    /**
     * Remove diagnostic to dataSetElement
     * @param dataSetElement
     * @param codificationId
     * @param diagnosticId
     * @param launchGrouping
     */
    async removeDiagnostic(dataSetElement: DataSetElement,
                           codificationId: number,
                           diagnosticId: number,
                           launchGrouping = true) {
        try {
             await this.editFilterSearchStatus(diagnosticId, dataSetElement);
            const oldDiagnosticObj = await this._dataSetElementCodificationDiagnosticApiService
                .removeDiagnostic(dataSetElement.id, codificationId, diagnosticId, {withPostProcess: launchGrouping})
                .toPromise();
            const position = dataSetElement
                .dataSetElementDiagnostic
                .findIndex(diagnosticObj => diagnosticObj.diagnostic.id === oldDiagnosticObj.diagnostic.id);
            if (position !== -1) {
                dataSetElement.dataSetElementDiagnostic.splice(position, 1);
                if (!dataSetElement.dataSetElementDiagnostic.some(diagnostic =>
                    diagnostic.codificationLabelId === 1
                )) {
                    dataSetElement.excludingDiagnostics = [];
                }
                return dataSetElement;
            } else {
                return dataSetElement;
            }
        } catch (e) {
            throw e;
        }
    }

    async editFilterSearchStatus(diagId: number, dataSetElement: DataSetElement): Promise<void> {
        const filterSearch = dataSetElement?.filtersSearch?.filter(el => el.validityStatus == 1);
        let diagnosisPresent = dataSetElement?.dataSetElementDiagnostic.filter(el =>
            el.source = 'filterSearch'
        );
        if (filterSearch && diagnosisPresent) {
            let deleteDiagnosticSlug: string = null;
            diagnosisPresent.forEach((el) => {
                if (el.diagnosticId === diagId) {
                    deleteDiagnosticSlug = el.diagnostic.slug;
                }
            });
            diagnosisPresent = diagnosisPresent.map(el => {
                if (el.diagnostic.slug !== deleteDiagnosticSlug) {
                    return el.diagnostic.slug;
                }
                return null;
            }).filter(el => el !== null);
            for (const el of filterSearch) {
                if (this.searchEngineAdvancedService.getFilterSearchSlugsByConditionSlug('diagnosisMissing', el.params.query.args.criteria)?.includes(deleteDiagnosticSlug)) {
                    const data = this.searchEngineAdvancedService.getFilterSearchSlugsByConditionSlug('diagnosisMissing', el.params.query.args.criteria);
                    if (!data.some(r => diagnosisPresent.includes(r))) {
                        if (await this.setFilterSearchValidity(dataSetElement.parentId, el.id, 0)) {
                            const eventData = {
                                stayId: dataSetElement.dataSetContent.stayId,
                                filterSearchStatus: 0,
                                filterSearchName: el.name,
                                filterSearchId: el.id,
                                endDate: dataSetElement.dataSetContent.endDate,
                                type: dataSetElement.dataSetContent.type
                            };
                            await this._dashboardService.sendEvent('stay_rule_status', eventData);
                            el.validityStatus = 0;
                        }
                    }
                }
            }
        }
    }

    async codifyDataSetElement(dataSetElementId: number): Promise<any> {
        try {
            return await this._dataSetElementApiService
                .codify(dataSetElementId)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async loadGrouping(dataSetElementId: number) {
        try {
            return await this._dataSetElementApiService
                .loadGrouping(dataSetElementId)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async linkCodifyDataSetElement(dataSetElementId: number): Promise<any> {
        try {
            return await this._dataSetElementApiService
                .linkCodifyRule(dataSetElementId)
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async calculateGrouping(dataSetElementId: number, prediction: boolean = false, params?: any) {
        try {
            if (prediction) {
                params = {codificationId: 1, ...params};
                const groupingDiagnosticPredictionsPromise = this._dataSetElementApiService
                    .calculateGroupingDiagnosticPredictions(dataSetElementId, params)
                    .toPromise();
                const groupingActPredictionsPromise = this._dataSetElementApiService
                    .calculateGroupingActPredictions(dataSetElementId, params)
                    .toPromise();
                return await Promise.all([groupingDiagnosticPredictionsPromise, groupingActPredictionsPromise]);
            } else {
                return await this._dataSetElementApiService
                    .calculateGrouping(dataSetElementId, params)
                    .toPromise();
            }

        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async addActs(dataSetElementId: number, data: any, canSendBroadcastSuccessMessage = true) {
        try {
            const body = {
                data: data.data, // atention au format de data.data [actid, execution_date]
                type: data.type,
            };
            const res = await this._dataSetElementCodificationActApiService
                .addActs(dataSetElementId, 1, body)
                .toPromise();
            if (canSendBroadcastSuccessMessage) {
                this._broadcastService.send('act::addAct', {success: true, acts: res});
            }
            return res;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async deleteAct(dataSetElementId: number, actId: number, execution_date: string) {
        try {
            const date = this._dateHelperService.InputExtractDate(execution_date);
            const time = this._dateHelperService.InputExtractTime(execution_date);
            const res = await this._dataSetElementCodificationActApiService
                .deleteAct(dataSetElementId, 1, actId, {date: date, time: time})
                .toPromise();
            this._broadcastService.send('act::deleteAct', {success: true, act: res});
            return res;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async updateDataSetElementDates(dataSetElementId: number, body: any) {
        try {
            return await this._dataSetElementApiService
                .updateDataSetElementDates(dataSetElementId, body)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async deleteDataSetElementPrediction(diagnosticPredictionId: number) {
        try {
            return await this._dataSetElementApiService
                .deleteDataSetElementPrediction(diagnosticPredictionId)
                .toPromise();
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}
