interface SearchEngineConditions {
    DatasetElementCount: string;
    Revaluation: string;
}

/**
 * Define the search engine conditions we support.
 *
 * @type SearchEngineConditions
 */
export const SearchEngineConditions: SearchEngineConditions = {
    DatasetElementCount: 'datasetElementCount',
    Revaluation: 'revaluation',
};
