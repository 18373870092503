import {Injectable} from '@angular/core';
import {ConfigurationApiService} from '../../core/api-services/configuration/configuration.api.service';
import {SnackBarService} from '../../core/services/snack-bar.service';
import {TranslateService} from '@ngx-translate/core';
import {Configuration} from './configuration.model';
import {BroadcastService} from '../../core/services/broadcast.service';

/**
 * Defines private app only configuration.
 *
 * These configuration items must not appear on a public application.
 */
const PrivateConfigurationItems: string[] = [
    'health.canDisplayBilling',
];

/**
 * Defines public app only configuration.
 *
 * These configuration items must not appear on a private application.
 */
const PublicConfigurationItems: string[] = [];

/**
 * Defines the configuration type for public application.
 */
export const CONFIGURATION_TYPE_PUBLIC = 'public';

/**
 * Defines the configuration type for private application.
 */
export const CONFIGURATION_TYPE_PRIVATE = 'private';

/**
 * Represents the configuration types for an application.
 *
 * This type is used to specify whether the configuration is for a public or private application.
 */
type ConfigurationType = typeof CONFIGURATION_TYPE_PRIVATE | typeof CONFIGURATION_TYPE_PUBLIC;

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    private _configurations: any[] = [];

    constructor(
        private _configurationApiService: ConfigurationApiService,
        private _snackBarService: SnackBarService,
        private _broadcastService: BroadcastService,
        private _translateService: TranslateService,
    ) {
        this.updateConfigurationFromLocalStorage();
    }

    get configurations() {
        return this._configurations;
    }

    /**
     * Update configuration from local storage value.
     *
     * @return void
     */
    updateConfigurationFromLocalStorage() {
        let configuration = JSON.parse(localStorage.getItem('configurations')) || [];

        const configurationType: ConfigurationType = configuration.find((item: { name: string}) => item.name === 'clientType')?.content;

        if (configurationType === CONFIGURATION_TYPE_PUBLIC) {
            configuration = this.getPublicConfiguration(configuration);
        } else if (configurationType === CONFIGURATION_TYPE_PRIVATE) {
            configuration = this.getPrivateConfiguration(configuration);
        }

        this._configurations = configuration;
    }

    /**
     * Return a filtered array of configuration items
     * for private application.
     *
     * @param configuration
     * @private
     */
    private getPrivateConfiguration(configuration: any[]) {
        return configuration.filter((item: { name: string; }) => !PublicConfigurationItems.includes(item.name));
    }

    /**
     * Return a filtered array of configuration items
     * for public application.
     *
     * @param configuration
     * @private
     */
    private getPublicConfiguration(configuration: any[]) {
        return configuration.filter((item: { name: string; }) => !PrivateConfigurationItems.includes(item.name));
    }

    /**
     * Build an array of trees based on
     * array of string representing the paths
     * @param paths
     * @param parent
     * @param configurations
     * @param separator
     * @private
     */
    private _buildTree(paths: string[][], parent: string, configurations: any[], separator: string) {
        const items = [];
        for (let i = 0; i < paths.length; i++) {
            const path = paths[i];
            const name = path[0];
            const rest = path.slice(1);
            let item = null;
            for (let j = 0; j < items.length; j++) {
                if (items[j].name === name) {
                    item = items[j];
                    break;
                }
            }
            if (!item) {
                item = {
                    name,
                    parent,
                    children: []
                };
                items.push(item);
            }
            if (rest.length > 0) {
                item.children.push(rest);
            } else {
                const configToFind = configurations.find(config => {
                    return parent ? config.name === `${parent}.${name}` : config.name === name;
                });

                if (configToFind) {
                    Object.assign(item, configToFind);
                }
            }
        }
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const newParent = item.parent ? `${item.parent}.${item.name}` : item.name;
            item.children = this._buildTree(item.children, newParent, configurations, separator);
        }

        return items;
    }

    groupPaths(configurations: any[], separator: string) {
        if (configurations) {
            if (separator) {
                const paths = configurations.slice(0).map(el => el.name.split(separator));
                return this._buildTree(paths, null, configurations, separator);
            } else {
                return configurations.map(config => {
                    return {
                        ...config,
                        children: [],
                        parent: null
                    };
                });
            }
        } else {
            return [];
        }
    }

    getConfigurationContent(type: 'back' | 'front' | 'ml', name: string) {
        if (!this.configurations?.length) {
            return null;
        }

        const configurationItem = this.configurations.find(config => config.type === type && config.name === name);

        if (!configurationItem) {
            return null;
        }

        return configurationItem.content;
    }

    updateLocalStorage(newConfiguration: Configuration) {
        if (newConfiguration) {
            const localConfigurations = JSON.parse(localStorage.getItem('configurations')) || [];
            const newLocalConfigurations = localConfigurations.map(config => {
                if (config.id === newConfiguration.id) {
                    return {
                        ...config,
                        content: newConfiguration.content
                    };
                }
                return config;
            });
            localStorage.setItem('configurations', JSON.stringify(newLocalConfigurations));
            this._broadcastService.send('configuration::update');
        }
    }

    async loadAllConfigurations() {
        try {
            return await this._configurationApiService
                .getAll()
                .toPromise();
        } catch (e) {
            throw e;
        }
    }

    async loadSingleConfiguration(configurationName: string) {
        try {
            switch (configurationName) {
                case 'hasMailServer':
                    return await this._configurationApiService
                        .getHasMailServerConfiguration()
                        .toPromise();
                case 'language':
                    return await this._configurationApiService
                        .getLanguageConfiguration()
                        .toPromise();
            }
        } catch (e) {
            throw e;
        }
    }

    async updateConfiguration(configurationId: number, body: any) {
        try {
            const res = await this._configurationApiService
                .update(configurationId, body)
                .toPromise();
            this._snackBarService.success(this._translateService.instant('SUCCESS.UPDATE'));
            return res;
        } catch (e) {
            throw e;
        }
    }

    async resetAllConfigurations(type: string) {
        try {
            const res = await this._configurationApiService
                .resetAll({type})
                .toPromise();
            this._snackBarService.success(this._translateService.instant('SUCCESS.UPDATE'));
            return res;
        } catch (e) {
            throw e;
        }
    }

    async resetConfiguration(configurationId: number) {
        try {
            const res = await this._configurationApiService
                .reset(configurationId)
                .toPromise();
            this._snackBarService.success(this._translateService.instant('SUCCESS.UPDATE'));
            return res;
        } catch (e) {
            throw e;
        }
    }

    appTypeIsMCOAndSSR(): boolean {
        const appType: string = this.getConfigurationContent('back', 'APP.TYPE');
        return appType ? !!appType.match(/MCO.*SSR|SSR.*MCO/g) : false;
    }

    appTypeIs(value: string): boolean {
        const appType: string = this.getConfigurationContent('back', 'APP.TYPE');
        return appType ? appType === value : false;
    }
}
