import {Component, Input, OnInit} from '@angular/core';
import {StayDetailDependencyPredictionElementTooltipComponent} from './tooltip/stay-detail-dependency-prediction-element-tooltip.component';
import {StateService} from '@uirouter/core';
 import {CodificationLabelsModel} from '../../../shared/codification-labels.model';
import {DataSetElement} from '../../../shared/data-set-element.model';

@Component({
    selector: 'ct-stay-detail-dependency-prediction-element',
    templateUrl: './stay-detail-dependency-prediction-element.component.html',
    styleUrls: ['./stay-detail-dependency-prediction-element.component.scss',
        '../../diagnostic-prediction/element/stay-detail-diagnostic-prediction-element.component.scss']
})
export class StayDetailDependencyPredictionElementComponent implements OnInit {
    @Input() predictiveDependency: any;
    @Input() codificationLabels: CodificationLabelsModel;
    @Input() filterSearch: any;
    @Input() dataSetElement: DataSetElement;
    tooltipComponent: any;

    config: {
        dataSetElement,
        codificationLabels,
        filterSearch,
        predictionElementComponent,
        predictiveDependencies,
    };

    constructor(public $state: StateService) {
    }
    ngOnInit(): void {
        this.tooltipComponent = StayDetailDependencyPredictionElementTooltipComponent;
        this._initConfig();
    }

    private _initConfig(): void {
        this.config = {
            dataSetElement: this.dataSetElement,
            predictiveDependencies: this.predictiveDependency,
            codificationLabels: this.codificationLabels,
            predictionElementComponent: this,
            filterSearch: this.filterSearch,
        };
    }
    setCodificationLabelTrad(): string {
        const trad = this.codificationLabels.getSlugFromId(this.predictiveDependency.codificationLabelId);
        if (trad?.includes('DEPENDENCY')) {
            return trad.replace('_DEPENDENCY', '');
        } else {
            return trad;
        }
    }
}
