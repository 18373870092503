import {Component, Input, OnInit} from '@angular/core';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import {StateService} from '@uirouter/core';
import {AuthenticationService} from '../../../../core/authentication/authentication.service';
import {ConfigurationService} from '../../../configuration/configuration.service';

@Component({
    selector: 'ct-search-engine-condition-collection',
    templateUrl: './search-engine-condition-collection.component.html',
    styleUrls: ['./search-engine-condition-collection.component.scss']
})
export class SearchEngineConditionCollectionComponent implements OnInit {
    @Input() condition: any;

    isAutoSupervised: boolean;

    constructor(public $state: StateService,
                private _broadcastService: BroadcastService,
                public authenticationService: AuthenticationService,
                private _configurationService: ConfigurationService, ) { }

    ngOnInit() {
        this.isAutoSupervised = (this._configurationService.getConfigurationContent('front', 'menu.canDisplayQualityControlNearAutomationModule')
            && this.authenticationService.hasRole('admin')) || false;
    }

    getColor() {
        if (this.$state &&
            this.$state?.current) {
            // @ts-ignore
            const color: string = this.$state.current.color;
            return color ? color : 'blue';
        }
        return 'blue';
    }

    tagUpdate() {
        this._broadcastService.send('filterSearchCollectionCondition::tagUpdated');
    }
}
