import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {StayDetailFilterSearchComponent} from './stay-detail-filter-search.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {PipesModule} from '../../../../shared/pipes/pipes.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {StayDetailDiagnosticPredictionModule} from '../diagnostic-prediction/stay-detail-diagnostic-prediction.module';
import {TabListModule} from '../../../../shared/components/tab-list/tab-list.module';
import {StayDetailFilterSearchActListModule} from './act-list/stay-detail-filter-search-act-list.module';
import {StayDetailFilterSearchMenuModule} from './menu/stay-detail-filter-search-menu.module';
import {StayDetailDependencyPredictionListModule} from '../dependency-prediction/list/stay-detail-dependency-prediction-list.module';

@NgModule({
    declarations: [
        StayDetailFilterSearchComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        StayDetailDiagnosticPredictionModule,
        PipesModule,
        TabListModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatButtonModule,
        NgbTooltipModule,
        MatDividerModule,
        MatCardModule,
        MatChipsModule,
        StayDetailFilterSearchActListModule,
        StayDetailFilterSearchMenuModule,
        StayDetailDependencyPredictionListModule
    ],
    exports: [
        StayDetailFilterSearchComponent
    ],
})
export class StayDetailFilterSearchModule {}
