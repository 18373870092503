<div class="row">
    <div class="col no-padding">
        <!-- Stay start date -->
        <div class="margin-bottom-10">
            <ct-field-display class="display-block"
                              [isKeyBold]="true"
                              [isValueDisabled]="rumIdIncludesTmp(dataSetElement.dataSetContent?.rumId)"
                              [key]="'STUFF.FROM' | translate"
                              [value]="dateHelperService.mysqlToFrench(dataSetElementParent.dataSetContent?.startDate)"
                              [tooltipPlacement]="'bottom'"
                              [tooltipText]="rumIdIncludesTmp(dataSetElement.dataSetContent?.rumId) ? ('TOOLTIP.INDICATIVE_INFO' | translate) : null"
                              [htmlAttributeName]="'cypress-id'"
                              [htmlAttributeValue]="'stay-start-date'"
                              [breakLine]="true"
            ></ct-field-display>

        </div>

        <!-- Stay end date -->
        <div class="margin-bottom-10">
            <ct-field-display class="display-block"
                              [isKeyBold]="true"
                              [isValueDisabled]="rumIdIncludesTmp(dataSetElement.dataSetContent?.rumId)"
                              [key]="'STUFF.TO' | translate"
                              [value]="dateHelperService.mysqlToFrench(dataSetElementParent.dataSetContent?.endDate)"
                              [tooltipPlacement]="'bottom'"
                              [tooltipText]="rumIdIncludesTmp(dataSetElement.dataSetContent?.rumId) ? ('TOOLTIP.INDICATIVE_INFO' | translate) : null"
                              [htmlAttributeName]="'cypress-id'"
                              [htmlAttributeValue]="'stay-end-date'"
                              [breakLine]="true"
            ></ct-field-display>
        </div>
        <!-- Expected stay end date -->
        <div class="margin-bottom-10"
             *ngIf="displayExpectedEndDate()"
        >
            <ct-field-display class="display-block"
                              [isKeyBold]="true"
                              [key]="'STUFF.EXPECTED_END_DATE' | translate"
                              [value]="dateHelperService.mysqlToFrench(dataSetElement.dataSetContent?.expectedDate)"
                              [htmlAttributeName]="'cypress-id'"
                              [htmlAttributeValue]="'expected-stay-end-date'"
                              [breakLine]="true"
            ></ct-field-display>
        </div>
        <!-- Stay duration-->
        <div class="margin-bottom-10"
             *ngIf="!rumIdIncludesTmp(dataSetElement.dataSetContent?.rumId)">
            <ct-field-display class="display-block"
                              [isKeyBold]="true"
                              [key]="'DATA_SET.CODIFICATION_ELEMENT.STAY_DURATION' | translate"
                              [value]="dataSetElementParent.dataSetContent?.nbDay"
                              [htmlAttributeName]="'cypress-id'"
                              [htmlAttributeValue]="'stay-duration'"
                              [breakLine]="true"
            ></ct-field-display>
        </div>
        <!-- Stay IP-DMS-->
        <div class="margin-bottom-10"
             *ngIf="!isTypeRehabilitation && !isTypeExternal">
            <ct-field-display class="display-block"
                              [isKeyBold]="true"
                              [key]="'ACRONYM.IP-DMS' | translate"
                              [value]="dataSetElement.dataSetContent?.ipDms | number: '1.0-1'"
                              [htmlAttributeName]="'cypress-id'"
                              [htmlAttributeValue]="'stay-ip-dms'"
                              [breakLine]="true"
            ></ct-field-display>
        </div>
    </div>

    <div class="col no-padding"
         *ngIf="!isTypeExternal">
        <!-- RSS GHM / GME -->
        <div class="margin-bottom-10">
            <ng-container *ngIf="!isTypeRehabilitation">
                <b [ngbTooltip]="'TOOLTIP.GHM_RSS' | translate"
                   placement="right">{{'ACRONYM.GHM' | translate}} : </b>
                <br>
                <ct-ghm-tag [healthGhm]="dataSetElement.dataSetContent?.healthGhm"
                            [healthGhmDescriptionKey]="'name'"
                            [tooltipPlacement]="'right'"
                            [canDisplaySupIcon]="true"
                            [htmlAttributeName]="'cypress-id'"
                            [htmlAttributeValue]="'stay-ghm'"></ct-ghm-tag>
            </ng-container>

            <ng-container *ngIf="isTypeRehabilitation">
                <b [ngbTooltip]="'TOOLTIP.GME_RSS' | translate"
                   placement="right">{{'ACRONYM.GME' | translate}} : </b>
                <br>
                <ct-ghm-tag [healthGhm]="dataSetElement.dataSetContent?.healthStayAdditional?.healthGme"
                            [healthGhmDescriptionKey]="'name'"
                            [tooltipPlacement]="'right'"
                            [canDisplaySupIcon]="true"
                            [htmlAttributeName]="'cypress-id'"
                            [htmlAttributeValue]="'stay-gme'"></ct-ghm-tag>
            </ng-container>
        </div>

        <!-- CT GHM / GME-->
        <div class="margin-bottom-10">
            <ng-container *ngIf="canDisplayCtGhm() && !isTypeRehabilitation">
                <b [ngbTooltip]="'TOOLTIP.GHM_CT' | translate"
                   placement="right">{{'ACRONYM.GHM' | translate}} CT : </b>
                <br>
                <ct-ghm-tag [healthGhm]="dataSetElement.dataSetContent?.ctHealthGhsGhm?.healthGhm"
                            [healthGhmDescriptionKey]="'name'"
                            [tooltipPlacement]="'right'"
                            [canDisplaySupIcon]="true"
                            [htmlAttributeName]="'cypress-id'"
                            [htmlAttributeValue]="'stay-ct-ghm'"></ct-ghm-tag>
            </ng-container>

            <ng-container *ngIf="canDisplayCtGme() && isTypeRehabilitation">
                <b [ngbTooltip]="'TOOLTIP.GME_CT' | translate"
                   placement="right">{{'ACRONYM.GME' | translate}} CT : </b>
                <br>
                <ct-ghm-tag [healthGhm]="dataSetElement.dataSetContent?.healthStayAdditional?.ctHealthGmtGme?.healthGme"
                            [healthGhmDescriptionKey]="'name'"
                            [tooltipPlacement]="'right'"
                            [canDisplaySupIcon]="true"
                            [htmlAttributeName]="'cypress-id'"
                            [htmlAttributeValue]="'stay-ct-gme'"></ct-ghm-tag>
            </ng-container>
        </div>

        <!-- GHM price -->
        <div class="margin-bottom-10">
            <span class="ct-text-field font-weight-700">{{'DATA_SET.CODIFICATION_ELEMENT.PRICING' | translate}} :</span>
            <br>
            <span class="no-data margin-right-5"
                  cypress-id="stay-pricing"
                  *ngIf="!dataSetElement.dataSetContent?.pricing">N/A</span>
            <span *ngIf="!isUpdatePriceLoading && dataSetElement.dataSetContent?.pricing"
                  [ngbTooltip]="'TOOLTIP.PRICE_GHS_ADJUST_TO_STAY_LENGTH_WITHOUT_ADDED_FORFAIT' | translate"
                  placement="top"
                  tooltipClass="ngb-tooltip"
                  cypress-id="stay-pricing">
                        {{ 'DATA_SET.CODIFICATION_ELEMENT.PRICING_VALUE' | translate:{'price': dataSetElement.dataSetContent?.pricing} }}
                    </span>

            <ng-container *ngIf="canDisplayBilling">
                <mat-icon class="is-billed-icon"
                          *ngIf="dataSetElement.dataSetContent?.isBilled"
                          [ngbTooltip]="'TOOLTIP.STAY_BILLED' | translate"
                          placement="right">lock</mat-icon>
                <mat-icon class="is-billed-icon black"
                          *ngIf="!dataSetElement.dataSetContent?.isBilled"
                          [ngbTooltip]="'TOOLTIP.STAY_NOT_BILLED' | translate"
                          placement="right">lock_open</mat-icon>
            </ng-container>

            <mat-progress-spinner *ngIf="isUpdatePriceLoading"
                                  style="margin: 0 auto; display: inline;"
                                  mode="indeterminate"
                                  [diameter]="14">
            </mat-progress-spinner>
        </div>

        <!-- Stay nb session-->
        <div class="margin-bottom-10"
             *ngIf="!isTypeRehabilitation && !isTypeExternal">
            <ct-field-display class="display-block"
                              [isKeyBold]="true"
                              [key]="'DATA_SET.CODIFICATION.SEARCH.NUMBER_OF_SESSIONS' | translate"
                              [value]="dataSetElement.dataSetContent?.nbSession"
                              [htmlAttributeName]="'cypress-id'"
                              [htmlAttributeValue]="'stay-nb-session'"
                              [breakLine]="true"
            ></ct-field-display>
        </div>
    </div>
</div>

<hr class="margin-top-16 margin-bottom-20"/>

<div class="row"
     *ngIf="!isTypeExternal">
    <div class="col no-padding">
        <ct-field-display class="display-block margin-bottom-10"
                          [isKeyBold]="true"
                          [key]="'DATA_SET.CODIFICATION.SEARCH.HEALTH_MOVEMENT_ENTRANCE' | translate"
                          [value]="entryMode"
                          [htmlAttributeName]="'cypress-id'"
                          [htmlAttributeValue]="'stay-movement-entrance'"
                          [breakLine]="true"
        ></ct-field-display>
        <ct-field-display class="display-block"
                          [isKeyBold]="true"
                          [key]="'DATA_SET.CODIFICATION.SEARCH.HEALTH_MOVEMENT_ORIGIN' | translate"
                          [value]="entryOrigin"
                          [htmlAttributeName]="'cypress-id'"
                          [htmlAttributeValue]="'stay-movement-entrance'"
                          [breakLine]="true"
        ></ct-field-display>
    </div>

    <div class="col no-padding">
        <ct-field-display class="display-block margin-bottom-10"
                          [isKeyBold]="true"
                          [key]="'DATA_SET.CODIFICATION.SEARCH.HEALTH_MOVEMENT_EXIT' | translate"
                          [value]="exitMode"
                          [htmlAttributeName]="'cypress-id'"
                          [htmlAttributeValue]="'stay-movement-exit'"
                          [breakLine]="true"
        ></ct-field-display>
        <ct-field-display class="display-block"
                          [isKeyBold]="true"
                          [key]="'DATA_SET.CODIFICATION.SEARCH.HEALTH_MOVEMENT_DESTINATION' | translate"
                          [value]="exitDestination"
                          [htmlAttributeName]="'cypress-id'"
                          [htmlAttributeValue]="'stay-movement-exit'"
                          [breakLine]="true"
        ></ct-field-display>
    </div>
</div>

<hr class="margin-top-16 margin-bottom-20"/>

<!-- Patient IPP and name -->
<div class="row margin-bottom-10"
     *ngIf="dataSetElement.dataSetContent?.healthPatient">
    <div class="col no-padding">
        <ct-field-display [isKeyBold]="true"
                          [key]="'ACRONYM.IPP' | translate"
                          [value]="dataSetElement.dataSetContent?.healthPatient.ipp"
                          [htmlAttributeName]="'cypress-id'"
                          [htmlAttributeValue]="'patient-ipp'"
                          [breakLine]="true"
        ></ct-field-display>

        <div class="edit-ipp-indicator-container"
             *ngIf="canDisplayMissingIPPIndicator()">
            <ct-warning-indicator [dataSetElement]="dataSetElement"
                                  [tooltipText]="'TOOLTIP.MISSING_PATIENT_IPP' | translate"
                                  [classes]="['margin-left-5']"></ct-warning-indicator>

            <button mat-mini-fab
                    color="primary"
                    class="mini-fab-edition-button"
                    [matTooltip]="'TOOLTIP.EDIT_PATIENT_IPP' | translate"
                    (click)="openPatientIPPEditDialog()">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
    </div>

    <div class="col no-padding">
        <ct-field-display class="display-block"
                          [isKeyBold]="true"
                          [key]="'HEALTH.PATIENT.PATIENT' | translate"
                          [value]="getPatientName(dataSetElement.dataSetContent?.healthPatient)"
                          [htmlAttributeName]="'cypress-id'"
                          [htmlAttributeValue]="'patient-name'"
                          [breakLine]="true"
        ></ct-field-display>
    </div>
</div>

<!-- Patient gender and birthDate -->
<div class="row margin-bottom-10">
    <div class="col no-padding">
        <ct-field-display class="display-block"
                          [isKeyBold]="true"
                          [key]="'HEALTH.PATIENT.GENDER' | translate"
                          [value]="getGender(dataSetElement.dataSetContent?.healthPatient?.gender)"
                          [htmlAttributeName]="'cypress-id'"
                          [htmlAttributeValue]="'patient-gender'"
                          [breakLine]="true"
        ></ct-field-display>
    </div>

    <div class="col no-padding">
        <ct-field-display class="display-block"
                          [tooltipPlacement]="'right'"
                          [tooltipText]="dateHelperService.mysqlToFrench(dataSetElement.dataSetContent?.healthPatient?.birthDate)"
                          [isKeyBold]="true"
                          [key]="'HEALTH.PATIENT.AGE' | translate"
                          [value]="patientAge"
                          [htmlAttributeName]="'cypress-id'"
                          [htmlAttributeValue]="'patient-age'"
                          [breakLine]="true"
        ></ct-field-display>
    </div>
</div>

<!-- Patient stay history-->
<ng-container
    *ngIf="canDisplayPatientInfo()">
    <div class="row">
        <div class="col align-self-center no-padding">
            <div class="display-inline-block margin-right-10"
                 [ngbTooltip]="patientStayHistory.length > 0 ? historyTooltip : null"
                 tooltipClass="patient-history-tooltip ngb-tooltip"
                 placement="right">
                <span class="ct-text-field font-weight-700">{{'DATA_SET.CODIFICATION_ELEMENT.PREVIOUS_STAYS' | translate}} :</span>
                <span class="no-data"
                      *ngIf="patientStayHistory.length === 0">{{'STUFF.NONE_MALE' | translate}}</span>
                <span *ngIf="patientStayHistory.length > 0">{{patientStayHistory.length}}</span>
            </div>
        </div>

        <div class="col align-self-center no-padding">
            <button mat-raised-button
                    cypress-id="other-patient-stays"
                    class="go-to-patient-detail-button"
                    color="primary"
                    [ngbTooltip]="numberOfPatientStays < 2 ? ('TOOLTIP.NO_OTHER_PATIENT_STAY' | translate) : ''"
                    placement="bottom"
                    (click)="goToPatientDetail()">
                {{'BUTTON.GO_TO_PATIENT_DETAIL' | translate}}
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isTypeExternal && !isPatientStayHistoryLoading">
    <div class="margin-top-30 text-center">
        <button mat-mini-fab
                color="primary"
                (click)="showAdditionalInfo = !showAdditionalInfo">
            <mat-icon *ngIf="!showAdditionalInfo">expand_more</mat-icon>
            <mat-icon *ngIf="showAdditionalInfo">expand_less</mat-icon>
        </button>
    </div>

    <ct-stay-detail-info-additional class="display-block margin-top-20"
                                    *ngIf="showAdditionalInfo"
                                    [dataSetElement]="dataSetElement"
                                    [isTypeRehabilitation]="isTypeRehabilitation"></ct-stay-detail-info-additional>
</ng-container>
<!-- History tooltip template -->
<ng-template #historyTooltip>
    <div class="tooltip-container"
         *ngFor="let stay of patientStayHistory">
        <div class="text-start">
            <b>{{'STUFF.DATE' | translate}} : </b>
            {{dateHelperService.mysqlToFrench(stay.endDate)}}
            <span>(n° {{stay.stayId ||stay.stayIdOriginal}})</span>
        </div>

        <div class="text-start"
             *ngIf="hasHistoryDiagnostic(stay.contents.id, 1)">
            <b>{{'ACRONYM.DP' | translate}} : </b>
            <div
                *ngFor="let a of filterHistoryDiagnostics(stay.contents.id, 1); first as isFirst"
                [ngStyle]="{'margin-left': !isFirst ? '28px' : '0', 'display': !isFirst ? 'block' : 'inline-block'}">
                <div style="display: inline-block; min-width: 50px">
                    {{a.slug}}
                </div>
                <div style="display: inline-block;">
                    {{a.name}}
                </div>
                <br/></div>
        </div>

        <div class="text-start"
             *ngIf="hasHistoryDiagnostic(stay.contents.id, 2)">
            <b>{{'ACRONYM.DR' | translate}} : </b>
            <div
                *ngFor="let a of filterHistoryDiagnostics(stay.contents.id, 2); first as isFirst"
                [ngStyle]="{'margin-left': !isFirst ? '28px' : '0', 'display': !isFirst ? 'block' : 'inline-block'}">
                <div style="display: inline-block; min-width: 50px">
                    {{a.slug}}
                </div>
                <div style="display: inline-block;">
                    {{a.name}}
                </div>
                <br/>
            </div>
        </div>

        <div class="text-start"
             *ngIf="hasHistoryDiagnostic(stay.id, 3)">
            <b>{{'ACRONYM.DA' | translate}} : </b>
            <div
                *ngFor="let a of filterHistoryDiagnostics(stay.id, 3); first as isFirst"
                [ngStyle]="{'margin-left': !isFirst ? '28px' : '0', 'display': !isFirst ? 'block' : 'inline-block'}">
                <div class="display-inline-block"
                     style="min-width: 50px">
                    {{a.slug}}
                </div>
                <div class="display-inline-block">
                    {{a.name}}
                </div>
                <br/>
            </div>
        </div>
    </div>
</ng-template>

<div class="align-center-center margin-top-20 margin-bottom-10"
     *ngIf="isPatientStayHistoryLoading">
    <mat-progress-spinner
        [diameter]="100"
        mode="indeterminate">
    </mat-progress-spinner>
</div>
