export enum CodificationLabelEnum {
    DP = 'DP',
    DR = 'DR',
    DA = 'DA',
    DP_ROOT = 'DP_ROOT',
    DA_ROOT = 'DA_ROOT',
    HEALTH_STAY_SEVERITY = 'HEALTH_STAY_SEVERITY',
    FP = 'FP',
    MP = 'MP',
    AE = 'AE',
    CCAM = 'CCAM',
    CSARR = 'CSARR',
    BEHAVIOUR_DEPENDENCY = 'BEHAVIOUR_DEPENDENCY',
    DRESSING_OR_TOILETING_DEPENDENCY = 'DRESSING_OR_TOILETING_DEPENDENCY',
    CONTINENCE_DEPENDENCY = 'CONTINENCE_DEPENDENCY',
    MOVING_DEPENDENCY = 'MOVING_DEPENDENCY',
    EATING_DEPENDENCY = 'EATING_DEPENDENCY',
    RELATIONSHIP_DEPENDENCY = 'RELATIONSHIP_DEPENDENCY'
}

export type CodificationLabel = CodificationLabelEnum.DP
    | CodificationLabelEnum.DR
    | CodificationLabelEnum.DA
    | CodificationLabelEnum.DP_ROOT
    | CodificationLabelEnum.DA_ROOT
    | CodificationLabelEnum.HEALTH_STAY_SEVERITY
    | CodificationLabelEnum.FP
    | CodificationLabelEnum.MP
    | CodificationLabelEnum.AE
    | CodificationLabelEnum.CCAM
    | CodificationLabelEnum.CSARR
    | CodificationLabelEnum.BEHAVIOUR_DEPENDENCY
    | CodificationLabelEnum.DRESSING_OR_TOILETING_DEPENDENCY
    | CodificationLabelEnum.CONTINENCE_DEPENDENCY
    | CodificationLabelEnum.MOVING_DEPENDENCY
    | CodificationLabelEnum.EATING_DEPENDENCY
    | CodificationLabelEnum.RELATIONSHIP_DEPENDENCY;

